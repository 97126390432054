import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface RoleBasedRouteProps {
  element: ReactNode;
  allowedRoles: string[];
  redirectToLoginOnDisallowed?: boolean; 
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ element, allowedRoles, redirectToLoginOnDisallowed }) => {

  const userRole = localStorage.getItem('role');

  if (!userRole) {
   
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles.includes(userRole)) {
   
    return <>{element}</>;
  } else {
    
    if (redirectToLoginOnDisallowed && userRole === 'instalator') {
      return <Navigate to="/installation" replace />;
    }
   
    return <Navigate to="/not-found" replace />;
  }
};

export default RoleBasedRoute;
