import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Avatar
} from '@mui/material';
import axiosInstance from '../axiosInstance';
import PasswordAccordion from './PasswordAccordion';
import ProfileImageAccordion from './ProfileImageAccordion';
import FeedbackColumns from './FeedbackColumns';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTheme } from '@mui/material/styles';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<string | false>(false);
  const theme = useTheme();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('/auth/me');
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleAccordionChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const iconColors = [theme.palette.primary.main, theme.palette.primary.light];

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container component={Paper} elevation={3} sx={{ padding: 4, maxWidth: '1200px' }}>
        {userData && (
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: "center", alignItems: 'center', gap: { xs: 2, md: 4 } }}>
            <Box sx={{ width: { xs: '100%', md: '30%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <Avatar 
                src={userData.profile_image_path} 
                alt="Profile Image" 
                sx={{ 
                  width: { xs: '30vw', sm: '20vw', md: '15vw', lg: '10vw' }, 
                  height: { xs: '30vw', sm: '20vw', md: '15vw', lg: '10vw' }, 
                  borderRadius: '50%', 
                  border: '5px solid', 
                  borderColor: 'primary.main',
                  boxShadow: '0 0 10px rgba(0,0,0,0.5)' 
                }} 
              />
            </Box>
            <Grid container spacing={2} sx={{ width: { xs: '100%', md: '70%' }, justifyContent: 'center', ml:4 }}>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircleIcon sx={{ marginRight: 1, color: iconColors[0] }} />
                <Typography variant="h6">Username: {userData.username}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ marginRight: 1, color: iconColors[1] }} />
                <Typography variant="h6">Email: {userData.email}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <SecurityIcon sx={{ marginRight: 1, color: iconColors[0] }} />
                <Typography variant="h6">Role: {userData.role}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <VerifiedUserIcon sx={{ marginRight: 1, color: iconColors[1] }} />
                <Typography variant="h6">Status: {userData.is_active ? 'Active' : 'Inactive'}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ width: { xs: '100%', md: '30%' }, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <PasswordAccordion expanded={expanded} handleAccordionChange={handleAccordionChange} />
            <ProfileImageAccordion expanded={expanded} handleAccordionChange={handleAccordionChange} userData={userData} setUserData={setUserData} />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '70%' }, mt: { xs: 4, md: 0 } }}>
            <FeedbackColumns />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ProfilePage;
