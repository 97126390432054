import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Divider, Dialog, DialogTitle, DialogContent, Chip, Tabs, Tab, Alert, Menu, MenuItem, Button, IconButton, GlobalStyles, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import  {publicInstance} from '../axiosInstance';

interface ChangeHistoryProps {
  deviceNumber: string;
  open: boolean;
  onClose: () => void;
}

const ChangeHistory: React.FC<ChangeHistoryProps> = ({ deviceNumber, open, onClose }) => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [changeHistory, setChangeHistory] = useState({
    production: [],
    sensorbox: [],
    board: [],
    gps: [],
    bts: [],
  });
  const [filters, setFilters] = useState({
    production: '',
    sensorbox: '',
    board: '',
    gps: '',
    bts: '',
  });
  const [loadingStatus, setLoadingStatus] = useState({
    production: true,
    sensorbox: true,
    board: true,
    gps: true,
    bts: true,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const contentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      fetchChangeHistory('production', `/dacapi_sensorbox_introduction_form_changes?box=eq.${deviceNumber}`);
      fetchChangeHistory('sensorbox', `/dacapi_sensorbox_changes?box=eq.${deviceNumber}`);
      fetchChangeHistory('board', `/dacapi_board_changes?box=eq.${deviceNumber}`);
      fetchChangeHistory('gps', `/dacapi_gps_changes?box=eq.${deviceNumber}`);
      fetchChangeHistory('bts', `/dacapi_bts_changes?box=eq.${deviceNumber}`);
    }
  }, [open]);

  const fetchChangeHistory = async (type: string, url: string) => {
    try {
      const response = await publicInstance.get(url);
      setChangeHistory(prev => ({
        ...prev,
        [type]: response.data.sort((a, b) => new Date(b.changed_at).getTime() - new Date(a.changed_at).getTime()),
      }));
    } catch (error) {
      console.error(`Error fetching ${type} change history:`, error);
    } finally {
      setLoadingStatus(prev => ({
        ...prev,
        [type]: false,
      }));
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleFilterChange = (tab: string, filter: string) => {
    setFilters(prev => ({ ...prev, [tab]: filter }));
    handleCloseMenu();
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getFilteredChangeHistory = () => {
    const currentTab = ['production', 'sensorbox', 'board', 'gps', 'bts'][tabIndex];
    const currentFilter = filters[currentTab];
    return currentFilter
      ? changeHistory[currentTab].filter(change => {
        const valuesToSearch = Object.values(change).join(' ').toLowerCase();
        return valuesToSearch.includes(currentFilter.toLowerCase());
      })
      : changeHistory[currentTab];
  };

  const filteredChangeHistory = getFilteredChangeHistory();

  const renderChangeItem = (change: any, type: string) => {
    switch (type) {
      case 'production':
      case 'board':
      case 'sensorbox':
        return (
          <Box key={change.id} mb={2}>
            <Typography variant="body2">
              <strong>Field:</strong> {change.field_name}
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body2" color="error" sx={{ mr: 1 }}>
                {change.old_value}
              </Typography>
              <ArrowForwardIcon sx={{ color: 'grey' }} />
              <Typography variant="body2" color="success.main" sx={{ ml: 1 }}>
                {change.new_value}
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>Changed By:</strong> {change.username}
            </Typography>
            <Typography variant="body2">
              <strong>Changed At:</strong> {new Date(change.changed_at).toLocaleString()}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
        );
      case 'gps':
        return (
          <Box key={change.id} mb={2}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body2" color="error" sx={{ mr: 1 }}>
                {change.old_gps}
              </Typography>
              <ArrowForwardIcon sx={{ color: 'grey' }} />
              <Typography variant="body2" color="success.main" sx={{ ml: 1 }}>
                {change.new_gps}
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>Changed At:</strong> {new Date(change.changed_at).toLocaleString()}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
        );
      case 'bts':
        return (
          <Box key={change.id} mb={2}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body2" color="error" sx={{ mr: 1 }}>
                {change.old_lac}, {change.old_cid}
              </Typography>
              <ArrowForwardIcon sx={{ color: 'grey' }} />
              <Typography variant="body2" color="success.main" sx={{ ml: 1 }}>
                {change.new_lac}, {change.new_cid}
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>Changed At:</strong> {new Date(change.changed_at).toLocaleString()}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
        );
      default:
        return null;
    }
  };

  const filterOptions = {
    production: [
      'status', 'device_type', 'location', 'version_of_dz', 'number_of_dz',
      'pull_up', 'power_supply_type', 'measurement_track_type', 'rtc_battery',
      'voltage_filter', 'sensors', 'number_of_sensors', 'antennas', 'comments'
    ],
    sensorbox: [
      'version_dz', 'version_stm', 'mac', 'imei', 'imsi', 'iccid', 'rssi',
      'modem', 'graphana', 'rsync', 'trm_patch', 'root', 'vpns', 'vpn_check',
      'svlog', 'cron', 'network', 'hwclock', 'inittab', 'conf_channel_all',
      'number_sensor_all', 'ose_esa', 'routes', 'auto_jas', 'version',
      'customroutes', 'open_vpn', 'open_wrt', 'scripts_comments'
    ],
    board: [
      'mac_table', 'bin', 'data', 'table_type', 'brightness_type'
    ],
    gps: [],
    bts: [],
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <IconButton onClick={onClose} color="primary" sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center', mr: 7 }}>
          Change History
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minHeight: '85vh', position: 'relative' }}>

        <Box position="sticky" top={0} zIndex={1} pb={1}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Production" />
            <Tab label="Sensorbox" />
            <Tab label="Board" />
            <Tab label="GPS" />
            <Tab label="BTS" />
          </Tabs>
          {(tabIndex === 0 || tabIndex === 1 || tabIndex === 2) && (
            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
              <Button
                id="filter-button"
                aria-controls={openMenu ? 'filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClickMenu}
                variant="contained"
                size="small"
                startIcon={<FilterListIcon />}
                sx={{ marginRight: 1, marginBottom: 1 }}
              >
                Filter
              </Button>
              <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'filter-button',
                }}
                PaperProps={{
                  style: {
                    maxHeight: 600,
                    overflow: 'auto',
                  },
                }}
              >
                {filterOptions[tabIndex === 0 ? 'production' : tabIndex === 1 ? 'sensorbox' : 'board'].map((option) => (
                  <MenuItem key={option} onClick={() => handleFilterChange(tabIndex === 0 ? 'production' : tabIndex === 1 ? 'sensorbox' : 'board', option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
              <Chip
                label="ALL"
                onClick={() => handleFilterChange(tabIndex === 0 ? 'production' : tabIndex === 1 ? 'sensorbox' : 'board', '')}
                sx={{ marginRight: 1, marginBottom: 1, backgroundColor: 'lightblue', fontWeight: 'bold' }}
              />
              <IconButton
                onClick={handleScrollToTop}
                color="primary"
                sx={{ marginLeft: 'auto' }}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box ref={contentRef} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          {loadingStatus[tabIndex === 0 ? 'production' : tabIndex === 1 ? 'sensorbox' : tabIndex === 2 ? 'board' : tabIndex === 3 ? 'gps' : 'bts'] ? (
            <Typography variant="body2">Loading...</Typography>
          ) : filteredChangeHistory.length > 0 ? (
            filteredChangeHistory.map(change => renderChangeItem(change, tabIndex === 0 ? 'production' : tabIndex === 1 ? 'sensorbox' : tabIndex === 2 ? 'board' : tabIndex === 3 ? 'gps' : 'bts'))
          ) : (
            <Alert severity="info">No changes found for the selected tab.</Alert>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeHistory;
