import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../axiosInstance';

interface ProfileImageAccordionProps {
  expanded: string | false;
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  userData: any;
  setUserData: (userData: any) => void;
}

const ProfileImageAccordion: React.FC<ProfileImageAccordionProps> = ({
  expanded,
  handleAccordionChange,
  userData,
  setUserData
}) => {
  const [newProfileImagePath, setNewProfileImagePath] = useState('');
  const [profileImageError, setProfileImageError] = useState('');
  const [profileImageSuccess, setProfileImageSuccess] = useState('');

  const handleProfileImageChange = async () => {
    if (!userData) return;

    try {
      const response = await axiosInstance.put(
        `/auth/users/${userData.user_id}/profile-image`,
        new URLSearchParams({
          profile_image_path: newProfileImagePath
        })
      );

      if (response.status === 200) {
        setUserData({ ...userData, profile_image_path: newProfileImagePath });
        setProfileImageSuccess('Profile image updated successfully');
        setProfileImageError('');
      }
    } catch (error) {
      setProfileImageError('Error updating profile image. Please try again.');
      console.error('Error updating profile image:', error);
    }
  };

  return (
    <Accordion
      expanded={expanded === 'panel2'}
      onChange={handleAccordionChange('panel2')}
      sx={{ marginTop: 2, width: '100%', maxWidth: '400px', backgroundColor: 'background.default' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Change Profile Image</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="New Profile Image Path"
                type="text"
                fullWidth
                margin="normal"
                value={newProfileImagePath}
                onChange={(e) => setNewProfileImagePath(e.target.value)}
              />
            </Grid>
            {profileImageError && (
              <Grid item xs={12}>
                <Alert severity="error">{profileImageError}</Alert>
              </Grid>
            )}
            {profileImageSuccess && (
              <Grid item xs={12}>
                <Alert severity="success">{profileImageSuccess}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleProfileImageChange}
                sx={{ marginTop: 2 }}
                fullWidth
              >
                Change Profile Image
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProfileImageAccordion;
