import React, { useState } from 'react';
import { Box, Typography, TextField, useTheme } from '@mui/material';

const renderBlocks = (
  count: number, 
  color: string, 
  isP5 = false, 
  isP8 = false, 
  large = false, 
  numbers: string[] = [], 
  onEdit?: (index: number, newValue: string) => void 
) => {
  const theme = useTheme();

  const size = large ? 70 : 20;
  const p5p8Size = size * 2 + 6; // Szerokość bloku dla p5 i p8 (2x standardowy rozmiar plus margines)

  const blockStyle = {
    width: size,
    height: size,
    backgroundColor: color,
    margin: large ? '3px' : '1px',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'yellow',
    fontSize: large ? '14px' : '8px',
    fontWeight: 'bold',
  };

  const p5p8BlockStyle = {
    ...blockStyle,
    width: p5p8Size, // Zwiększenie szerokości bloku
    marginRight: large ? '3px' : '2px', // Dodanie większego odstępu między blokami
    border: theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.3)' : 'none', // Dodanie borderu dla trybu dark
  };

  const whiteDotStyle = {
    width: large ? '14px' : '4px',
    height: large ? '14px' : '4px',
    backgroundColor: 'white',
    borderRadius: '50%',
    position: 'absolute' as 'absolute',
  };

  const getDots = (isP5: boolean, isP8: boolean) => {
    if (isP5) {
      if (isP5) {
        return (
          <>
            {/* Górny rząd kropek */}
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '6px' : '0px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '26px' : '16px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '46px' : '32px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '66px' : '48px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '86px' : '64px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '106px' : '80px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '8px' : '10px', left: large ? '126px' : '96px' }} />
      
            {/* Środkowy rząd kropek */}
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '6px' : '0px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '26px' : '16px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '46px' : '32px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '66px' : '48px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '86px' : '64px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '106px' : '80px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '28px' : '28px', left: large ? '126px' : '96px' }} />
      
            {/* Dolny rząd kropek */}
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '6px' : '0px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '26px' : '16px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '46px' : '32px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '66px' : '48px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '86px' : '64px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '106px' : '80px' }} />
            <Box sx={{ ...whiteDotStyle, top: large ? '48px' : '46px', left: large ? '126px' : '96px' }} />
          </>
        );
      }
    }      
    if (isP8) {
      return (
        <>
          {/* Górny rząd kropek */}
          <Box sx={{ ...whiteDotStyle, top: large ? '10px' : '20px', left: large ? '10px' : '4px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '10px' : '20px', left: large ? '38px' : '20px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '10px' : '20px', left: large ? '66px' : '36px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '10px' : '20px', left: large ? '94px' : '52px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '10px' : '20px', left: large ? '122px' : '68px' }} />

          {/* Dolny rząd kropek */}
          <Box sx={{ ...whiteDotStyle, top: large ? '45px' : '40px', left: large ? '10px' : '4px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '45px' : '40px', left: large ? '38px' : '20px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '45px' : '40px', left: large ? '66px' : '36px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '45px' : '40px', left: large ? '94px' : '52px' }} />
          <Box sx={{ ...whiteDotStyle, top: large ? '45px' : '40px', left: large ? '122px' : '68px' }} />
        </>
      );
    }
    return null;
  };

  const blocks = Array.from({ length: count }).map((_, index) => (
    <Box 
      key={index} 
      sx={isP5 || isP8 ? p5p8BlockStyle : blockStyle} 
    >
      {numbers[index] ? (
        <TextField 
          variant="standard" 
          value={numbers[index]} 
          onChange={(e) => onEdit && onEdit(index, e.target.value)} 
          sx={{ 
            zIndex: 1, 
            textAlign: 'center', 
            fontSize: '12px', 
            width: isP5 || isP8 ? p5p8Size - 10 : size - 10, 
            '& .MuiInputBase-input': {
              backgroundColor: theme.name === 'dark' ? '#444' : '#ADD8E6', 
              color: theme.palette.getContrastText(theme.name === 'dark' ? '#444' : '#ADD8E6'), 
              padding: '2px 4px',
              fontSize: '12px', 
            }
          }}
        />
      ) : null}
      {isP5 && getDots(true, false)}
      {isP8 && getDots(false, true)}
    </Box>
  ));

  return <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: large ? '70vh' : '20vh' }}>{blocks}</Box>;
};

export default renderBlocks;
