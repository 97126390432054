import React, { useState, useMemo } from 'react';
import { Box, Typography, AppBar, Toolbar, IconButton, Tab, Tabs, Card, CardContent, useTheme, useMediaQuery, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, Pagination } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { FaSearch } from 'react-icons/fa';

type Shipment = {
  id: number;
  group_id: number;
  submission_date: string;
  recipient: string;
  planned_shipping_date: string;
  comments: string;
  device_type: string;
  quantity: number;
  details: any[];
  username: string;
  status: string;
  time_stamp: string;
  deletion_time: string;
};

type ShipmentHistoryProps = {
  shipments: Shipment[];
  onClose: () => void;
};

const ShipmentHistory: React.FC<ShipmentHistoryProps> = ({ shipments, onClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('all');
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // You can adjust this value as needed
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(1); // Reset page to 1 when changing tabs
  };

  const filteredShipments = useMemo(() => {
    return shipments.filter(shipment => {
      const searchLower = searchTerm.toLowerCase();
      switch (searchField) {
        case 'group_id':
          return shipment.group_id.toString().includes(searchTerm);
        case 'recipient':
          return shipment.recipient.toLowerCase().includes(searchLower);
        case 'mac_address':
          return shipment.details.some(detail => detail.mac_address && detail.mac_address.toLowerCase().includes(searchLower));
        case 'box':
          return shipment.details.some(detail => detail.box && detail.box.toLowerCase().includes(searchLower));
        case 'all':
        default:
          return shipment.group_id.toString().includes(searchTerm) ||
            shipment.recipient.toLowerCase().includes(searchLower) ||
            shipment.details.some(detail =>
              (detail.mac_address && detail.mac_address.toLowerCase().includes(searchLower)) ||
              (detail.box && detail.box.toLowerCase().includes(searchLower))
            );
      }
    }).sort((a, b) => new Date(b.deletion_time).getTime() - new Date(a.deletion_time).getTime());
  }, [shipments, searchTerm, searchField]);

  const cancelledShipments = filteredShipments.filter(shipment => shipment.status === 'anulowane');
  const sentShipments = filteredShipments.filter(shipment => shipment.status === 'wyslane');

  const paginatedShipments = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return tabValue === 0 ? sentShipments.slice(startIndex, endIndex) : cancelledShipments.slice(startIndex, endIndex);
  }, [tabValue, sentShipments, cancelledShipments, page]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderShipmentCard = (shipment: Shipment) => (
    <Card key={shipment.id} sx={{
      mb: 2,
      boxShadow: 3,
      '&:hover': { boxShadow: 6 },
      // Zmieniono na jaśniejszy kolor
    }}>
      <CardContent>
        <Typography variant="h6" color="primary">Group ID: {shipment.group_id}</Typography>
        <Typography><strong>Recipient:</strong> {shipment.recipient}</Typography>
        <Typography><strong>Planned Shipping Date:</strong> {shipment.planned_shipping_date}</Typography>
        <Typography><strong>Device Type:</strong> {shipment.device_type}</Typography>
        <Typography><strong>Quantity:</strong> {shipment.quantity}</Typography>
        <Typography><strong>Status:</strong> {shipment.status}</Typography>
        <Typography><strong>Submission Date:</strong> {new Date(shipment.submission_date).toLocaleString()}</Typography>
        <Typography><strong>Deletion Time:</strong> {new Date(shipment.deletion_time).toLocaleString()}</Typography>
        {shipment.comments && <Typography><strong>Comments:</strong> {shipment.comments}</Typography>}
        <Typography variant="subtitle2" sx={{ mt: 1 }}>Details:</Typography>
        {shipment.details.map((detail, index) => (
          <Box key={index} sx={{ ml: 2 }}>
            {detail.mac_address && <Typography>MAC: {detail.mac_address}</Typography>}
            {detail.box && <Typography>Box: {detail.box}</Typography>}
          </Box>
        ))}
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.palette.background.default 
    }}>

      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <IoMdClose />
        </IconButton>
        <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
          Shipment History
        </Typography>
      </Toolbar>

      <Box sx={{ p: 2, backgroundColor: theme.palette.background.default, display: 'flex', gap: 2 }}>
        <FormControl variant="outlined" sx={{ minWidth: 200, backgroundColor: theme.palette.background.default }}>
          <InputLabel>Search by</InputLabel>
          <Select
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            label="Search by"
          >
            <MenuItem value="all">All Fields</MenuItem>
            <MenuItem value="group_id">Group ID</MenuItem>
            <MenuItem value="recipient">Recipient</MenuItem>
            <MenuItem value="mac_address">MAC Address</MenuItem>
            <MenuItem value="box">Box Number</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={`Search by ${searchField === 'all' ? 'any field' : searchField.replace('_', ' ')}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="shipment history tabs"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Sent Shipments" />
        <Tab label="Cancelled Shipments" />

      </Tabs>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        {tabValue === 0 && (
          <>
            <Typography variant="h5" gutterBottom>Sent Shipments</Typography>
            {paginatedShipments.map(renderShipmentCard)}
          </>
        )}
        {tabValue === 1 && (
          <>
            <Typography variant="h5" gutterBottom>Cancelled Shipments ({cancelledShipments.length})</Typography>
            {paginatedShipments.map(renderShipmentCard)}
          </>
        )}
      </Box>

      <Box sx={{ 
        p: 2, 
        borderTop: 1, 
        borderColor: 'divider',
        backgroundColor: theme.palette.background.default,
        display: 'flex', 
        justifyContent: 'center'
      }}>
        <Pagination
          count={Math.ceil((tabValue === 0 ? sentShipments.length : cancelledShipments.length) / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          
        />
      </Box>
    </Box>
  );
};

export default ShipmentHistory;
