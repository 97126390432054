import React, { useState, useEffect, SyntheticEvent, ChangeEvent } from 'react';
import { useAdminUsers } from './AdminUserContext';
import {
  Box,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Snackbar,
  Alert,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../axiosInstance';
import qs from 'qs';

interface UserFormProps {
  expanded: string | false;
  handleAccordionChange: (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

const UserForm: React.FC<UserFormProps> = ({ expanded, handleAccordionChange }) => {
  const { users, setUsers, roles, fetchRoles } = useAdminUsers();
  const [formState, setFormState] = useState({
    username: '',
    email: '',
    password: '',
    password2: '',
    role: '',
    isactive: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const handleChangeState = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    setFormState({
      ...formState,
      [e.target.name as string]: e.target.value,
    });
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const data = {
      username: formState.username,
      email: formState.email,
      password: formState.password,
      password2: formState.password2,
      role: formState.role,
      isactive: formState.isactive,
    };

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    try {
      const response = await axiosInstance.post('/auth/register', qs.stringify(data), config);
      const newUser = {
        ...formState,
        user_id: response.data.user.user_id,
      };
      setUsers([...users, newUser]);
      setSnackbarMessage(response.data.message || 'User created successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error creating user:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error creating user. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Accordion expanded={expanded === 'userForm'} onChange={handleAccordionChange('userForm')} sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Add New User</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: isMobile ? 2 : 4 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Username"
                    name="username"
                    value={formState.username}
                    onChange={handleChangeState}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={formState.email}
                    onChange={handleChangeState}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formState.password}
                    onChange={handleChangeState}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Confirm Password"
                    name="password2"
                    type="password"
                    value={formState.password2}
                    onChange={handleChangeState}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Role</InputLabel>
                    <Select
                      name="role"
                      value={formState.role}
                      onChange={handleChangeState}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Active</InputLabel>
                    <Select
                      name="isactive"
                      value={formState.isactive ? 'true' : 'false'}
                      onChange={(e) => setFormState({ ...formState, isactive: e.target.value === 'true' })}
                    >
                      <MenuItem value="true">Active</MenuItem>
                      <MenuItem value="false">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Create User
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserForm;
