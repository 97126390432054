import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Box, useTheme, GlobalStyles, useMediaQuery } from '@mui/material';

const PDFViewer = () => {
    const theme = useTheme();
    const isLightTheme = theme.name === 'light';
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            
            <Box
                sx={{
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'background.default', 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: isMobile ? '24px' : '0px'
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'background.default', 
                    }}
                >
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}>
                        <Viewer
                            theme={isLightTheme ? 'light' : 'dark'}
                            fileUrl='/Dokumentacja_użytkownika_DACAPI_v1_1.pdf'
                            plugins={[defaultLayoutPluginInstance]}
                            defaultScale={isMobile ? 0.6 : 2.0}
                        />
                    </Worker>
                </Box>
            </Box>
        </>
    );
};

export default PDFViewer;
