import React, { SyntheticEvent } from 'react';
import { useAdminUsers } from './AdminUserContext';
import { Box, Select, MenuItem, FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../axiosInstance';

interface UserRolesProps {
  expanded: string | false;
  handleAccordionChange: (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

const UserRoles: React.FC<UserRolesProps> = ({ expanded, handleAccordionChange }) => {
  const { users, roles, fetchUsers } = useAdminUsers();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleRoleChange = async (userId: string, newRole: string) => {
    try {
      await axiosInstance.put(`/auth/users/${userId}/role`, null, {
        params: {
          role: newRole,
        },
      });
      fetchUsers();
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <Accordion expanded={expanded === 'roles'} onChange={handleAccordionChange('roles')} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Manage User Roles</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {users.map((user) => (
            <Grid item xs={12} sm={6} md={4} key={user.user_id}>
              <FormControl fullWidth margin="normal">
                <InputLabel>{user.username} ({user.email})</InputLabel>
                <Select
                  value={user.role}
                  onChange={(e) => handleRoleChange(user.user_id, e.target.value)}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UserRoles;
