
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginForm from "./Pages/LoginForm/LoginForm";

import NotFoundPage from "./Pages/NotFoundPage";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import HomePage from "./Pages/HomePage/HomePage";
import Layout from "./Layout/Layout";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";


import MapPage from "./Pages/MapPanel/MapPanel";

import FeedbackPage from "./Pages/Feedback/FeedbackPage";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";

import AddDevice from "./Pages/HomePage/AddDevice";
import EditDevice from "./Pages/HomePage/EditDevice";
import DeviceDetails from "./Pages/HomePage/DeviceDetails";
import MenuItemsManager from "./Pages/HomePage/MenuItemsManager";
import DataTables from "./Pages/DataTables/DataTables";
import { RefreshProvider } from "./Components/RefreshContext";
import CustomRequireAuth from "./Pages/CustomRequireAuth";
import RepairPanel from "./Pages/RepairPanel/RepairPanel";
import AddShipment from './Pages/Shipments/AddShipment';

import DocsPanel from "./Pages/DocsPanel/DocsPanel";
import ShipmentPanel from './Pages/Shipments/ShipmentPanel';
import AddDetails from "./Pages/Shipments/AddDetails";
import InstalationPage from "./Pages/InstalationPage/InstalationPage";
import InstalationDetailPage from "./Pages/InstalationPage/InstalationDetailPage";
import RoleBasedRoute from "./Pages/RoleBasedRoute";


function App() {
  const router = createBrowserRouter([
    

    {
      path: "/login",
      element: <LoginForm />,
      errorElement: <NotFoundPage />,
    },
    {
      element: (
        <CustomRequireAuth>
          <Layout />
        </CustomRequireAuth>
      ),
      children: [
        {
          path: "/",
          element: <RoleBasedRoute
            element={<HomePage />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
            redirectToLoginOnDisallowed={true} 
          />,
        },
        {
          path: "/installation",
          element: <RoleBasedRoute
            element={<InstalationPage />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+', 'instalator']}
          />,
        },
        {
          path: "/installation/:boxNumber",
          element: <RoleBasedRoute
            element={<InstalationDetailPage />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+', 'instalator']}
          />,
        },
        {
          path: "/profile",
          element: <RoleBasedRoute
            element={<ProfilePage />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+', 'instalator']}
          />,
        },


        {
          path: "/map",
          element: <RoleBasedRoute
            element={<MapPage />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/feedback",
          element: <RoleBasedRoute
            element={<FeedbackPage />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/docs",
          element: <RoleBasedRoute
            element={<DocsPanel />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/admin",
          element: <RoleBasedRoute
            element={<AdminPanel />}
            allowedRoles={['admin', 'serwis+']}
          />,
        },
        {
          path: "/data",
          element: <RoleBasedRoute
            element={<DataTables />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },

        {
          path: "/add-device",
          element: <RoleBasedRoute
            element={<AddDevice />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/edit-device/:deviceNumber",
          element: <RoleBasedRoute
            element={<EditDevice />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/device/:deviceNumber",
          element: <RoleBasedRoute
            element={<DeviceDetails />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/menu-items-manager",
          element: <RoleBasedRoute
            element={<MenuItemsManager />}
            allowedRoles={['admin']}
          />,
        },
        {
          path: "/repairs",
          element: <RoleBasedRoute
            element={<RepairPanel />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/shipments",
          element: <RoleBasedRoute
            element={<ShipmentPanel />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/shipments/add",
          element: <RoleBasedRoute
            element={<AddShipment />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        },
        {
          path: "/shipments/add-details/:groupId",
          element: <RoleBasedRoute
            element={<AddDetails />}
            allowedRoles={['admin', 'user', 'serwis', 'serwis+']}
          />,
        }
      ],
    }
  ]);

  const store = createStore({
    authType: "cookie",
    authName: "_auth",
    cookieDomain: window.location.hostname,
    cookieSecure: false,
  });

  return (
    <>
      <AuthProvider store={store}>
        <RefreshProvider>
          <RouterProvider router={router} />
        </RefreshProvider>
      </AuthProvider>
    </>
  );
}

export default App;
