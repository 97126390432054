import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Snackbar,
  Alert,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Button,
  TextField
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import  { publicInstance } from '../axiosInstance';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import DeviceInformation from './DeviceInformation';
import GPSInformation from './GPSInformation';
import ServiceComments from './ServiceComments';
import SensorBoxDetails from './SensorBoxDetails';
import ConnectionStats from './ConnectionStats';
import ChangeHistory from './ChangeHistory';
import HistoryIcon from '@mui/icons-material/History';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const DeviceDetails: React.FC = () => {
  const { deviceNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [deviceData, setDeviceData] = useState<any>(null);
  const [gpsData, setGpsData] = useState<any>(null);
  const [sensorBoxData, setSensorBoxData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [historyOpen, setHistoryOpen] = useState(false);
  const [latestChanges, setLatestChanges] = useState<any[]>([]);
  const [searchDeviceNumber, setSearchDeviceNumber] = useState('');

  useEffect(() => {

    setDeviceData(null);
    setGpsData(null);
    setSensorBoxData(null);
    setLatestChanges([]);
    setError('');
    setLoading(true);

    const fetchData = async () => {
      try {
        const response1 = await publicInstance.get(`/dacapi_sensorbox_introduction_form?box=eq.${deviceNumber}`);
        setDeviceData(response1.data[0]);

        

        try {
          const response2 = await publicInstance.get(`/view_dacapi_basic_sensorbox_information?box=eq.${deviceNumber}`);
          const sensorBoxData = response2.data[0];
        
          
          const gpsData = {
            box: sensorBoxData.box || null,
            time_stamp_sensorbox: sensorBoxData.time_stamp_sensorbox || null,
            modem_gps: sensorBoxData.modem_gps || null,
            gps: sensorBoxData.gps || null,
            scripts_comments_gps: sensorBoxData.scripts_comments_gps || null,
            time_stamp_gps: sensorBoxData.time_stamp_gps || null,
          };
          console.log(gpsData);
         
          setGpsData(gpsData);
        
         
          setSensorBoxData(sensorBoxData);
        
        } catch (sensorBoxError) {
          console.warn(`Error fetching Sensor Box data: ${sensorBoxError.message}`);
        }

        const endpoints = [
          `/dacapi_sensorbox_introduction_form_changes?box=eq.${deviceNumber}`,
          `/dacapi_sensorbox_changes?box=eq.${deviceNumber}`,
          `/dacapi_board_changes?box=eq.${deviceNumber}`,
          `/dacapi_gps_changes?box=eq.${deviceNumber}`,
          `/dacapi_bts_changes?box=eq.${deviceNumber}`,
        ];

        const allChanges = await Promise.all(
          endpoints.map(endpoint => publicInstance.get(endpoint).catch(() => ({ data: [] })))
        );

        const mergedChanges = allChanges.flatMap(response => response.data);
        const sortedChanges = mergedChanges.sort((a, b) => new Date(b.changed_at).getTime() - new Date(a.changed_at).getTime());

        setLatestChanges(sortedChanges.slice(0, 2));
        setLoading(false);
      } catch (error) {
        setError(`Error fetching device data: ${error.message}`);
        setLoading(false);
      }
    };

    fetchData();

    if (location.state?.successMessage) {
      setSuccessMessage(location.state.successMessage);
    }
  }, [deviceNumber, location.state]);


  const handleSearch = () => {
    if (searchDeviceNumber) {
      navigate(`/device/${searchDeviceNumber}`);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  let latitude, longitude;
  if (gpsData) {
    const { gps } = gpsData;
    const coords = gps.replace('N', '').replace('E', '').split(' ').map(coord => parseFloat(coord));
    latitude = coords[0];
    longitude = coords[1];
  }

  const handleSnackbarClose = () => {
    setSuccessMessage('');
  };

  const handleHistoryOpen = () => {
    setHistoryOpen(true);
  };

  const handleHistoryClose = () => {
    setHistoryOpen(false);
  };

  const renderChangeItem = (change: any) => {
    let oldValue, newValue;
    if ('old_gps' in change && 'new_gps' in change) {
      oldValue = change.old_gps;
      newValue = change.new_gps;
    } else if ('old_lac' in change && 'old_cid' in change && 'new_lac' in change && 'new_cid' in change) {
      oldValue = `${change.old_lac}, ${change.old_cid}`;
      newValue = `${change.new_lac}, ${change.new_cid}`;
    } else {
      oldValue = change.old_value;
      newValue = change.new_value;
    }

    return (
      <Box key={change.id} mb={0.5}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
          Field: {change.field_name}
        </Typography>
        <Box display="flex" alignItems="center" mb={0.5}>
          <Typography variant="body2" color="error" sx={{ mr: 0.5 }}>
            {oldValue}
          </Typography>
          <ArrowForwardIcon sx={{ color: 'grey', fontSize: 16 }} />
          <Typography variant="body2" color="success.main" sx={{ ml: 0.5 }}>
            {newValue}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ mb: 0.5 }}>
          Changed At: {new Date(change.changed_at).toLocaleString()}
        </Typography>
        <Divider sx={{ my: 0.5 }} />
      </Box>
    );
  };

  const components = [
    <Grid item xs={12} xl={4} key="device-info">
      <DeviceInformation deviceNumber={deviceNumber} deviceData={deviceData} />
      <ServiceComments deviceNumber={deviceNumber} />
    </Grid>,
    sensorBoxData && (
      <Grid item xs={12} xl={4} key="sensor-box-details">
        <SensorBoxDetails deviceNumber={deviceNumber} />
      </Grid>
    ),
    <Grid item xs={12} xl={4} key="connection-stats">
      <ConnectionStats deviceNumber={deviceNumber} />
      <GPSInformation gpsData={gpsData} latitude={latitude} longitude={longitude} />
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={
            <Typography variant="h5" sx={{}}>
              Latest Changes
            </Typography>
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ pt: 0 }}>
          {latestChanges.length > 0 ? (
            <List>
              {latestChanges.map(change => (
                <ListItem key={change.id} sx={{ p: 0 }}>
                  <ListItemText primary={renderChangeItem(change)} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2">No recent changes found.</Typography>
          )}
          <Button variant="contained" color="primary" onClick={handleHistoryOpen} startIcon={<HistoryIcon />} >
            View Full Change History
          </Button>
        </CardContent>
      </Card>
      <ChangeHistory deviceNumber={deviceNumber} open={historyOpen} onClose={handleHistoryClose} />
    </Grid>,
  ].filter(Boolean);

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', }}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 2, mr: 3.5 }}>
          <IconButton
            onClick={() => navigate(`/device/${Number(deviceNumber) - 1}`)}
            sx={{ color: '#1976d2' }}
            disabled={Number(deviceNumber) <= 1}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ textAlign: 'center', color: '#1976d2', fontWeight: 'bold', mx: 2 }}>
            Device Details for {deviceNumber}
          </Typography>
          <IconButton
            onClick={() => navigate(`/device/${Number(deviceNumber) + 1}`)}
            sx={{ color: '#1976d2' }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, mr: 12 }}>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              mb: 2,
              mt: 1,
              width: 'auto',
              alignSelf: 'flex-start'
            }}
          >
            Back
          </Button>
          <Box display="flex" alignItems="center" sx={{ justifyContent: 'center', flexGrow: 1 }}>
            <TextField
              label="Device Number"
              variant="outlined"
              size="small"
              value={searchDeviceNumber}
              onChange={(e) => setSearchDeviceNumber(e.target.value.replace(/\s+/g, ''))}
              onKeyPress={handleKeyPress}
              sx={{ mr: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
          {components}
        </Grid>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default DeviceDetails;
