import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useAdminUsers } from './AdminUserContext';
import { Button, Box, Snackbar, Alert, Avatar } from '@mui/material';
import { styled, ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import axiosInstance from '../axiosInstance';

const UserTable: React.FC = () => {
  const { users, setUsers } = useAdminUsers();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const theme = useTheme();

  const handleDelete = async (id: string) => {
    try {
      await axiosInstance.delete(`/auth/users/${id}`);
      setUsers(users.filter((user) => user.user_id !== id));
      setSnackbarMessage('User deleted successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbarMessage('Error deleting user. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    { field: 'user_id', headerName: 'ID', width: 90 },
    { field: 'username', headerName: 'Username', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'is_active', headerName: 'Active', width: 80 },
    { field: 'role', headerName: 'Role', width: 80 },
    {
      field: 'last_login',
      headerName: 'Last Login',
      width: 270,
      renderCell: (params: any) => {
        const lastLoginDate = new Date(params.value);
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate.getTime() - lastLoginDate.getTime());
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        const themeMode = theme.name ;

        let color;

        if (themeMode === 'light') {
          if (daysDifference <= 7) {
            color = '#008000'; 
          } else if (daysDifference <= 30) {
            color = '#CCCC00'; 
          } else {
            color = '#B22222'; 
          }
        } else {
          if (daysDifference <= 7) {
            color = '#00FF00'; 
          } else if (daysDifference <= 30) {
            color = '#FFFF00'; 
          } else {
            color = '#FF5555'; 
          }
        }

        return (
          <span style={{ color: color }}>
            {lastLoginDate.toLocaleString()}
          </span>
        );
      },
    }
    ,
    {
      field: 'profile_image_path',
      headerName: 'Profile Image',
      width: 130,
      renderCell: (params: any) => (
        <Avatar alt={params.row.username} sx={{ ml: 3, mt: 1 }} src={params.row.profile_image_path} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => (
        <Box>
          <Button variant="contained" color="error" onClick={() => handleDelete(params.row.user_id)}>
            Delete
          </Button>
        </Box>
      ),
    },
  ];


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "42vh", width: '100%' }}>
        <DataGrid
          rows={users}
          columns={columns}
          checkboxSelection
          getRowId={(row) => row.user_id}
          sortModel={[
            {
              field: 'role',
              sort: 'asc',
            },
          ]}
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default UserTable;
