import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Box, Snackbar, Alert, useTheme, useMediaQuery, IconButton, Tooltip } from '@mui/material';
import { Add as AddIcon, HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import  {publicInstance} from '../axiosInstance';
import DeviceTable from './DeviceTable';
import { Device } from './types';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const HomePage: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();
  const [role, setRole] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    setRole(storedRole);

    publicInstance.get('/dacapi_sensorbox_introduction_form')
      .then(response => {
        setDevices(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching devices:', error);
        setLoading(false);
      });

    if (location.state?.successMessage) {
      setSnackbarMessage(location.state.successMessage);
      setSnackbarOpen(true);
    }
  }, [location.state]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, '');
    setSearch(valueWithoutSpaces);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigate(`/device/${search}`);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDelete = (deviceNumber: number) => {
    setDevices((prevDevices) => prevDevices.filter((device) => device.box !== deviceNumber));
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container maxWidth={false} >
        <Box display="flex" alignItems="center" mb={2} flexDirection={isMobile ? 'column' : 'row'}>
          <Typography variant="h6" sx={{ marginRight: isMobile ? 0 : 2, marginBottom: isMobile ? 2 : 0 }}>
            {`${devices.length} Devices`}
          </Typography>
          <Link to="/add-device" style={{ textDecoration: 'none', marginBottom: isMobile ? 2 : 0 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginRight: isMobile ? 0 : 2, width: isMobile ? '100%' : 'auto' }}
              startIcon={<AddIcon />}
            >
              Add New Device
            </Button>
          </Link>
          <Box display="flex" alignItems="center" sx={{ width: isMobile ? '100%' : 'auto' }}>
            <TextField
              label="Search by Device Number"
              value={search}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              variant="outlined"
              sx={{ width: isMobile ? '100%' : 300, backgroundColor: 'white', marginBottom: isMobile ? 2 : 0 }}
            />
            
          </Box>
          {role === 'admin' || role === 'serwis+' ? (
            <Link to="/menu-items-manager" style={{ marginLeft: 'auto', textDecoration: 'none', width: isMobile ? '100%' : 'auto' }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                sx={{ marginLeft: isMobile ? 0 : 'auto', width: isMobile ? '100%' : 'auto' }}
              >
                Menu Items Manager
              </Button>
            </Link>
          ) : null}
        </Box>
        <DeviceTable devices={devices.filter(device => device.box.toString().includes(search))} loading={loading} onDelete={handleDelete} />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default HomePage;
