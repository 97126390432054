import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axiosInstance from '../Pages/axiosInstance'; // Import axiosInstance

// Definiujemy interfejsy dla typów użytkownika i kontekstu użytkownika
interface User {
  role: ReactNode;
  username: string;
  email: string;
  profile_image_path: string;
  // dodaj inne właściwości użytkownika tutaj
}

interface UserContextType {
  user: User | null;
  loading: boolean;
  fetchUser: () => Promise<void>;
}

// Tworzymy kontekst użytkownika
export const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

// UserProvider z fetchowaniem danych użytkownika
export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    try {
      const response = await axiosInstance.get('/auth/me');
      const userData = response.data;
      setUser(userData);

      // Zapisz username w localStorage
      localStorage.setItem('username', userData.username);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
