import React, { useEffect, useState } from 'react';
import { 
  Card, CardContent, CardHeader, Grid, Typography, Box, CircularProgress, 
  Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, IconButton, Tooltip
} from '@mui/material';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import GrafanaIcon from '../../Components/Assets/Grafana_icon.svg';

interface ConnectionStatsProps {
  deviceNumber: string;
}

const getDotColor = (percentage: number): string => {
  if (percentage > 83.3) return 'green';
  if (percentage > 47.6) return 'yellow';
  return 'red';
};

const ConnectionStats: React.FC<ConnectionStatsProps> = ({ deviceNumber }) => {
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [timeRange, setTimeRange] = useState<string>('7d');

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        let endpoint = `/form/box7d/${deviceNumber}`;
        if (timeRange === '1d') {
          endpoint = `/form/box1d/${deviceNumber}`;
        } else if (timeRange === '30d') {
          endpoint = `/form/box30d/${deviceNumber}`;
        }
        const response = await axiosInstance.get(endpoint);
        setStats(response.data.data ? response.data.data[0] : {});
        setLoading(false);
      } catch (error) {
        setError(`Error fetching connection stats: ${error.message}`);
        setLoading(false);
      }
    };

    fetchStats();
  }, [deviceNumber, timeRange]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTimeRange(event.target.value as string);
  };

  const statsKeys = ['sum_vpna', 'sum_vpns', 'sum_vpnb', 'sum_ping'];
  const labels = ['VPN A', 'VPN S', 'VPN B', 'Ping'];

  const validStats = stats && typeof stats === 'object';

  const max = timeRange === '30d' ? 180 : timeRange === '7d' ? 42 : 6;

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title="Connection Stats"
        action={
          <Box display="flex" alignItems="center">
            <FormControl variant="outlined" size="small" sx={{ mr: 6 }}>
              <InputLabel>Time Range</InputLabel>
              <Select
                value={timeRange}
                onChange={handleChange}
                label="Time Range"
              >
                <MenuItem value="1d">Last 1 Day</MenuItem>
                <MenuItem value="7d">Last 7 Days</MenuItem>
                <MenuItem value="30d">Last 30 Days</MenuItem>
              </Select>
            </FormControl>
            
          </Box>
        }
      />
      <CardContent>
        {loading ? (
          <Grid container spacing={2}>
            {statsKeys.map((_, index) => (
              <Grid item xs={3} key={index}>
                <Box display="flex" justifyContent="center" alignItems="center" height={80}>
                  <CircularProgress />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {statsKeys.map((key, index) => {
              const value = validStats ? stats[key] : 0;
              const percentage = ((value / max) * 100).toFixed(1);

              return (
                <Grid item xs={3} key={index}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                      {labels[index]}
                    </Typography>
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getDotColor(parseFloat(percentage)),
                        ml: 1,
                      }}
                    />
                  </Box>
                  <Typography variant="h5" sx={{ mt: 1 }}>
                    {`${percentage}%`}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      </CardContent>
    </Card>
  );
};

export default ConnectionStats;
