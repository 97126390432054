import React from 'react';
import BoxNumberSearch from './BoxNumberSearch';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function InstalationPage() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column',   p: 4 }}>
            <BoxNumberSearch />
            <Typography variant="h4" component="h1" gutterBottom>
                Instalation Page
            </Typography>
            
        </Box>
    );
}

export default InstalationPage;
