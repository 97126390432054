import React from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';

export const CustomTextField = ({ label, value, onChange, required = false, multiline = false, minRows, sx }) => (
  <TextField
    label={label}
    variant="outlined"
    fullWidth
    required={required}
    value={value}
    onChange={onChange}
    multiline={multiline}
    minRows={multiline ? minRows : undefined}
    sx={sx}
  />
);

export const CustomSelect = ({ label, value, onChange, options, required = false }) => (
  <FormControl fullWidth required={required}>
    <InputLabel>{label}</InputLabel>
    <Select value={value} label={label} onChange={onChange}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export const CustomSwitch = ({ label, checked, onChange }) => (
  <FormControlLabel
    control={<Switch checked={checked} onChange={onChange} />}
    label={label}
  />
);
