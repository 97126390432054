import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import BoxNumberSearch from './BoxNumberSearch';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';


function InstalationDetailPage() {
    const theme = useTheme();
    const { boxNumber } = useParams();
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState<Record<string, any> | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState<string>('Checking connection...');
    const [loadingConnection, setLoadingConnection] = useState<boolean>(true);
    const [serviceStatus, setServiceStatus] = useState<string>('');
    const [fetchStatus, setFetchStatus] = useState<string>('');
    const [dots, setDots] = useState<string>('');
    const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
    const [rebooting, setRebooting] = useState<boolean>(false);
    const [restartingService, setRestartingService] = useState<boolean>(false);

    const isDarkMode = theme.name === 'dark';

    const buttonStyle = {
        '&.MuiButton-root': {
            backgroundColor: isDarkMode ? '#333333' : '#1976D2',
            color: isDarkMode ? '#E0E0E0' : '#FFFFFF',
            '&:hover': {
                backgroundColor: isDarkMode ? '#444444' : '#1565C0',
            },
            minWidth: '150px',
            margin: '8px',
        },
    };

    const disabledButtonStyle = {
        '&.MuiButton-root': {
            backgroundColor: isDarkMode ? '#555555' : '#E0E0E0',
            color: isDarkMode ? '#B0B0B0' : '#9E9E9E', // Zmieniono kolor tekstu, aby był widoczny w trybie ciemnym
            border: `1px solid ${isDarkMode ? '#777777' : '#CCCCCC'}`,
            opacity: 0.5,
            cursor: 'not-allowed',
            minWidth: '150px',
            margin: '8px',
        },
        '&.Mui-disabled': {
            backgroundColor: isDarkMode ? '#555555' : '#E0E0E0',
            color: isDarkMode ? '#B0B0B0' : '#9E9E9E',
        },
    };

    const customAlertStyle = {
        backgroundColor: isDarkMode ? '#333' : '#E0F7FA',  // Ciemne tło dla dark mode
        color: isDarkMode ? '#A8DADC' : '#00796B',  // Kolor tekstu
        border: isDarkMode ? '1px solid #457B9D' : '1px solid #004D40',  // Obwódka
        borderRadius: '8px',  // Zaokrąglenie rogów
        mt: 2,  // Margines górny
    };

    const ranges = {
        "pm25": [0, 200],
        "pm10": [0, 200],
        "dust temp": [10, 50],
        "dust humid": [10, 80],
        "ambient temp": [-20, 40],
        "ambient press": [900, 1200],
        "ambient humid": [20, 90],
        "dust heater ntc": [10, 70],
        "dust rpm": [4450, 4550],
    };

    const getAdditionalDataColor = (key: string, value: string | undefined) => {
        if (typeof value !== 'string') {
            return 'black';
        }

        switch (key) {
            case 'network':
                return value.includes('OK') ? 'green' : 'red';
            case 'OSE_ESA':
                return value === 'OK' ? 'green' : 'red';
            case 'Displays MAC':
                return value !== 'N/A' ? 'green' : 'red';
            case 'SIM':
                if (value === 'ORANGE') return 'orange';
                if (value === 'PLUS') return 'green';
                if (value === 'PLAY') return 'violet';
                return 'red';
            case 'MODEM':
                return 'green';
            default:
                return 'black';
        }
    };

    useEffect(() => {
        const dotInterval = setInterval(() => {
            setDots(prevDots => (prevDots.length === 3 ? '' : prevDots + '.'));
        }, 500);

        return () => clearInterval(dotInterval);
    }, []);

    useEffect(() => {
        resetStates();
        const source = axios.CancelToken.source();
        setCancelSource(source);
        checkConnection(source);

        return () => {
            source.cancel('Operation canceled due to box number change.');
        };
    }, [boxNumber]);

    const resetStates = () => {
        setResponseData(null);
        setLoading(false);
        setError(null);
        setConnectionStatus('Checking connection...');
        setLoadingConnection(true);
        setServiceStatus('');
        setFetchStatus('');
    };

    const checkConnection = async (source: any) => {
        try {
            const response = await axiosInstance.get(`/serwis/run-check-connection/${boxNumber}`, {
                cancelToken: source.token,
            });
            if (response.data.status === "connection successful") {
                setConnectionStatus("Connection successful");
                setLoadingConnection(false);
                checkService(source);
            } else {
                throw new Error("Connection lost");
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Request canceled', err.message);
            } else {
                setTimeout(() => checkConnection(source), 5000);
            }
        }
    };

    const checkService = async (source: any) => {
        setLoading(true);
        const intervalId = setInterval(async () => {
            try {
                const response = await axiosInstance.get(`/serwis/run-service-check/${boxNumber}`, {
                    cancelToken: source.token,
                });
                if (response.data.output === "Service up") {
                    setServiceStatus("Service running");
                    clearInterval(intervalId);
                    // Add a 5-second pause before fetching data
                    setTimeout(() => handleButtonClick(source), 5000);
                } else if (response.data.output === "Service down") {
                    setServiceStatus("Service not running");
                    console.log('Service not running, will retry...');
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    console.log('Error while checking service status, will retry...');
                }
            }
        }, 5000);

        return () => clearInterval(intervalId);
    };

    const handleButtonClick = async (source: any) => {
        setLoading(true);
        setError(null);
        setFetchStatus('Fetching data...');

        try {
            const response = await axiosInstance.get(`/serwis/run-macio-read-box/${boxNumber}`, {
                cancelToken: source.token,
            });
            setResponseData(response.data);
            setFetchStatus('Data fetched');
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Request canceled', err.message);
            } else {
                setError('An error occurred while fetching data.');
                setFetchStatus('Failed to fetch data');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDeviceReboot = async () => {
        setRebooting(true);
        resetStates();  // Resetowanie wszystkich stanów
        try {
            const response = await axiosInstance.get(`/serwis/run-box-reboot/${boxNumber}`);
            if (response.data.output === "Reboot completed") {
                checkConnection(cancelSource);
            } else {
                setError("Reboot failed");
            }
        } catch (err) {
            setError("An error occurred during reboot.");
        } finally {
            setRebooting(false);
        }
    };

    const handleServiceRestart = async () => {
        setRestartingService(true);
        setServiceStatus(''); // Resetowanie statusu usługi
        setFetchStatus(''); // Resetowanie statusu pobierania
        try {
            const response = await axiosInstance.get(`/serwis/run-sv-restart/${boxNumber}`);
            if (response.data.output === "sv restart completed") {
                checkService(cancelSource);
            } else {
                setError("Service restart failed");
            }
        } catch (err) {
            setError("An error occurred during service restart.");
        } finally {
            setRestartingService(false);
        }
    };

    const handleRefresh = () => {
        setResponseData(null);
        setFetchStatus('');
        const newSource = axios.CancelToken.source();
        setCancelSource(newSource);
        handleButtonClick(newSource);
    };

    const extractMAC = (hardwareString: string | undefined): string => {
        if (!hardwareString) {
            return 'N/A';
        }
        const macMatch = hardwareString.match(/MAC\s([\dA-Fa-f:]+)/);
        return macMatch ? macMatch[1] : 'N/A';
    };

    const renderTable = (data: Record<string, any>, isMeasurement: boolean = false) => (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableBody>
                    {data && Object.entries(data).map(([key, value]) => {
                        const numericValue = parseFloat(value);
                        const [min, max] = ranges[key] || [null, null];
                        const isInRange = min !== null && max !== null && numericValue >= min && numericValue <= max;
                        const color = isMeasurement
                            ? isInRange ? 'green' : 'red'
                            : getAdditionalDataColor(key, value);

                        return (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell sx={{ color }}>
                                    {String(value)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const measurements = responseData ? {
        "pm25": responseData["pm25"],
        "pm10": responseData["pm10"],
        "dust temp": responseData["dust temp"],
        "dust humid": responseData["dust humid"],
        "ambient temp": responseData["ambient temp"],
        "ambient press": responseData["ambient press"],
        "ambient humid": responseData["ambient humid"],
        "dust heater ntc": responseData["dust heater ntc"],
        "dust rpm": responseData["dust rpm"]
    } : {};

    const additionalInfo = responseData ? {
        "network": responseData["network"],
        "OSE_ESA": responseData["OSE_ESA"],
        "Displays MAC": extractMAC(responseData["hardware"]),
        "SIM": responseData["SIM"],
        "MODEM": responseData["MODEM"]
    } : {};

    const isBoxOperational = () => {
        const measurementDataKeys = Object.keys(measurements);
        const areMeasurementsValid = measurementDataKeys.every((key) => {
            const numericValue = parseFloat(measurements[key]);
            const [min, max] = ranges[key] || [null, null];
            return min !== null && max !== null && numericValue >= min && numericValue <= max;
        });

        const additionalInfoKeys = Object.keys(additionalInfo);
        const isAdditionalInfoValid = additionalInfoKeys.every((key) => {
            const value = additionalInfo[key];
            const color = getAdditionalDataColor(key, value);
            return color !== 'red';
        });

        return areMeasurementsValid && isAdditionalInfoValid;
    };

    return (
        <Box sx={{ p: 4, maxWidth: '960px', position: 'relative' }}>
            <BoxNumberSearch />

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
                <Typography variant="h4" component="h1">
                    Dashboard for Box: {boxNumber}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    ml: { xs: 0, sm: 2 },
                    mt: { xs: 2, sm: 0 },
                    justifyContent: { xs: 'center', sm: 'flex-start' }
                }}>
                    <Button
                        onClick={handleDeviceReboot}
                        disabled={loadingConnection || rebooting}
                        variant="contained"
                        sx={loadingConnection || rebooting ? disabledButtonStyle : buttonStyle}
                    >
                        Reboot Device
                    </Button>
                    <Button
                        onClick={handleServiceRestart}
                        disabled={serviceStatus !== "Service running" || restartingService}
                        variant="contained"
                        sx={serviceStatus !== "Service running" || restartingService ? disabledButtonStyle : buttonStyle}
                    >
                        Restart Service
                    </Button>
                    <Button
                        onClick={handleRefresh}
                        disabled={fetchStatus !== 'Data fetched' || loading}
                        variant="contained"
                        sx={fetchStatus !== 'Data fetched' || loading ? disabledButtonStyle : buttonStyle}
                        startIcon={<RefreshIcon />}
                    >
                        Refresh
                    </Button>
                </Box>
            </Box>

            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                Status
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Box
                                    sx={{
                                        width: 12,
                                        height: 12,
                                        borderRadius: '50%',
                                        backgroundColor: connectionStatus === "Connection successful" ? 'green' : 'red',
                                        marginRight: 2,
                                    }}
                                />
                                <Typography>
                                    {loadingConnection ? `Checking connection${dots}` : connectionStatus}
                                </Typography>
                            </Box>
                            {connectionStatus === "Connection successful" && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: serviceStatus === "Service running" ? 'green' : 'red',
                                            marginRight: 2,
                                        }}
                                    />
                                    <Typography>
                                        {serviceStatus === "" ? `Checking service${dots}` : serviceStatus}
                                    </Typography>
                                </Box>
                            )}
                            {serviceStatus === "Service running" && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: fetchStatus === 'Data fetched' ? 'green' : 'red',
                                            marginRight: 2,
                                        }}
                                    />
                                    <Typography>
                                        {loading ? `Fetching data${dots}` : fetchStatus}
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {fetchStatus === 'Data fetched' && (
                    isBoxOperational() ? (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100px' }}>
                                    <CheckCircleIcon style={{ color: 'green', fontSize: 50 }} />
                                    <Typography variant="h5" component="div" color="green">
                                        Box is operational
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100px' }}>
                                    <CancelIcon style={{ color: 'red', fontSize: 50 }} />
                                    <Typography variant="h5" component="div" color="red">
                                        Box is not operational.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                )}

                {fetchStatus === 'Data fetched' && (
                    <>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Additional Data
                                    </Typography>
                                    {renderTable(additionalInfo)}
                                    {additionalInfo['Displays MAC'] === 'N/A' && (
                                        <Alert severity="info" sx={customAlertStyle}>
                                            Często tablica włącza się dłużej niż usługa. Najlepiej poczekać, aż na tablicy pojawią się wartości i wtedy dokonać refresh danych.
                                        </Alert>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Measurement Data
                                    </Typography>
                                    {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                                    {responseData && renderTable(measurements, true)}
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}

export default InstalationDetailPage;
