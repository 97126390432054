import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import axiosInstance from './axiosInstance'; 

const CustomRequireAuth = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem("redirectAfterLogin", location.pathname + location.search);
      navigate("/login");
    } else {
      axiosInstance.put('/form/update_last_login')
        
        .catch(error => {
          console.error('Error updating last login time:', error);
        });
    }
  }, [isAuthenticated, navigate, location]);

  return isAuthenticated ? children : null;
};

export default CustomRequireAuth;
