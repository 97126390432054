import React from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Avatar, IconButton, Tooltip, CircularProgress, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import ResetSearchIcon from '@mui/icons-material/ClearAll';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { SelectChangeEvent } from '@mui/material/Select';
import { Data, markerUrls, parseGpsInput } from './utils'; // Add parseGpsInput to the import
import { publicInstance } from '../axiosInstance'; // Make sure to import this

interface ControlPanelProps {
  isMobile: boolean;
  data: Data[];
  searchBox: string;
  selectedContract: string;
  selectedCategory: string;
  initialMarkerStats: { green: number, yellow: number, red: number, unknown: number };
  loading: boolean;
  unknownGps: Data[];
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleContractChange: (event: SelectChangeEvent<string>) => void;
  handleResetContracts: () => void;
  handleCategoryClick: (category: string) => void;
  handleLegendClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  searchGps: string;
  handleSearchGpsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchGps: () => void;
  handleResetSearch: () => void;
  setSearchedLocation: React.Dispatch<React.SetStateAction<{ lat: number; lng: number } | null>>;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  isMobile,
  data,
  searchBox,
  selectedContract,
  selectedCategory,
  initialMarkerStats,
  loading,
  unknownGps,
  handleSearchChange,
  handleContractChange,
  handleResetContracts,
  handleCategoryClick,
  handleLegendClick,
  searchGps,
  handleSearchGpsChange,

  handleResetSearch,
  setSearchedLocation
}) => {
  const handleSearchGps = async () => {
    if (/^\d+$/.test(searchGps)) {
      // If input is only digits, treat as RSPO
      try {
        const response = await publicInstance.get(`/view_dacapi_bts_search_near_installations?order=distance.asc&limit=1&rspo=eq.${searchGps}`);
        if (response.data && response.data.length > 0) {
          const { nask_latitude, nask_longitude } = response.data[0];
          setSearchedLocation({ lat: nask_latitude, lng: nask_longitude });
        } else {
          alert('No location found for this RSPO number.');
        }
      } catch (error) {
        console.error('Error fetching RSPO location:', error);
        alert('Error fetching location for RSPO number.');
      }
    } else {
      // Existing GPS parsing logic
      const parsedLocation = parseGpsInput(searchGps);
      if (parsedLocation) {
        setSearchedLocation(parsedLocation);
      } else {
        alert('Invalid GPS coordinates or RSPO number. Please use format: latitude, longitude\nExample: 52.2297, 21.0122\nOr: N: 52.2297, E: 21.0122\nOr enter RSPO number');
      }
    }
  };

  return (
    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2">Markers: {data.length}</Typography>
          <Typography variant="body2">Unknown GPS: {unknownGps.length}</Typography>
        </Box>
        {loading && <CircularProgress size={24} />}
      </Box>

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search by box number"
          value={searchBox}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          sx={{ width: '200px' }}
        />

        <Tooltip title="Enter GPS coordinates (e.g., 52.1, 19.28 or N: 52.1, E: 19.28) or RSPO number">
          <TextField
            label="Search GPS or RSPO"
            variant="outlined"
            size="small"
            value={searchGps}
            onChange={handleSearchGpsChange}
            placeholder="GPS coords or RSPO number"
            sx={{ width: '220px' }}
          />
        </Tooltip>
        <Button variant="contained" size="small" onClick={handleSearchGps}>
          Search GPS/RSPO
        </Button>
        <Tooltip title="Reset GPS/RSPO search">
          <IconButton onClick={handleResetSearch} size="small">
            <LocationOffIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Box sx={{ display: 'flex', gap: 0.5, flexGrow: 1 }}>
          {['green', 'yellow', 'red', 'unknown', ''].map((category) => (
            <Button
              key={category}
              sx={{ minWidth: 'auto', padding: '4px 8px', bgcolor: 'background.paper' }}
              onClick={() => handleCategoryClick(category)}
              variant="outlined"
              color={selectedCategory === category ? 'primary' : 'inherit'}
            >
              <Avatar
                src={category ? (category === 'unknown' ? markerUrls.caution : markerUrls[category]) : undefined}
                sx={{ width: 16, height: 16, mr: 0.5 }}
              />
              {category === '' ? 'All' : initialMarkerStats[category]}
            </Button>
          ))}
          <Tooltip title="Show Legend">
            <IconButton onClick={handleLegendClick} size="small"><InfoIcon /></IconButton>
          </Tooltip>
          <FormControl variant="outlined" size="small" sx={{ width: '150px', bgcolor: 'background.paper' }}>
            <InputLabel id="contract-select-label">Contract</InputLabel>
            <Select
              labelId="contract-select-label"
              value={selectedContract}
              label="Contract"
              onChange={handleContractChange}
            >
              <MenuItem value=""><em>All Contracts</em></MenuItem>
              {[...new Set(data.flatMap(d => d.contracts))].filter(Boolean).map(contract => (
                <MenuItem key={contract} value={contract}>{contract}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" size="small" onClick={handleResetContracts} sx={{ whiteSpace: 'nowrap' }}>
            Show All
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

export default ControlPanel;
