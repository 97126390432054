import React from 'react';
import { Box, Typography, IconButton, Tooltip, Avatar, Card, CardContent, CardActions, useTheme, useMediaQuery } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Link } from 'react-router-dom';

const CurrentServiceComments = ({ comments, users, onRepairClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ width: isMobile ? '100%' : '48%', display: 'flex', flexDirection: 'column', mb: isMobile ? 4 : 0 }}>
      <Typography variant="h4" gutterBottom>
        Current Service Comments ({comments.length})
      </Typography>
      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: isMobile ? '60vh' : '90vh',
      }}>
        {comments.map((comment) => {
          const user = users.find(u => u.username === comment.username);
          return (
            <Card key={comment.box} sx={{ mb: 2, p: 2, backgroundColor: theme.palette.background.paper, borderRadius: '8px' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', wordBreak: 'break-word' }}>
                <Avatar src={user?.profile_image_path} alt={comment.username} sx={{ mr: 2 }} />
                <Box sx={{ flexGrow: 1, mr: 2 }}>
                  <Typography variant="body2">
                    <strong>Username:</strong> {comment.username}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Comment:</strong> {comment.comment}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Box:</strong> {comment.box}
                  </Typography>
                </Box>
                <CardActions sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="View Device Panel">
                    <IconButton component={Link} to={`/device/${comment.box}`} color="primary">
                      <AssessmentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Report Repair">
                    <IconButton onClick={() => onRepairClick(comment.box)} color="primary">
                      <BuildIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default CurrentServiceComments;
