import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar, { SidebarItem } from "./Sidebar";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import { LogOut, Home, Map, Grid, UserPlus } from "lucide-react";
import BuildIcon from '@mui/icons-material/Build';
import FeedbackIcon from "@mui/icons-material/Feedback";

import axiosInstance, { publicInstance } from "../Pages/axiosInstance";
import { useRefresh } from "../Components/RefreshContext";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { lightTheme, darkTheme } from "../helpers/themes";
import { CssBaseline, useMediaQuery, GlobalStyles } from "@mui/material";
import Button from "@mui/material/Button";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useAtom } from 'jotai';
import { feedbackCountAtom, serviceCommentsCountAtom, shipmentsCountAtom, completedShipmentsCountAtom } from '../helpers/atoms';


const Layout: React.FC = () => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [feedbackCount, setFeedbackCount] = useAtom(feedbackCountAtom);
  const [serviceCommentsCount, setServiceCommentsCount] = useAtom(serviceCommentsCountAtom);
  const [shipmentsCount, setShipmentsCount] = useAtom(shipmentsCountAtom);
  const [completedShipmentsCount, setCompletedShipmentsCount] = useAtom(completedShipmentsCountAtom);
  const [theme, setTheme] = useState(darkTheme);
  const signOut = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState<string | null>(null);
  const [refreshFeedback, setRefreshFeedback] = useState(false);
  const { refresh } = useRefresh();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "light") {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }

    const storedRole = localStorage.getItem("role");
    setRole(storedRole);

    publicInstance.get('/dacapi_shipment_of_devices')
      .then(response => {
        const uniqueGroupIds = countUniqueGroupIds(response.data);
        setShipmentsCount(uniqueGroupIds);
        const uniqueCompletedGroupIds = countCompletedGroupIds(response.data);
        setCompletedShipmentsCount(uniqueCompletedGroupIds);
      })
      .catch(error => console.error('Error fetching shipments count:', error));

    publicInstance.get('/dacapi_feedback')
      .then(response => {
        setFeedbackCount(response.data.length);
      })
      .catch(error => console.error('Error fetching feedback count:', error));

    publicInstance.get('/view_dacapi_sensorbox_service_comments')
      .then(response => {
        setServiceCommentsCount(response.data.length);
      })
      .catch(error => console.error('Error fetching service comments count:', error));
  }, [setFeedbackCount, setServiceCommentsCount, setShipmentsCount, setCompletedShipmentsCount]);

  const countUniqueGroupIds = (data) => {
    const filteredData = data.filter(item => item.status === 'utworzone');
    const uniqueGroupIds = new Set(filteredData.map(item => item.group_id));
    return uniqueGroupIds.size;
  };

  const countCompletedGroupIds = (data) => {
    const filteredData = data.filter(item => item.status === 'skompletowane');
    const uniqueGroupIds = new Set(filteredData.map(item => item.group_id));
    return uniqueGroupIds.size;
  };

  const handleSignOut = () => {
    signOut();
    localStorage.removeItem("role");
    navigate("/login");
  };

  const handleOpenFeedback = () => setOpenFeedback(true);
  const handleCloseFeedback = () => {
    setOpenFeedback(false);
    setFeedback("");
  };
  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleFeedbackSubmit = async () => {
    try {
      await axiosInstance.post("/form/feedback/", { comment: feedback });
      setSnackbarMessage("Feedback sent successfully!");
      setFeedback("");
      handleCloseFeedback();
      setRefreshFeedback(true);
      setFeedbackCount(prevCount => prevCount + 1);
    } catch (error) {
      setSnackbarMessage("Failed to send feedback. Please try again.");
    }
    setOpenSnackbar(true);
  };

  const updateTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === lightTheme ? darkTheme : lightTheme;
      localStorage.setItem("theme", newTheme === lightTheme ? "light" : "dark");
      return newTheme;
    });
  };

  const updateFeedbackCount = (count: number) => {
    setFeedbackCount(count);
  };

  const updateServiceCommentsCount = (count: number) => {
    setServiceCommentsCount(count);
  };

  const updateShipmentsCount = (count: number) => {
    setShipmentsCount(count);
  };

 

  const refreshFeedbackList = () => {
    setRefreshFeedback((prev) => !prev);
  };

  const handleRepair = (box: number) => {
    console.log(`Repair handled for box: ${box}`);
    setServiceCommentsCount(prevCount => prevCount - 1);
  };

  const feedbackModalBody = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        outline: "none",
      }}
    >
      <Typography variant="h6" component="h2">
        Send Feedback
      </Typography>
      <TextField
        autoFocus
        margin="dense"
        id="feedback"
        label="Your Feedback"
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}>
        <Button onClick={handleCloseFeedback}>Cancel</Button>
        <Button onClick={handleFeedbackSubmit}>Submit</Button>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={(theme: any) => ({
        '*::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '*::-webkit-scrollbar-track': {
          background: theme.palette.background.default,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: '8px',
          border: `2px solid ${theme.palette.background.default}`,
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      })} />
      <div className="layout" style={{ display: 'flex', minHeight: '100vh' }}>
        <Sidebar handleOpenFeedback={handleOpenFeedback} updateTheme={updateTheme} theme={theme} darkTheme={darkTheme}>
          {role === "instalator" ? (
            <Link to="/installation">
              <SidebarItem
                icon={<BuildIcon />}  
                text="Installation Page"
                active={location.pathname === "/installation"}
              />
            </Link>
          ) : (
            <>
              <Link to="/">
                <SidebarItem
                  icon={<Home />}
                  text="Home"
                  active={location.pathname === "/"}
                />
              </Link>
              <Link to="/map">
                <SidebarItem
                  icon={<Map />}
                  text="Map"
                  active={location.pathname === "/map"}
                />
              </Link>
              <Link to="/data">
                <SidebarItem
                  icon={<Grid />}
                  text="Data Tables"
                  active={location.pathname === "/data"}
                />
              </Link>
              <Link to="/repairs">
                <SidebarItem
                  icon={<BuildIcon />}
                  text={`Repair Panel (${serviceCommentsCount})`}
                  active={location.pathname === "/repairs"}
                />
              </Link>
              {(role === "admin" || role === "serwis+") && (
                <>
                  <Link to="/admin">
                    <SidebarItem
                      icon={<UserPlus />}
                      text="Admin Panel"
                      active={location.pathname === "/admin"}
                    />
                  </Link>
                  <Link to="/installation">
                    <SidebarItem
                      icon={<BuildIcon />} 
                      text="Installation Page"
                      active={location.pathname === "/installation"}
                    />
                  </Link>
                </>
              )}
              {role === "admin" && (
                <>
                  <Link to="/shipments">
                    <SidebarItem
                      icon={<LocalShippingIcon />}
                      text={`Shipments (${shipmentsCount} | ${completedShipmentsCount})`}
                      active={location.pathname === "/shipments"}
                    />
                  </Link>
                  <Link to="/feedback">
                    <SidebarItem
                      icon={<FeedbackIcon />}
                      text={`Feedback (${feedbackCount})`}
                      active={location.pathname === "/feedback"}
                    />
                  </Link>
                </>
              )}
              <Link to="/docs">
                <SidebarItem
                  icon={<DescriptionOutlinedIcon />}
                  text="Documentation"
                  active={location.pathname === "/docs"}
                />
              </Link>
            </>
          )}
          <Link onClick={handleSignOut} to="/login">
            <SidebarItem
              icon={<LogOut />}
              text="Log out"
              active={null}
              alert={null}
            />
          </Link>
        </Sidebar>
        <div className="main-content" style={{ flexGrow: 1, marginTop: isMobile ? '32px' : '0' }}>
          <Outlet context={{ handleRepair, updateFeedbackCount, updateServiceCommentsCount, updateShipmentsCount, refreshFeedbackList, refreshFeedback }} />
        </div>
        <Modal
          open={openFeedback}
          onClose={handleCloseFeedback}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {feedbackModalBody}
        </Modal>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
