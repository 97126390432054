import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  GlobalStyles,
  useTheme,
  useMediaQuery
} from '@mui/material';
import axiosInstance from '../axiosInstance';
import { format } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { publicInstance } from '../axiosInstance';

const FeedbackColumns = () => {
  const [currentFeedback, setCurrentFeedback] = useState([]);
  const [implementedFeedback, setImplementedFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const username = localStorage.getItem('username');

  const isLightTheme = theme.name === 'light';
  const borderColor = isLightTheme ? '#e0e0e0' : '#424242';

  const fetchCurrentFeedback = useCallback(() => {
    publicInstance.get('/dacapi_feedback')
      .then(response => {
        const filteredData = response.data
          .filter(item => item.username === username)
          .sort((a, b) => new Date(b.time_stamp).getTime() - new Date(a.time_stamp).getTime());
        setCurrentFeedback(filteredData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Error fetching current feedback data');
        setLoading(false);
      });
  }, [username]);

  const fetchImplementedFeedback = useCallback(() => {
    publicInstance.get('/dacapi_feedback_historical')
      .then(response => {
        const filteredData = response.data
          .filter(item => item.username === username)
          .sort((a, b) => new Date(b.time_stamp).getTime() - new Date(a.time_stamp).getTime());
        setImplementedFeedback(filteredData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching deleted feedback data:', error);
        setError('Error fetching implemented feedback data');
        setLoading(false);
      });
  }, [username]);

  useEffect(() => {
    fetchCurrentFeedback();
    fetchImplementedFeedback();
  }, [fetchCurrentFeedback, fetchImplementedFeedback]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">{error}</Alert>
    );
  }

  return (
    <>
      
      <Box sx={{ 
          backgroundColor: theme.palette.background.secondary, 
          borderRadius: '8px', 
          padding: 3, 
          mt: 4, 
          mx: { xs: 1, sm: 2, md: 3 }, 
          border: 1, 
          borderColor: borderColor 
        }}>
        <Box>
          <Typography variant="h4" align="center" gutterBottom>
            Your Feedback:
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', width: '100%', mb: isMobile ? 4 : 0 }}>
          
          <Box sx={{ width: { xs: '100%', md: '48%' }, display: 'flex', flexDirection: 'column', mb: { xs: 4, md: 0 } }}>
            <Typography variant="h6" gutterBottom>
              In Progress ({currentFeedback.length})
            </Typography>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: '45vh' }}>
              {currentFeedback.map((feedback) => (
                <Card key={feedback.id} sx={{ mb: 2, p: 2, backgroundColor: theme.palette.background.paper, borderRadius: '8px', position: 'relative' }}>
                  <CardContent sx={{ wordBreak: 'break-word' }}>
                    <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                      <PendingIcon color="warning" />
                    </Box>
                    <Typography variant="body2">
                      <strong>Comment:</strong> {feedback.comment}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Submitted on:</strong> {format(new Date(feedback.time_stamp), 'yyyy-MM-dd HH:mm:ss')}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

          <Box sx={{ width: { xs: '100%', md: '48%' }, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Implemented ({implementedFeedback.length})
            </Typography>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: '45vh' }}>
              {implementedFeedback.map((feedback) => (
                <Card key={feedback.id} sx={{ mb: 2, p: 2, backgroundColor: theme.palette.background.paper, borderRadius: '8px', position: 'relative' }}>
                  <CardContent sx={{ wordBreak: 'break-word' }}>
                    <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                      <CheckCircleIcon color="success" />
                    </Box>
                    <Typography variant="body2">
                      <strong>Comment:</strong> {feedback.comment}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Done on:</strong> {format(new Date(feedback.deletion_time), 'yyyy-MM-dd HH:mm:ss')}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FeedbackColumns;
