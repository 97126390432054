import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Card, CardContent, CardActions } from '@mui/material';
import axiosInstance, {publicInstance} from '../axiosInstance'; 

interface DeletedFeedbackListProps {
  updateDeletedFeedbackCount: (count: number) => void;
}

const DeletedFeedbackList: React.FC<DeletedFeedbackListProps> = ({ updateDeletedFeedbackCount }) => {
  const [deletedFeedbackList, setDeletedFeedbackList] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchFeedbacks = () => {
      publicInstance.get('/dacapi_feedback_historical')
        .then(response => {
          const sortedData = response.data.sort((a, b) => new Date(b.deletion_time).getTime() - new Date(a.deletion_time).getTime());
          setDeletedFeedbackList(sortedData);
          updateDeletedFeedbackCount(sortedData.length); 
        })
        .catch(error => console.error('Error fetching deleted feedback data:', error));
    };

    const fetchUsers = () => {
      axiosInstance.get('/auth/users')
        .then(response => {
          setUsers(response.data);
        })
        .catch(error => console.error('Error fetching users:', error));
    };

    fetchFeedbacks();
    fetchUsers();
  }, [updateDeletedFeedbackCount]);

  const handleDeleteFeedback = (feedbackId: number) => {
    publicInstance.delete(`/dacapi_feedback_historical?id=eq.${feedbackId}`)
      .then(() => {
        const updatedList = deletedFeedbackList.filter(feedback => feedback.id !== feedbackId);
        setDeletedFeedbackList(updatedList);
        updateDeletedFeedbackCount(updatedList.length); 
      })
      .catch(error => console.error('Error deleting feedback:', error));
  };

  return (
    <Box sx={{ width: '85%', margin: 'auto', mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Deleted Feedback List ({deletedFeedbackList.length})
      </Typography>
      {deletedFeedbackList.map((feedback) => {
        const user = users.find(u => u.username === feedback.username);
        return (
          <Card key={feedback.id} sx={{ mb: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Avatar src={user?.profile_image_path} alt={feedback.username} sx={{ mr: 2 }} />
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                <Typography variant="body2">
                  <strong>Username:</strong> {feedback.username}
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                  <strong>Comment:</strong> {feedback.comment}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Created At:</strong> {new Date(feedback.time_stamp).toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Deleted At:</strong> {new Date(feedback.deletion_time).toLocaleString()}
                </Typography>
              </Box>
              <CardActions>
                <Button variant="outlined" color="error" onClick={() => handleDeleteFeedback(feedback.id)}>
                  Delete
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default DeletedFeedbackList;
