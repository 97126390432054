import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { Data, parseGps, getMarkerIcon } from './utils';

interface MapContainerProps {
  data?: Data[];
  filteredData?: Data[];
  btsData?: any[];
  installationLocation?: { lat: number, lng: number } | null;
  handleMarkerClick?: (box: number) => void;
  handleBtsClick?: (btsGroup: any[]) => void;
  searchedLocation?: { lat: number, lng: number } | null;
  initialZoom: number;
}

function parseBtsLocation(location: string): { lat: number, lng: number } {
  const latPart = location.slice(0, 7);
  const latDegrees = parseInt(latPart.slice(0, 2));
  const latMinutes = parseInt(latPart.slice(3, 5));
  const latSeconds = parseInt(latPart.slice(5, 7));
  const latFinal = latDegrees + latMinutes / 60 + latSeconds / 3600;
  const latitude = latPart[2] === 'N' ? latFinal : -latFinal;

  const lngPart = location.slice(9);
  const lngDegrees = parseInt(lngPart.slice(0, 2));
  const lngMinutes = parseInt(lngPart.slice(3, 5));
  const lngSeconds = parseInt(lngPart.slice(5, 7));
  const lngFinal = lngDegrees + lngMinutes / 60 + lngSeconds / 3600;
  const longitude = lngPart[2] === 'E' ? lngFinal : -lngFinal;

  return { lat: latitude, lng: longitude };
}

const MapContainer: React.FC<MapContainerProps> = ({
  data,
  filteredData,
  btsData,
  installationLocation,
  handleMarkerClick,
  handleBtsClick,
  searchedLocation,
  initialZoom,
}) => {
  const [cameraProps, setCameraProps] = useState({
    center: installationLocation || { lat: 52.1, lng: 19.280556 },
    zoom: installationLocation ? 12 : initialZoom,
  });

  const fitBoundsToMarkers = () => {
    if (btsData && btsData.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      btsData.forEach((bts: any) => {
        const btsCoords = parseBtsLocation(bts.bts_location);
        bounds.extend(new google.maps.LatLng(btsCoords.lat, btsCoords.lng));
      });

      const center = bounds.getCenter().toJSON();
      setCameraProps({
        center,
        zoom: 12,
      });
    }
  };

  useEffect(() => {
    if (btsData && btsData.length > 0) {
      fitBoundsToMarkers();
    }
  }, [btsData]);

  const handleCameraChange = (event: any) => {
    setCameraProps({
      center: event.detail.center,
      zoom: event.detail.zoom,
    });
  };

  const getMarkerColor = (siec_id?: string) => {
    if (!siec_id) {
      return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    }

    switch (siec_id.toLowerCase()) {
      case 'orange':
        return 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png';
      case 'play':
        return 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png';
      case 'plus':
        return 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      default:
        return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    }
  };

  const groupBtsByLocation = (btsData: any[]) => {
    const groupedData: { [key: string]: any[] } = {};

    btsData.forEach((bts) => {
      const btsCoords = parseBtsLocation(bts.bts_location);
      const coordsKey = `${btsCoords.lat},${btsCoords.lng}`;

      if (!groupedData[coordsKey]) {
        groupedData[coordsKey] = [];
      }

      groupedData[coordsKey].push(bts);
    });

    return groupedData;
  };

  const groupedBtsData = groupBtsByLocation(btsData || []);

  const renderBtsMarkers = () => {
    const seenCoords: { [key: string]: number } = {};

    return btsData?.map((bts, index) => {
      const btsCoords = parseBtsLocation(bts.bts_location);
      const coordsKey = `${btsCoords.lat},${btsCoords.lng}`;
      const markerIcon = getMarkerColor(bts.siec_id);


      let markerSizeMultiplier = 1;
      let zIndex = 1;

      if (seenCoords[coordsKey]) {
        if (seenCoords[coordsKey] === 1) {
          markerSizeMultiplier = 1.3;
          zIndex = 0;
        } else if (seenCoords[coordsKey] === 2) {
          markerSizeMultiplier = 0.8;
          zIndex = 2;
        }
      }

      seenCoords[coordsKey] = (seenCoords[coordsKey] || 0) + 1;

      return (
        <Marker
          key={`bts-marker-${index}`}
          position={btsCoords}
          icon={{
            url: markerIcon,
            scaledSize: new google.maps.Size(30 * markerSizeMultiplier, 32 * markerSizeMultiplier),
          }}
          zIndex={zIndex}
          onClick={() => handleBtsClick && handleBtsClick(groupedBtsData[coordsKey])}
        />
      );
    });
  };

  useEffect(() => {
    if (searchedLocation) {
      setCameraProps({
        center: searchedLocation,
        zoom: 10,
      });
    } else {
      setCameraProps({
        center: { lat: 52.1, lng: 19.280556 },
        zoom: initialZoom,
      });
    }
  }, [searchedLocation, initialZoom]);

  return (
    <Box sx={{ height: '100%', flex: '1 1 auto' }}>
      <Map
        center={cameraProps.center}
        zoom={cameraProps.zoom}
        onCameraChanged={handleCameraChange}
      >
        {installationLocation && (
          <Marker position={installationLocation} />

        )}
        {renderBtsMarkers()}
        {filteredData?.map((d, index) => {
          const { lat, lng } = parseGps(d.gps);
          const markerIcon = getMarkerIcon(d.pm10, d.pm25, lat, lng);

          return (
            <Marker
              key={`marker-${d.box}-${index}`}
              position={{ lat, lng }}
              icon={markerIcon}
              zIndex={markerIcon.zIndex}
              onClick={() => handleMarkerClick && handleMarkerClick(d.box)}
            />
          );
        })}
        {searchedLocation && (
          <Marker
            position={searchedLocation}
            icon={{
              url: 'https://maps.gstatic.com/mapfiles/ms2/micons/blue.png',
                scaledSize: new google.maps.Size(60, 60),
            }}
          />
        )}
      </Map>
    </Box>
  );
};

export default MapContainer;
