import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../axiosInstance';

interface PasswordAccordionProps {
  expanded: string | false;
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const PasswordAccordion: React.FC<PasswordAccordionProps> = ({ expanded, handleAccordionChange }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');

  const handlePasswordChange = async () => {
    if (newPassword !== newPasswordConfirm) {
      setPasswordError('New passwords do not match');
      return;
    }

    try {
      const response = await axiosInstance.put('/auth/change-password', {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirm: newPasswordConfirm,
      });

      if (response.status === 200) {
        setPasswordSuccess('Password updated successfully');
        setPasswordError('');
      }
    } catch (error) {
      setPasswordError('Error changing password. Please try again.');
      console.error('Error changing password:', error);
    }
  };

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleAccordionChange('panel1')}
      sx={{ width: '100%', maxWidth: '400px', backgroundColor: 'background.default' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Change Password</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Current Password"
                type="password"
                fullWidth
                margin="normal"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm New Password"
                type="password"
                fullWidth
                margin="normal"
                value={newPasswordConfirm}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
              />
            </Grid>
            {passwordError && (
              <Grid item xs={12}>
                <Alert severity="error">{passwordError}</Alert>
              </Grid>
            )}
            {passwordSuccess && (
              <Grid item xs={12}>
                <Alert severity="success">{passwordSuccess}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePasswordChange}
                sx={{ marginTop: 2 }}
                fullWidth
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PasswordAccordion;
