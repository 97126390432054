import React, { useState } from 'react';
import { Box, Typography, TextField, MenuItem, Select, FormControl, InputLabel, IconButton, Tooltip, Avatar, Card, CardContent, CardActions, useTheme, useMediaQuery, Pagination } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Link } from 'react-router-dom';

const ServiceHistory = ({ comments, users }) => {
  const [searchBox, setSearchBox] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 10; 

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchChange = (event) => {
    setSearchBox(event.target.value);
    setCurrentPage(1); 
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(1); 
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const filteredComments = comments.filter((comment) => {
    const matchesBox = searchBox === '' || comment.box.toString().includes(searchBox);
    const matchesStatus = statusFilter === '' || comment.status === statusFilter;
    return matchesBox && matchesStatus;
  });

  const paginatedComments = filteredComments.slice(
    (currentPage - 1) * commentsPerPage,
    currentPage * commentsPerPage
  );

  const getStatusBackgroundColor = (status) => {
    return status === 'naprawione' ? theme.palette.status.naprawione : theme.palette.status.awaria;
  };

  return (
    <Box sx={{ width: isMobile ? '100%' : '48%', display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : '90vh' }}>
      <Typography variant="h4" gutterBottom>
        Service History
      </Typography>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          label="Search by Box"
          variant="outlined"
          value={searchBox}
          onChange={handleSearchChange}
          size="small"
          sx={{ width: '120px', marginRight: '16px', backgroundColor: theme.palette.background.paper }}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, backgroundColor: theme.palette.background.paper }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusChange}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="awaria">Awaria</MenuItem>
            <MenuItem value="naprawione">Naprawione</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {paginatedComments.map((comment) => {
          const user = users.find(u => u.username === comment.username);
          return (
            <Card key={comment.id} sx={{ mb: 2, backgroundColor: getStatusBackgroundColor(comment.status), borderRadius: '8px' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', wordBreak: 'break-word' }}>
                <Avatar src={user?.profile_image_path} alt={comment.username} sx={{ mr: 2 }} />
                <Box sx={{ flexGrow: 1, mr: 2 }}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                    <strong>Username:</strong> {comment.username}
                  </Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                    <strong>Comment:</strong> {comment.comment}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Box:</strong> {comment.box}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Status:</strong> {comment.status}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Time:</strong> {new Date(comment.time_stamp).toLocaleString()}
                  </Typography>
                </Box>
                <CardActions sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Tooltip title="View Device Panel">
                    <IconButton component={Link} to={`/device/${comment.box}`} color="primary">
                      <AssessmentIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </CardContent>
            </Card>
          );
        })}
      </Box>
      <Pagination
        count={Math.ceil(filteredComments.length / commentsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        sx={{ mt: 2, alignSelf: 'center' }}
      />
    </Box>
  );
};

export default ServiceHistory;
