import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, Typography, CircularProgress, Box, Divider, Grid, Button, Dialog, DialogTitle, DialogContent, Tooltip, IconButton
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SensorsIcon from '@mui/icons-material/Sensors';
import RouteIcon from '@mui/icons-material/Route';
import axiosInstance, { publicInstance } from '../axiosInstance';
import GrafanaIcon from '../../Components/Assets/Grafana_icon.svg';
import favicon from '../../Components/Assets/favicon.ico';
import esa from '../../Components/Assets/esa.svg';
import SchoolIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';

interface SensorBoxDetailsProps {
  deviceNumber: string;
}


const determineCarrier = (iccid) => {
  iccid = iccid.replace(/"/g, ""); 
  if (iccid.slice(5, 7) === "32") {
    return { carrier: "ORANGE", color: "#FF6600" };
  } else if (iccid.slice(5, 7) === "11") {
    return { carrier: "PLUS", color: "#00A651" };
  } else if (iccid.slice(5, 7) === "61") {
    return { carrier: "PLAY", color: "#660099" };
  } else {
    return { carrier: "UNKNOWN", color: "rgba(0, 0, 0, 0)" };
  }
};


const isValueNo = (value) => value === 'Yes';

const SensorBoxDetails: React.FC<SensorBoxDetailsProps> = ({ deviceNumber }) => {
  const [sensorBoxData, setSensorBoxData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');
  const [openSensors, setOpenSensors] = useState(false);
  const [openRoutes, setOpenRoutes] = useState(false);
  const [idEsa, setIdEsa] = useState(null);
  const [idInstalacji, setIdInstalacji] = useState(null);

  const fetchSensorBoxData = async () => {
    try {
      const { data } = await publicInstance.get(`/view_dacapi_basic_sensorbox_information?box=eq.${deviceNumber}`);
      setSensorBoxData(data[0]);
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchPublicData = async () => {
    try {
      const { data } = await publicInstance.get(`/dacapi_nask_id_our?box=eq.${deviceNumber}&select=id_esa,id_instalacji`);
      if (data.length > 0) {
        setIdEsa(data[0].id_esa);
        setIdInstalacji(data[0].id_instalacji);
      }
    } catch (err) {
      setError(`Error fetching public data: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchSensorBoxData();
    fetchPublicData();
  }, [deviceNumber]);

  const handleOpenSensors = () => {
    setOpenSensors(true);
  };

  const handleCloseSensors = () => {
    setOpenSensors(false);
  };

  const handleOpenRoutes = () => {
    setOpenRoutes(true);
  };

  const handleCloseRoutes = () => {
    setOpenRoutes(false);
  };

  const handleRefreshData = async () => {
    setRefreshing(true);
    try {
      await axiosInstance.get(`/form/run-dacapi-box-read/${deviceNumber}`);
      fetchSensorBoxData();
    } catch (err) {
      setError(`Error refreshing data: ${err.message}`);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const renderInfoRow = (label: string, value: string | React.ReactNode, fullWidth: boolean = false, isAlert: boolean = false) => (
    <Grid item xs={fullWidth ? 12 : 6}>
      <Box display="flex" alignItems="flex-start" sx={isAlert ? { border: '2px solid red', padding: '5px', borderRadius: '5px', backgroundColor: '#ffe6e6' } : {}}>
        {label && (
          <Typography variant="body2" fontWeight="bold" sx={{ mr: 1, color: isAlert ? 'red' : 'inherit' }}>
            {isAlert ? '!!! ' : ''}{label}{isAlert ? ' !!!' : ''}:
          </Typography>
        )}
        <Typography variant="body2" sx={{ wordBreak: 'break-word', color: isAlert ? 'red' : 'inherit' }}>
          {value}
        </Typography>
      </Box>
    </Grid>
  );

  const renderSection = (title: string, rows: { label: string, value: string | React.ReactNode, fullWidth?: boolean, isAlert?: boolean }[]) => (
    <Box mt={2} mb={1}>
      <Typography variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={1}>
        {rows.map(({ label, value, fullWidth, isAlert }) => renderInfoRow(label, value, fullWidth, isAlert))}
      </Grid>
    </Box>
  );

  const iccidInfo = sensorBoxData ? determineCarrier(sensorBoxData.iccid) : { carrier: "UNKNOWN", color: "#000000" };

  return (
    <Card sx={{  margin: 'auto', position: 'relative' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="h5" gutterBottom>
              Device Information
            </Typography>
            <Tooltip title="Refresh Data">
              <IconButton
                onClick={handleRefreshData}
                disabled={refreshing}
                sx={{ ml: 1, mb: 1 }}
              >
                {refreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
          {idInstalacji && (
              <Tooltip title="View Installation">
                <IconButton
                  component="a"
                  href={`https://instalacje.esa.nask.pl/schoolservce,request?id=${idInstalacji}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={esa} alt="esa Logo" width={60} height={40} />
                </IconButton>
              </Tooltip>
            )}
            {idEsa && (
              <Tooltip title="View on ESA">
                <IconButton
                  component="a"
                  href={`https://esa.nask.pl/szkola/id/${idEsa}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={favicon} alt="sowa Logo" width={40} height={40}  />
                </IconButton>
              </Tooltip>
            )}
            
            <Tooltip title="View on Grafana">
              <IconButton
                component="a"
                href={`https://logdb.test.sensorbox.pl/d/MiMb_wkVk/przeglad-boxa?orgId=1&refresh=5m&var-box=box${deviceNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GrafanaIcon} alt="Grafana Logo" width={40} height={40} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {sensorBoxData &&
          renderSection('Basic Information', [
            { label: 'Box', value: sensorBoxData.box },
            { label: 'Version DZ', value: sensorBoxData.version_dz },
            { label: 'Version STM', value: sensorBoxData.version_stm },
            { 
              label: 'Meteo', 
              value: sensorBoxData.meteo,
              isAlert: isValueNo(sensorBoxData.meteo)
            },
            { label: 'IMEI', value: sensorBoxData.imei, fullWidth: true },
            {
              label: 'ICCID',
              value: (
                <>
                  {sensorBoxData.iccid}
                  <Typography component="span" sx={{ color: iccidInfo.color, marginLeft: 1 }}>
                    ({iccidInfo.carrier})
                  </Typography>
                </>
              ),
              fullWidth: true
            },
            { label: 'RSSI', value: sensorBoxData.rssi },
            { label: 'Contract/CAS', value: sensorBoxData.contracts.join(', '), fullWidth: true },
            {
              label: '',
              value: (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenSensors}
                    startIcon={<SensorsIcon />}
                    sx={{ mr: 1 }}
                  >
                    Sensors
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenRoutes}
                    startIcon={<RouteIcon />}
                  >
                    Custom Routes
                  </Button>
                </Box>
              ),
              fullWidth: true
            }
          ])}

        <Divider sx={{ my: 1 }} />

        {sensorBoxData &&
          renderSection('Status Information', [
            { label: 'Modem', value: sensorBoxData.modem_sensorbox },
            { label: 'Graphana', value: sensorBoxData.graphana },
            { label: 'Rsync', value: sensorBoxData.rsync },
            { label: 'VPN Check', value: sensorBoxData.vpn_check },
            { label: 'OSE ESA', value: sensorBoxData.ose_esa },
            { label: 'Auto JAS', value: sensorBoxData.auto_jas },
            { label: 'TRM Patch', value: sensorBoxData.trm_patch },
            { label: 'Root', value: sensorBoxData.root },
            { label: 'SVLOG', value: sensorBoxData.svlog },
            { label: 'Cron', value: sensorBoxData.cron },
            { label: 'Network', value: sensorBoxData.network },
            { label: 'HW Clock', value: sensorBoxData.hwclock },
            { label: 'Inittab', value: sensorBoxData.inittab },
            { label: 'Routes', value: sensorBoxData.routes },
            { label: 'VPNS', value: sensorBoxData.vpns, fullWidth: true }
          ])}

        <Divider sx={{ my: 1 }} />

        {sensorBoxData &&
          renderSection('Version Information', [
            { label: 'Version', value: sensorBoxData.version },
            { label: 'Open WRT', value: sensorBoxData.open_wrt },
            { label: 'Open VPN', value: sensorBoxData.open_vpn }
          ])}

        <Divider sx={{ my: 1 }} />

        {sensorBoxData &&
          renderSection('LED Information', [
            { label: 'MAC Table', value: sensorBoxData.mac_table, fullWidth: true },
            { label: 'BIN', value: sensorBoxData.bin },
            { label: 'Data', value: sensorBoxData.data },
            { label: 'Table Type', value: sensorBoxData.table_type },
            { label: 'Brightness Type', value: sensorBoxData.brightness_type, fullWidth: true },
            { label: 'Time Stamp', value: sensorBoxData.time_stamp_sensorbox, fullWidth: true }
          ])}

        <Dialog open={openSensors} onClose={handleCloseSensors} maxWidth="sm" fullWidth>
          <DialogTitle>Sensors</DialogTitle>
          <DialogContent>
            {sensorBoxData &&
              Object.keys(sensorBoxData.number_sensor_all).map((key) => (
                <Box key={key} display="flex" alignItems="flex-start" mb={1}>
                  <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
                    {key}:
                  </Typography>
                  <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                    {sensorBoxData.number_sensor_all[key]}
                  </Typography>
                </Box>
              ))}
          </DialogContent>
        </Dialog>

        <Dialog open={openRoutes} onClose={handleCloseRoutes} maxWidth="sm" fullWidth>
          <DialogTitle>Custom Routes</DialogTitle>
          <DialogContent>
            {sensorBoxData && (
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                {sensorBoxData.customroutes.split(' ').map((route: string, index: number) => (
                  <Typography key={index} variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                    {route}
                  </Typography>
                ))}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default SensorBoxDetails;
