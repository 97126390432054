import React from 'react';
import { Grid } from '@mui/material';
import { CustomTextField, CustomSelect, CustomSwitch } from './FormElements';

const CustomGridItem = ({ type, label, value, onChange, options, required = false, checked, xs = 6, multiline = false, minRows, sx }) => {
  return (
    <Grid item xs={xs}>
      {type === 'text' && (
        <CustomTextField
          label={label}
          value={value}
          onChange={onChange}
          required={required}
          multiline={multiline}
          minRows={minRows}
          sx={sx}
        />
      )}
      {type === 'select' && (
        <CustomSelect
          label={label}
          value={value}
          onChange={onChange}
          options={options || []}
          required={required}
        />
      )}
      {type === 'switch' && (
        <CustomSwitch
          label={label}
          checked={checked}
          onChange={onChange}
        />
      )}
    </Grid>
  );
};

export default CustomGridItem;
