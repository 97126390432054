import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BuildIcon from '@mui/icons-material/Build';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import axiosInstance, { publicInstance } from '../axiosInstance';
import { useRefresh } from '../../Components/RefreshContext'; 
import { useAtom } from 'jotai';
import { serviceCommentsCountAtom } from '../../helpers/atoms';

interface ServiceComment {
  comment: string;
  username: string;
  id: number;
  time_stamp: string;
  box: number;
  status: string;
}

interface ServiceCommentsProps {
  deviceNumber: string;
}

const ServiceComments: React.FC<ServiceCommentsProps> = ({ deviceNumber }) => {
  const [comments, setComments] = useState<ServiceComment[]>([]);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const { refresh } = useRefresh(); 
  const [, setServiceCommentsCount] = useAtom(serviceCommentsCountAtom);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await publicInstance.get(`/dacapi_sensorbox_service_comments?box=eq.${deviceNumber}`);
        const sortedComments = response.data.sort((a: ServiceComment, b: ServiceComment) => new Date(b.time_stamp).getTime() - new Date(a.time_stamp).getTime());
        setComments(sortedComments);
      } catch (error) {
        console.error('Error fetching service comments:', error);
      }
    };

    fetchComments();
  }, [deviceNumber]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleReportDialogOpen = () => {
    setReportDialogOpen(true);
  };

  const handleReportDialogClose = () => {
    setReportDialogOpen(false);
    setIssueDescription('');
  };

  const handleSubmit = async () => {
    try {
      const userResponse = await axiosInstance.get('/auth/me');
      const username = userResponse.data.username;
      
      await axiosInstance.post('/form/report_service/', {
        box: deviceNumber,
        comment: newComment,
      });
      const newServiceComment = { 
        comment: newComment, 
        username: username, 
        id: Date.now(), 
        time_stamp: new Date().toISOString(), 
        box: parseInt(deviceNumber, 10), 
        status: 'naprawione' 
      };
      setComments([newServiceComment, ...comments]);
      setNewComment('');
      setDialogOpen(false);
      refresh(); 

      
      const response = await publicInstance.get('/view_dacapi_sensorbox_service_comments');
      const commentsCount = response.data.length;
      setServiceCommentsCount(commentsCount); 
    } catch (error) {
      console.error('Error submitting new service comment:', error);
    }
  };

  const handleReportIssue = async () => {
    try {
      const userResponse = await axiosInstance.get('/auth/me');
      const username = userResponse.data.username;
      
      await axiosInstance.post('/form/report_issue/', {
        box: deviceNumber,
        comment: issueDescription,
      });

      const newIssueComment = { 
        comment: issueDescription, 
        username: username, 
        id: Date.now(), 
        time_stamp: new Date().toISOString(), 
        box: parseInt(deviceNumber, 10), 
        status: 'awaria' 
      };
      setComments([newIssueComment, ...comments]);
      setSnackbarMessage('Issue reported successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setServiceCommentsCount(prevCount => prevCount + 1); 
      refresh(); 
    } catch (error) {
      console.error('Error reporting issue:', error);
      setSnackbarMessage('Error reporting issue');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      handleReportDialogClose();
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const latestComment = comments[0];

  return (
    <Card sx={{ margin: "auto", mt: 2 }}>
      <CardHeader
        title="Service Comments"
        action={
          <Box display="flex" alignItems="center">
            <Tooltip title={open ? "Hide Details" : "Show Details"}>
              <IconButton onClick={handleToggle}>
                <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Report Repair">
              <IconButton onClick={handleDialogOpen}>
                <BuildIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Report Issue">
              <IconButton onClick={handleReportDialogOpen}>
                <ReportProblemIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <CardContent>
        {!open && latestComment && (
          <Box>
            <Typography variant="body1" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}><strong>Comment:</strong> {latestComment.comment}</Typography>
            <Typography variant="body2"><strong>User:</strong> {latestComment.username}</Typography>
            <Typography variant="body2"><strong>Time:</strong> {new Date(latestComment.time_stamp).toLocaleString()}</Typography>
            <Typography variant="body2"><strong>Status:</strong> <span style={{ color: latestComment.status === 'awaria' ? 'red' : 'green' }}>{latestComment.status}</span></Typography>
          </Box>
        )}
        
        <Collapse in={open}>
          <Box sx={{ maxHeight: 150, overflow: 'auto', mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px' }}>Comment</TableCell>
                  <TableCell sx={{ padding: '4px' }}>User</TableCell>
                  <TableCell sx={{ padding: '4px' }}>Time</TableCell>
                  <TableCell sx={{ padding: '4px' }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comments.map(comment => (
                  <TableRow key={comment.id}>
                    <TableCell sx={{ padding: '4px', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>{comment.comment}</TableCell>
                    <TableCell sx={{ padding: '4px' }}>{comment.username}</TableCell>
                    <TableCell sx={{ padding: '4px' }}>{new Date(comment.time_stamp).toLocaleString()}</TableCell>
                    <TableCell sx={{ padding: '4px' }}>
                      <Typography color={comment.status === 'awaria' ? 'error' : 'green'}>
                        {comment.status}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Report Repair</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your repair comment below:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Comment"
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              multiline
              minRows={3}
            />
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button size="small" onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={reportDialogOpen} onClose={handleReportDialogClose}>
          <DialogTitle>Report Issue</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Describe the issue with the device.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Issue Description"
              fullWidth
              variant="outlined"
              value={issueDescription}
              onChange={(e) => setIssueDescription(e.target.value)}
              multiline
              minRows={3}
            />
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={handleReportDialogClose} color="primary">
              Cancel
            </Button>
            <Button size="small" onClick={handleReportIssue} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};

export default ServiceComments;
