import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  Box,
  Container,
  Paper,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import { styled } from '@mui/material/styles';

const API_URL = '/form/forms/sensorbox';
const ADD_URL = '/form/forms_add/sensorbox/';
const REMOVE_URL = '/form/forms_uniq/sensorbox/';

const predefinedOrder = ["Status", "Device Type", "Location", "Version of DZ", "Power Supply Type", "Measurement Track Type", "Sensors", "Antennas"];

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({


}));

const MenuItemsManager = () => {
  const [data, setData] = useState({});
  const [newItems, setNewItems] = useState({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({ category: '', item: '' });

  useEffect(() => {
    axiosInstance.get(API_URL)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddItem = async (category: string) => {
    const newItem = newItems[category];
    if (!newItem) return;

    try {
      await axiosInstance.put(`${ADD_URL}${category}/add_values`, {
        values: [newItem]
      });
      setData((prevData) => ({
        ...prevData,
        [category]: [...prevData[category], newItem]
      }));
      setNewItems((prevItems) => ({ ...prevItems, [category]: '' }));
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleRemoveItem = async () => {
    const { category, item } = itemToDelete;
    try {
      await axiosInstance.put(`${REMOVE_URL}${category}/remove_values`, {
        values: [item]
      });
      setData((prevData) => ({
        ...prevData,
        [category]: prevData[category].filter(i => i !== item)
      }));
      setOpenDialog(false);
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  const handleDialogOpen = (category: string, item: string) => {
    setItemToDelete({ category, item });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (category: string, value: string) => {
    setNewItems((prevItems) => ({
      ...prevItems,
      [category]: value
    }));
  };

  return (
    <Box sx={{ backgroundColor: 'backgraound.paper', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Button variant="contained" component={Link} to="/" sx={{ mb: 3 }}>
            Back
          </Button>
        </Box>
        <Alert severity="warning" sx={{ mb: 4 }}>
          <AlertTitle color='black'>Uwaga</AlertTitle>
          Usunięcie itemów, które były już użyte przy dodaniu bądź updatecie jakiegoś boxa, skutkuje błędnym wczytaniem takowego boxa w formularzu do updatu. Proszę usuwać tylko itemy, które nie zostały nigdzie wykorzystane!
        </Alert>
        {predefinedOrder.filter(category => data[category]).map((category) => (
          <Accordion expanded={expanded === category} onChange={handleChange(category)} key={category} sx={{ marginBottom: 2 }}>
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{category}</Typography>
            </CustomAccordionSummary>
            <AccordionDetails >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Add New {category} Item</Typography>
                  <Box display="flex" alignItems="center">
                    <TextField
                      label={`New ${category} Item`}
                      value={newItems[category] || ''}
                      onChange={(e) => handleInputChange(category, e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={() => handleAddItem(category)} sx={{ ml: 2 }}>
                      Add
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Existing {category} Items</Typography>
                  <Box display="flex" flexWrap="wrap">
                    {data[category].map((item: string, index: number) => (
                      <Paper key={`${item}-${index}`} elevation={1} sx={{ display: 'flex', alignItems: 'center', m: 1, p: 1 }}>
                        <ListItemText primary={item} />
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDialogOpen(category, item)}>
                          <DeleteIcon />
                        </IconButton>
                      </Paper>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle>Potwierdź usunięcie</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Czy na pewno chcesz usunąć ten element? Usunięcie itemów, które były już użyte przy dodaniu bądź updatecie jakiegoś boxa, skutkuje błędnym wczytaniem takowego boxa w formularzu do updatu.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Anuluj
            </Button>
            <Button onClick={handleRemoveItem} color="primary" autoFocus>
              Usuń
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default MenuItemsManager;
