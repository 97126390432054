import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from "@mui/material";

const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', paddingLeft: '16px', fontSize: '1.2rem' }}>
      {params.value}
    </MuiLink>
  );
};

const renderSensors = (params: GridRenderCellParams) => {
  const sensors = params.row.sensors || {};
  if (typeof sensors === 'object' && sensors !== null) {
    return JSON.stringify(sensors);
  }
  return sensors;
};

const renderNumberOfSensors = (params: GridRenderCellParams) => {
  const numberOfSensors = params.row.number_of_sensors || {};
  if (typeof numberOfSensors === 'object' && numberOfSensors !== null) {
    return JSON.stringify(numberOfSensors);
  }
  return numberOfSensors;
};

const renderNumberOfChannels = (params: GridRenderCellParams) => {
  const numberOfChannels = params.row.number_of_channels || {};
  if (typeof numberOfChannels === 'object' && numberOfChannels !== null) {
    return JSON.stringify(numberOfChannels);
  }
  return numberOfChannels;
};

const renderAntennas = (params: GridRenderCellParams) => {
  const antennas = params.row.antennas || {};
  if (typeof antennas === 'object' && antennas !== null) {
    return JSON.stringify(antennas);
  }
  return antennas;
};

const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', width: 100, renderCell: renderBoxLink },
  { field: 'status', headerName: 'Status', width: 100 },
  { field: 'device_type', headerName: 'Device Type', width: 120 },
  { field: 'location', headerName: 'Location', width: 120 },
  { field: 'version_of_dz', headerName: 'Version of DZ', width: 120 },
  { field: 'number_of_dz', headerName: 'Number of DZ', width: 120 },
  { field: 'pull_up', headerName: 'Pull Up', width: 100 },
  { field: 'power_supply_type', headerName: 'Power Supply Type', width: 150 },
  { field: 'measurement_track_type', headerName: 'Measurement Track Type', width: 180 },
  { field: 'rtc_battery', headerName: 'RTC Battery', width: 120 },
  { field: 'voltage_filter', headerName: 'Voltage Filter', width: 120 },
  { field: 'sensors', headerName: 'Sensors', width: 300, renderCell: renderSensors },
  { field: 'number_of_sensors', headerName: 'Number of Sensors', width: 300, renderCell: renderNumberOfSensors },
  { field: 'number_of_channels', headerName: 'Number of Channels', width: 300, renderCell: renderNumberOfChannels },
  { field: 'antennas', headerName: 'Antennas', width: 300, renderCell: renderAntennas },
  { field: 'time_stamp', headerName: 'Time Stamp', width: 220 },
  { field: 'comments', headerName: 'Comments', width: 200 },
];

interface Table1Props {
  search: string;
}

const Table1: React.FC<Table1Props> = ({ search }) => {
  return <ReusableTable fetchUrl="/dacapi_sensorbox_introduction_form" columns={columns} search={search} />;
};

export default Table1;
