import React, { useEffect, useState, useRef } from 'react';
import { Box, Container, useMediaQuery, useTheme, Tabs, Tab, TextField, Button } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import axiosInstance, { publicInstance } from '../axiosInstance';
import ControlPanel from './ControlPanel';
import MapContainer from './MapContainer';
import DetailsPanel from './DetailsPanel';
import LegendPopover from './LegendPopover';
import { Data, MeasurementData, determineBoxColor } from './utils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ResetSearchIcon from '@mui/icons-material/ClearAll';


const MapPanel: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState<Data[]>([]);
  const [unknownGps, setUnknownGps] = useState<Array<Data>>([]);
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [selectedContract, setSelectedContract] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [searchBox, setSearchBox] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [initialMarkerStats, setInitialMarkerStats] = useState({ green: 0, yellow: 0, red: 0, unknown: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [measurementData, setMeasurementData] = useState<MeasurementData[]>([]);
  const [timeRange, setTimeRange] = useState<string>('1d');
  const [customTimeRange, setCustomTimeRange] = useState<string>('');
  const [customTimeUnit, setCustomTimeUnit] = useState<string>('hours');
  const [loadingMeasurements, setLoadingMeasurements] = useState<boolean>(false);
  const [selectedBts, setSelectedBts] = useState<any>(null); 
   

  const [tabIndex, setTabIndex] = useState(0);
  const [rspo, setRspo] = useState('');
  const [installationId, setInstallationId] = useState('');
  const [btsData, setBtsData] = useState<any[]>([]);
  const [installationLocation, setInstallationLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [searchGps, setSearchGps] = useState<string>('');
  const [searchedLocation, setSearchedLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [initialZoom, setInitialZoom] = useState(6.8);

  

  useEffect(() => {
    if (tabIndex === 0) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await publicInstance.get('/view_dacapi_map_panel_data');
          const allData = response.data;
          const filteredData = allData.filter((data: Data) => data.gps.toLowerCase() !== 'unknown');
          setData(filteredData);

          const unknownGps = allData.filter((data: Data) => data.gps.toLowerCase() === 'unknown');
          setUnknownGps(unknownGps);

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (data.length > 0) {
      updateMarkerStats();
    }
  }, [data, selectedContract]);

  

  const updateMarkerStats = () => {
    const filteredData = data
      .filter(d => {
        if (selectedContract) {
          return d.contracts && d.contracts.includes(selectedContract);
        }
        return true;
      });

    const boxColors = filteredData.map(d => determineBoxColor(d.pm10, d.pm25));

    const markerStats = {
      green: boxColors.filter(color => color === 'green').length,
      yellow: boxColors.filter(color => color === 'yellow').length,
      red: boxColors.filter(color => color === 'red').length,
      unknown: boxColors.filter(color => color === 'unknown').length
    };

    setInitialMarkerStats(markerStats);
  };

  const handleMarkerClick = (box: number) => {
    setSelectedBox(box);
    const selectedDevice = data.find((d) => d.box === box);
    if (selectedDevice) {
      fetchMeasurementData(box, '1d');
    } else {
      setMeasurementData([]);
    }
  };

  const handleBtsClick = (btsGroup: any[]) => {
    setSelectedBts(btsGroup); 
  };


  const handleRspoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRspo(value);
    setInstallationId(''); 
  };

  const handleInstallationIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInstallationId(value);
    setRspo(''); 
  };

  const fetchMeasurementData = async (box: number, range: string) => {
    setLoadingMeasurements(true);
    let timeUnit = '';
    let timeValue = '';
    if (range === 'custom') {
      timeUnit = customTimeUnit;
      timeValue = customTimeRange;
    } else {
      [timeUnit, timeValue] = range.includes('d') ? ['days', range.replace('d', '')] : ['hours', range.replace('h', '')];
    }
    try {
      const response = await axiosInstance.get(`/form/measurements/${box}?${timeUnit}=${timeValue}`);
      let measurements = response.data.reverse();

      if (measurements.length > 700) {
        let buffer = [];
        for (let i = 0; i < measurements.length; i++) {
          if (i % Math.round(measurements.length / 700) === 0) {
            buffer.push(measurements[i]);
          }
        }
        measurements = buffer;
      }

      const processedData = measurements.map((measurement: MeasurementData) => ({
        ...measurement,
        pm10: measurement.pm10 === 'UNKNOWN' ? null : parseFloat(measurement.pm10),
        pm25: measurement.pm25 === 'UNKNOWN' ? null : parseFloat(measurement.pm25)
      }));

      setMeasurementData(processedData);
    } catch (error) {
      console.error('Error fetching measurement data:', error);
      setMeasurementData([]);
    } finally {
      setLoadingMeasurements(false);
    }
  };

  const handleContractChange = (event: SelectChangeEvent<string>) => {
    setSelectedContract(event.target.value);
  };

  const handleResetContracts = () => {
    setSelectedContract('');
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category === selectedCategory ? '' : category);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBox(event.target.value);
  };

  const handleLegendClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLegendClose = () => {
    setAnchorEl(null);
  };

  const handleTimeRangeChange = (event: SelectChangeEvent<string>) => {
    setTimeRange(event.target.value);
    if (event.target.value !== 'custom') {
      setCustomTimeRange('');
      if (selectedBox) {
        fetchMeasurementData(selectedBox, event.target.value);
      }
    }
  };

  const handleCustomTimeRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTimeRange(event.target.value);
  };

  const handleCustomTimeUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTimeUnit(event.target.checked ? 'days' : 'hours');
  };

  const handleCustomTimeSubmit = () => {
    if (selectedBox) {
      fetchMeasurementData(selectedBox, 'custom');
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const params: Record<string, string> = {
        order: 'distance.asc',
        limit: '10',
      };

      if (rspo) {
        params['rspo'] = `eq.${rspo}`;
      } else if (installationId) {
        params['id_instalacji'] = `eq.${installationId}`;
      }

      const response = await publicInstance.get('/view_dacapi_bts_search_near_installations', {
        params,
      });

      const data = response.data;
      setBtsData(data);

      if (data.length > 0) {
        const installationLoc = data[0].installation_location.split(', ').map(Number);
        setInstallationLocation({ lat: installationLoc[0], lng: installationLoc[1] });
      }

     
      setRspo('');
      setInstallationId('');
      setSelectedBts(null);
      setMeasurementData([]); 
      setSelectedBox(null);
      setInitialMarkerStats({ green: 0, yellow: 0, red: 0, unknown: 0 }); 

    } catch (error) {
      console.error('Error fetching BTS data:', error);
    } finally {
      setLoading(false);
    }
  };

  const networkColor = (network: string) => {
    switch (network) {
      case 'Orange':
        return 'orange';
      case 'Plus':
        return '#66bb6a'; 
      case 'Play':
        return '#ba68c8'; 
      default:
        return 'black';
    }
  };

  const parseGpsInput = (input: string): { lat: number, lng: number } | null => {
    // Try parsing "lat, lng" format
    const simpleFormat = input.split(',').map(coord => parseFloat(coord.trim()));
    if (simpleFormat.length === 2 && !isNaN(simpleFormat[0]) && !isNaN(simpleFormat[1])) {
      return { lat: simpleFormat[0], lng: simpleFormat[1] };
    }

    // Try parsing "N: xx.xxxxxxxx, E: xx.xxxxxxxx" format
    const complexFormat = input.match(/N:\s*(-?\d+(\.\d+)?),\s*E:\s*(-?\d+(\.\d+)?)/i);
    if (complexFormat) {
      const lat = parseFloat(complexFormat[1]);
      const lng = parseFloat(complexFormat[3]);
      if (!isNaN(lat) && !isNaN(lng)) {
        return { lat, lng };
      }
    }

    return null;
  };

  const handleSearchGpsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchGps(event.target.value);
  };

  const handleSearchGps = async () => {
    if (/^\d+$/.test(searchGps)) {
      // If input is only digits, treat as RSPO
      try {
        const response = await publicInstance.get(`/view_dacapi_bts_search_near_installations?order=distance.asc&limit=1&rspo=eq.${searchGps}`);
        if (response.data && response.data.length > 0) {
          const { nask_latitude, nask_longitude } = response.data[0];
          setSearchedLocation({ lat: nask_latitude, lng: nask_longitude });
        } else {
          alert('No location found for this RSPO number.');
        }
      } catch (error) {
        console.error('Error fetching RSPO location:', error);
        alert('Error fetching location for RSPO number.');
      }
    } else {
      // Existing GPS parsing logic
      const parsedLocation = parseGpsInput(searchGps);
      if (parsedLocation) {
        setSearchedLocation(parsedLocation);
      } else {
        alert('Invalid GPS coordinates or RSPO number. Please use format: latitude, longitude\nExample: 52.2297, 21.0122\nOr: N: 52.2297, E: 21.0122\nOr enter RSPO number');
      }
    }
  };

  const handleResetSearch = () => {
    setSearchedLocation(null);
    setSearchGps('');
  };

  const selectedDevice = data.find((d) => d.box === selectedBox);
  const filteredData = data
    .filter(d => {
      if (selectedContract) {
        return d.contracts && d.contracts.includes(selectedContract);
      }
      return true;
    })
    .filter(d => {
      if (selectedCategory) {
        const boxColor = determineBoxColor(d.pm10, d.pm25);
        return selectedCategory === boxColor;
      }
      return true;
    })
    .filter(d => {
      if (searchBox) {
        return d.box.toString().includes(searchBox);
      }
      return true;
    });

    return (
      <Box sx={{ backgroundColor: 'background.default', color: "text.primary", minHeight: '100vh', position: 'relative'}}>
        <Container maxWidth={false} sx={{ maxWidth: '100%', height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column' }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              mb: 2,
              mt: isMobile ? 3 : 0 
            }}
          >
            <Tab label="Map View" />
            <Tab label="BTS Search" />
          </Tabs>
  
          {tabIndex === 0 && (
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flex: '1' }}>
              <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <ControlPanel
                  isMobile={isMobile}
                  data={data}
                  searchBox={searchBox}
                  selectedContract={selectedContract}
                  selectedCategory={selectedCategory}
                  initialMarkerStats={initialMarkerStats}
                  loading={loading}
                  unknownGps={unknownGps}
                  handleSearchChange={handleSearchChange}
                  handleContractChange={handleContractChange}
                  handleResetContracts={handleResetContracts}
                  handleCategoryClick={handleCategoryClick}
                  handleLegendClick={handleLegendClick}
                  searchGps={searchGps}
                  handleSearchGpsChange={handleSearchGpsChange}
                  handleSearchGps={handleSearchGps}
                  handleResetSearch={handleResetSearch}
                  setSearchedLocation={setSearchedLocation}
                />
                <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                  <Box sx={{ flex: isMobile ? '1 1 auto' : '0 1 65%', height: isMobile ? '70vh' : '80vh' }}>
                    <MapContainer
                      data={data}
                      filteredData={filteredData}
                      handleMarkerClick={handleMarkerClick}
                      searchedLocation={searchedLocation}
                      initialZoom={initialZoom}
                    />
                  </Box>
                  <Box sx={{ flex: isMobile ? '1 1 auto' : '0 1 35%', height: isMobile ? '60%' : '100%' }}>
                    <DetailsPanel
                      isMobile={isMobile}
                      selectedDevice={selectedDevice}
                      measurementData={measurementData}
                      loadingMeasurements={loadingMeasurements}
                      timeRange={timeRange}
                      customTimeRange={customTimeRange}
                      customTimeUnit={customTimeUnit}
                      handleTimeRangeChange={handleTimeRangeChange}
                      handleCustomTimeRangeChange={handleCustomTimeRangeChange}
                      handleCustomTimeUnitChange={handleCustomTimeUnitChange}
                      handleCustomTimeSubmit={handleCustomTimeSubmit}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
  
          {tabIndex === 1 && (
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flex: '1' }}>
              <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', mb: 2 }}>
                  <TextField
                    label="RSPO"
                    variant="outlined"
                    value={rspo}
                    onChange={handleRspoChange}
                    sx={{
                      mr: isMobile ? 0 : 2,
                      mb: isMobile ? 2 : 0,
                      visibility: installationId ? 'hidden' : 'visible',
                    }}
                  />
                  <TextField
                    label="Installation ID"
                    variant="outlined"
                    value={installationId}
                    onChange={handleInstallationIdChange}
                    sx={{
                      mr: isMobile ? 0 : 2,
                      mb: isMobile ? 2 : 0,
                      visibility: rspo ? 'hidden' : 'visible',
                    }}
                  />
                  <Button variant="contained" onClick={handleSearch} disabled={loading} sx={{ width: isMobile ? '100%' : 'auto' }}>
                    {loading ? 'Loading...' : 'Search'}
                  </Button>
                </Box>
  
                <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                  <Box sx={{ flex: isMobile ? '1 1 auto' : '0 1 65%', height: isMobile ? '70vh' : '80vh' }}>
                    <MapContainer
                      btsData={btsData}
                      installationLocation={installationLocation}
                      handleBtsClick={handleBtsClick}
                      initialZoom={initialZoom}
                    />
                  </Box>
  
                  <Box sx={{ flex: isMobile ? '1 1 auto' : '0 1 35%', height: isMobile ? '60%' : '100%', mt: isMobile ? 2 : 0 }}>
                    <DetailsPanel
                      isMobile={isMobile}
                      selectedBts={selectedBts}
                    />
                  </Box>
                </Box>
  
                
                <Box sx={{
                  mt: 2,
                  maxWidth: isMobile ? '47vh' : '65%',
                  alignSelf: 'flex-start',
                  mb: 10,
                  overflowX: 'auto' 
                }}>
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ backgroundColor: 'background.paper' }}>Station ID</TableCell>
                          <TableCell sx={{ backgroundColor: 'background.paper' }}>Network</TableCell>
                          <TableCell sx={{ backgroundColor: 'background.paper' }}>Distance (m)</TableCell>
                          <TableCell sx={{ backgroundColor: 'background.paper', }}>Location</TableCell>
                          <TableCell sx={{ backgroundColor: 'background.paper' }}>BTS Location</TableCell>
                          <TableCell sx={{ backgroundColor: 'background.paper' }}>Standard</TableCell>
                          <TableCell sx={{ backgroundColor: 'background.paper' }}>Bands</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {btsData
                          .sort((a, b) => a.distance - b.distance)
                          .map((bts) => (
                            <TableRow key={bts.station_id}> 
                              <TableCell component="th" scope="row">
                                {bts.station_id}
                              </TableCell>
                              <TableCell sx={{ color: networkColor(bts.siec_id), }}>
                                {bts.siec_id}
                              </TableCell>
                              <TableCell>{bts.distance.toFixed(2)}</TableCell>
                              <TableCell >{bts.lokalizacja}</TableCell>
                              <TableCell>{bts.bts_location}</TableCell>
                              <TableCell >{bts.standard}</TableCell>
                              <TableCell >{Array.from(new Set(bts.pasma)).join(', ')}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
  
              </Box>
            </Box>
          )}
  
        </Container>
        <LegendPopover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleLegendClose={handleLegendClose}
        />
      </Box>
    );
  };
  
  export default MapPanel;
