import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import axiosInstance, {publicInstance} from '../axiosInstance';

interface AdminUserContextType {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  roles: string[];
  setRoles: React.Dispatch<React.SetStateAction<string[]>>;
  fetchRoles: () => Promise<void>;
  fetchUsers: () => Promise<void>;
}

export interface User {
  user_id: string;
  username: string;
  email: string;
  password: string;
  role: string;
  isactive: boolean;
  profile_image_path?: string;
}

const AdminUserContext = createContext<AdminUserContextType | undefined>(undefined);

export const AdminUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<string[]>([]);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await publicInstance.get('/roles');
      const roles = response.data.map(role => role.role_name); // emulacja jq -c '[.[].role_name]'
      setRoles(roles);
      console.log('Roles:', roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, []);
  

  const fetchUsers = useCallback(async () => {
    try {
      const response = await publicInstance.get('/users?select=user_id,username,email,is_active,profile_image_path,last_login,userroles(roles(role_name))');
  
      const users = response.data.map(user => ({
        ...user,
        role: user.userroles.length > 0 ? user.userroles[0].roles.role_name : null
      }));
  
      setUsers(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);
  

  useEffect(() => {
    fetchRoles();
    fetchUsers();
  }, [fetchRoles, fetchUsers]);

  return (
    <AdminUserContext.Provider value={{ users, setUsers, roles, setRoles, fetchRoles, fetchUsers }}>
      {children}
    </AdminUserContext.Provider>
  );
};

export const useAdminUsers = (): AdminUserContextType => {
  const context = useContext(AdminUserContext);
  if (!context) {
    throw new Error('useAdminUsers must be used within an AdminUserProvider');
  }
  return context;
};
