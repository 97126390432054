import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link as MuiLink } from "@mui/material";
import { Link } from 'react-router-dom';
const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', paddingLeft: '16px', fontSize: '1.2rem' }}>
      {params.value}
    </MuiLink>
  );
};

const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', flex:1, renderCell: renderBoxLink },
  { field: 'modem', headerName: 'Modem', flex:1 },
  { field: 'gps', headerName: 'GPS', flex:1 },
  { field: 'scripts_comments', headerName: 'Scripts Comments', flex:1 },
  { field: 'time_stamp', headerName: 'Time Stamp', flex:1 },
];

interface Table4Props {
  search: string;
}

const Table4: React.FC<Table4Props> = ({ search }) => {
  return <ReusableTable fetchUrl="/dacapi_gps_current" columns={columns} search={search} />;
};

export default Table4;
