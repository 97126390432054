import React from 'react'
import { Link } from 'react-router-dom'

function NotFoundPage() {
    return (
        <div className='wrapper'>
            <h1>Not found</h1>
            <Link to='/' style={{
                width: '100%',
                height: '45px',
                background: '#fff',
                border: 'none',
                outline: 'none',
                borderRadius: '40px',
                color: '#333',
                fontSize: '16px',
                cursor: 'pointer',
                fontWeight: '700',
                boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>Go back to the main page</Link>
        </div>
      )
}

export default NotFoundPage
