import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function BoxNumberSearch() {
    const [boxNumber, setBoxNumber] = useState('');
    const navigate = useNavigate();
    const { boxNumber: currentBoxNumber } = useParams();

    const handleInputChange = (event) => {
        setBoxNumber(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (boxNumber) {
            if (boxNumber === currentBoxNumber) {
               
                navigate(0); 
            } else {
                navigate(`/installation/${boxNumber}`);
            }
        }
    };

    return (
        <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{ mt: 2, width: '300px', display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}
        >
            <TextField 
                label="Box Number" 
                variant="outlined" 
                value={boxNumber} 
                onChange={handleInputChange} 
                required 
                fullWidth 
            />
            <Button type="submit" variant="contained" color="primary">
                Go to Installation
            </Button>
        </Box>
    );
}

export default BoxNumberSearch;
