import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, useMediaQuery, Button } from '@mui/material';
import UserTable from './UserTable';
import UserForm from './UserForm';
import UserRoles from './UserRoles';
import AdminRoles from './AdminRoles';
import UserProfileImage from './UserProfileImage';
import LogsAccordion from './LogsAccordion';
import AdminPopup from './AdminPopup'; // Nowy komponent, który trzeba stworzyć

import { AdminUserProvider } from './AdminUserContext';

const AdminPanel: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [expanded, setExpanded] = useState<string | false>(false);
  const [role, setRole] = useState<string | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem('role');
    setRole(userRole);
  }, []);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4, overflowX: 'hidden' }}>
      <AdminUserProvider>
        <Container maxWidth={isMobile ? 'xs' : 'xl'}>
          <Paper sx={{ p: isMobile ? 2 : 4, position: 'relative' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h4">
                Admin Panel
              </Typography>
              {role === 'admin' && (
                <Button variant="contained" onClick={handleOpenPopup}>
                  Open Scripts Popup
                </Button>
              )}
            </Box>
            {role === 'admin' ? (
              <>
                <UserTable />
                <Box
                  mt={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <AdminRoles expanded={expanded} handleAccordionChange={handleAccordionChange} />
                  <UserProfileImage expanded={expanded} handleAccordionChange={handleAccordionChange} />
                  <UserForm expanded={expanded} handleAccordionChange={handleAccordionChange} />
                  <UserRoles expanded={expanded} handleAccordionChange={handleAccordionChange} />
                  <LogsAccordion expanded={expanded} handleAccordionChange={handleAccordionChange} />
                 
                  
                </Box>
              </>
            ) : (
              <LogsAccordion expanded={expanded} handleAccordionChange={handleAccordionChange} />
            )}
          </Paper>
        </Container>
        {isPopupOpen && <AdminPopup open={isPopupOpen} onClose={handleClosePopup} />}
      </AdminUserProvider>
    </Box>
  );
};

export default AdminPanel;
