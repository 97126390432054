import React from 'react';
import { Box, Typography, Card, CardContent, useTheme, useMediaQuery, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { publicInstance } from '../axiosInstance';
import { alpha } from '@mui/material/styles';

const renderBlocks = (count: number, color: string, isP5 = false, isP8 = false) => {
  const blockStyle = {
    width: 20,
    height: 20,
    backgroundColor: color,
    margin: '1px',
    position: 'relative' as 'relative',
  };

  const whiteDotStyle = {
    width: '4px',
    height: '4px',
    backgroundColor: 'white',
    borderRadius: '50%',
    position: 'absolute' as 'absolute',
  };

  const getDots = (isP5: boolean, isP8: boolean) => {
    if (isP5) {
      return (
        <>
          <Box sx={{ ...whiteDotStyle, top: '2px', left: '2px' }} />
          <Box sx={{ ...whiteDotStyle, top: '2px', left: '8px' }} />
          <Box sx={{ ...whiteDotStyle, top: '2px', left: '14px' }} />
          <Box sx={{ ...whiteDotStyle, top: '8px', left: '2px' }} />
          <Box sx={{ ...whiteDotStyle, top: '8px', left: '8px' }} />
          <Box sx={{ ...whiteDotStyle, top: '8px', left: '14px' }} />
          <Box sx={{ ...whiteDotStyle, top: '14px', left: '2px' }} />
          <Box sx={{ ...whiteDotStyle, top: '14px', left: '8px' }} />
          <Box sx={{ ...whiteDotStyle, top: '14px', left: '14px' }} />
        </>
      );
    }
    if (isP8) {
      return (
        <>
          <Box sx={{ ...whiteDotStyle, top: '4px', left: '4px' }} />
          <Box sx={{ ...whiteDotStyle, top: '4px', left: '12px' }} />
          <Box sx={{ ...whiteDotStyle, top: '12px', left: '4px' }} />
          <Box sx={{ ...whiteDotStyle, top: '12px', left: '12px' }} />
        </>
      );
    }
    return null;
  };

  const blocks = Array.from({ length: count }).map((_, index) => (
    <Box key={index} sx={blockStyle}>
      {isP5 && getDots(true, false)}
      {isP8 && getDots(false, true)}
    </Box>
  ));

  return <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '20vh' }}>{blocks}</Box>;
};

type Shipment = {
  id: number;
  group_id: number;
  recipient: string;
  planned_shipping_date: string;
  comments: string;
  status: string;
  device_type: string;
  quantity: number;
  details: any[];
};

type GroupedShipments = {
  [key: number]: Shipment[];
};

type CurrentShipmentsProps = {
  shipments: Shipment[];
  onUpdateCurrentShipments: (groupId: number) => void;
  showSnackbar: (message: string, severity: 'success' | 'error' | 'warning' | 'info') => void;
};

const CurrentShipments: React.FC<CurrentShipmentsProps> = ({ shipments, onUpdateCurrentShipments, showSnackbar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const isDarkTheme = theme.palette.mode === 'dark';

  // Grouping shipments by group_id
  const groupedShipments: GroupedShipments = shipments.reduce((acc: GroupedShipments, shipment: Shipment) => {
    if (!acc[shipment.group_id]) {
      acc[shipment.group_id] = [];
    }
    acc[shipment.group_id].push(shipment);
    return acc;
  }, {});

  const handleAddDetails = (groupId: number, shipmentData: Shipment[]) => {
    navigate(`/shipments/add-details/${groupId}`, { state: { shipmentData } });
  };

  const handleCancelShipment = async (groupId: number) => {
    try {
      await publicInstance.patch(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`, { status: 'anulowane' });
      onUpdateCurrentShipments(groupId);
      showSnackbar('Shipment cancelled successfully.', 'success');
    } catch (error) {
      console.error('Error cancelling shipment:', error);
      showSnackbar('Failed to cancel shipment.', 'error');
    }
  };

  const cardStyle = {
    mb: 2,
    p: 2,
    backgroundColor: isDarkTheme ? alpha(theme.palette.background.paper, 0.2) : theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: isDarkTheme ? '0 4px 6px rgba(255, 255, 255, 0.1)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const buttonStyle = {
    '&.MuiButton-root': {
      fontSize: '0.8rem',
      padding: '6px 12px',
      minWidth: '120px',
      height: '32px',
      color: isDarkTheme ? theme.palette.primary.light : theme.palette.primary.contrastText,
      backgroundColor: isDarkTheme ? alpha(theme.palette.primary.dark, 0.7) : theme.palette.primary.main,
      '&:hover': {
        backgroundColor: isDarkTheme ? alpha(theme.palette.primary.main, 0.8) : theme.palette.primary.dark,
      },
    },
  };

  const cancelButtonStyle = {
    '&.MuiButton-root': {
      fontSize: '0.8rem',
      padding: '6px 12px',
      minWidth: '120px',
      height: '32px',
      color: isDarkTheme ? theme.palette.error.light : theme.palette.error.contrastText,
      backgroundColor: isDarkTheme ? alpha(theme.palette.error.dark, 0.7) : theme.palette.error.main,
      '&:hover': {
        backgroundColor: isDarkTheme ? alpha(theme.palette.error.main, 0.8) : theme.palette.error.dark,
      },
    },
  };

  return (
    <Box sx={{ width: isMobile ? '100%' : '48%', display: 'flex', flexDirection: 'column', mb: isMobile ? 4 : 0 }}>
      <Typography variant="h4" gutterBottom>
        Current Shipments ({Object.keys(groupedShipments).length})
      </Typography>
      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: isMobile ? '60vh' : '80vh',
      }}>
        {Object.values(groupedShipments).map((group, index) => (
          <Card key={index} sx={cardStyle}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', wordBreak: 'break-word' }}>
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                <Typography variant="body2">
                  <strong>Group ID:</strong> {group[0].group_id}
                </Typography>
                <Typography variant="body2">
                  <strong>Recipient:</strong> {group[0].recipient}
                </Typography>
                <Typography variant="body2">
                  <strong>Planned Shipping Date:</strong> {group[0].planned_shipping_date}
                </Typography>
                {group.map((item, idx) => (
                  <Typography key={idx} variant="body2">
                    <strong>{item.device_type}:</strong> {item.quantity}
                  </Typography>
                ))}
                <Typography variant="body2">
                  <strong>Comments:</strong> {group[0].comments}
                </Typography>
                <Typography variant="body2">
                  <strong>Status:</strong> {group[0].status}
                </Typography>
              </Box>
              <Box sx={{ mr: 5, overflowY: 'auto', maxHeight: '20vh' }}>
                {group.map((item, idx) => {
                  let color, isP5 = false, isP8 = false;
                  switch (item.device_type) {
                    case 'sensorbox(Orange)':
                      color = 'orange';
                      break;
                    case 'sensorbox(Play)':
                      color = 'purple';
                      break;
                    case 'sensorbox(Plus)':
                      color = 'green';
                      break;
                    case 'p5':
                      color = 'black';
                      isP5 = true;
                      break;
                    case 'p8':
                      color = 'black';
                      isP8 = true;
                      break;
                    default:
                      color = 'grey';
                  }
                  return (
                    <Box key={idx} sx={{ mb: 0.1 }}>
                      {renderBlocks(item.quantity, color, isP5, isP8)}
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleAddDetails(group[0].group_id, group)}
                  sx={buttonStyle}
                >
                  Add Details
                </Button>
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={() => handleCancelShipment(group[0].group_id)}
                  sx={cancelButtonStyle}
                >
                  Cancel
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default CurrentShipments;
