import React, { useEffect, useState } from "react";
import { Box, Snackbar, Alert, GlobalStyles, useTheme, useMediaQuery } from '@mui/material';
import axiosInstance, {publicInstance} from '../axiosInstance';
import CurrentServiceComments from './CurrentServiceComments';
import ServiceHistory from './ServiceHistory';
import ReportRepairDialog from './ReportRepairDialog';
import { useOutletContext } from 'react-router-dom';
import { useRefresh } from '../../Components/RefreshContext';
import { useAtom } from 'jotai';
import { serviceCommentsCountAtom } from '../../helpers/atoms';

const RepairPanel: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentComments, setCurrentComments] = useState([]);
  const [historyComments, setHistoryComments] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [users, setUsers] = useState([]);
  const { handleRepair, updateServiceCommentsCount } = useOutletContext<{ handleRepair: (box: number) => void, updateServiceCommentsCount: (count: number) => void }>();
  const { refresh } = useRefresh();
  const [, setServiceCommentsCount] = useAtom(serviceCommentsCountAtom);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const fetchCurrentComments = () => {
      setIsLoading(true); 
      publicInstance.get('/view_dacapi_sensorbox_service_comments')
        .then(response => {
          const sortedData = response.data.sort((a, b) => b.box - a.box);
          setCurrentComments(sortedData);
          updateServiceCommentsCount(sortedData.length);
          setServiceCommentsCount(sortedData.length); 
        })
        .catch(error => console.error('Error fetching service comments:', error))
        .finally(() => setIsLoading(false)); 
    };

    const fetchHistoryComments = () => {
      publicInstance.get('/dacapi_sensorbox_service_comments')
        .then(response => {
          const sortedData = response.data.sort((a, b) => new Date(b.time_stamp).getTime() - new Date(a.time_stamp).getTime());
          setHistoryComments(sortedData);
        })
        .catch(error => console.error('Error fetching history comments:', error));
    };

    const fetchUsers = () => {
      axiosInstance.get('/auth/users')
        .then(response => {
          setUsers(response.data);
        })
        .catch(error => console.error('Error fetching users:', error));
    };

    fetchCurrentComments();
    fetchHistoryComments();
    fetchUsers();
  }, [refresh, updateServiceCommentsCount, setServiceCommentsCount]);

  const handleDialogOpen = (box: number) => {
    setSelectedBox(box);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewComment('');
  };

  const handleNewComment = (comment) => {
    setHistoryComments([comment, ...historyComments]);
    if (comment.status === 'awaria') {
      setCurrentComments([comment, ...currentComments]);
    } else {
      setCurrentComments(currentComments.filter((currentComment) => currentComment.box !== comment.box));
    }
    if (handleRepair) {
      handleRepair(comment.box); 
    } else {
      console.error('handleRepair is not defined');
    }
    refresh();
  };

  const handleSubmit = async () => {
    try {
      console.log('Fetching user data...');
      const userResponse = await axiosInstance.get('/auth/me');
      console.log('User data fetched:', userResponse.data);

      const username = userResponse.data.username;

      const newCommentData = {
        comment: newComment,
        username: username,
        id: Date.now(),
        time_stamp: new Date().toISOString(),
        box: selectedBox,
        status: 'naprawione'
      };

      console.log('Posting new comment data:', newCommentData);
      await axiosInstance.post('/form/report_service/', {
        box: selectedBox,
        comment: newComment,
      });

      console.log('New comment posted successfully.');
      handleNewComment(newCommentData);
      setSnackbarMessage('Repair reported successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleDialogClose(); 
    } catch (error) {
      console.error('Error submitting new service comment:', error);
      setSnackbarMessage('Error reporting repair');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
      
      <Box sx={{ width: '85%', margin: 'auto' }}>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 4 }}>
          <CurrentServiceComments comments={currentComments} users={users} onRepairClick={handleDialogOpen} />
          <ServiceHistory comments={historyComments} users={users} />
        </Box>

        <ReportRepairDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onSubmit={handleSubmit}
          newComment={newComment}
          setNewComment={setNewComment}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default RepairPanel;
