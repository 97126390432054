import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography, Snackbar, Alert, IconButton } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import UndoIcon from '@mui/icons-material/Undo';
import renderBlocks from './renderBlocks';
import { publicInstance } from '../axiosInstance';

const AddDetails = () => {
    const theme = useTheme();
    const { groupId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [details, setDetails] = useState<Details>({});

    const [currentInputs, setCurrentInputs] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('success');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const shipmentData = location.state?.shipmentData || [];

    useEffect(() => {
        const allFilled = shipmentData.every(item => {
            const currentDetails = details[item.device_type] || [];
            return currentDetails.length === item.quantity;
        });
        setIsSubmitDisabled(!allFilled);
    }, [details, shipmentData]);

    const handleDetailChange = (deviceType: string, value: string) => {
        setCurrentInputs({
            ...currentInputs,
            [deviceType]: value,
        });
    };

    const handleApply = (deviceType: string) => {
        const currentDetails = details[deviceType] || [];
        const value = currentInputs[deviceType];
        const numbers = value.trim().split(/\s+/);

        if (numbers.length === 0) {
            setSnackbarMessage('Please enter a value');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        if (currentDetails.length + numbers.length > shipmentData.find(item => item.device_type === deviceType)?.quantity) {
            setSnackbarMessage(`You cannot add more than the required quantity for ${deviceType}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        let additionalFields = {};
        if (deviceType.startsWith('sensorbox')) {
            const operator = deviceType.match(/\(([^)]+)\)/)?.[1];
            additionalFields = { operator };
        } else if (deviceType === 'p5' || deviceType === 'p8') {
            additionalFields = { board_type: deviceType };
        }

        const newDetails = [
            ...currentDetails,
            ...numbers.map(num => ({ ...additionalFields, box: num, mac_address: num })),
        ];

        setDetails({
            ...details,
            [deviceType]: newDetails,
        });

        setCurrentInputs({
            ...currentInputs,
            [deviceType]: '',
        });
    };

    const handleUndo = (deviceType: string) => {
        const currentDetails = details[deviceType] || [];
        if (currentDetails.length > 0) {
            const newDetails = currentDetails.slice(0, -1);
            setDetails({
                ...details,
                [deviceType]: newDetails,
            });
        }
    };

    interface Detail {
        box: string;
        mac_address: string;
    }
    
    type Details = {
        [deviceType: string]: Detail[];
    };
    
    
    
    const handleEdit = (deviceType: string, index: number, newValue: string) => {
        const currentDetails = details[deviceType] || [];
        const updatedDetails = currentDetails.map((detail, idx) => {
            if (idx === index) {
                return { ...detail, box: newValue, mac_address: newValue };
            }
            return detail;
        }).filter(detail => detail.box || detail.mac_address); 
    
        setDetails(prevDetails => {
            if (updatedDetails.length === 0) {
               
                const { [deviceType]: _, ...rest } = prevDetails;
                return rest;
            }
            return {
                ...prevDetails,
                [deviceType]: updatedDetails,
            };
        });
    };
    
    

    const validateDetails = () => {
        const seenNumbers = new Set();
        const seenMacs = new Set();

        for (const [deviceType, deviceDetails] of Object.entries(details)) {
            for (const detail of deviceDetails as any[]) {
                if (deviceType === 'p5' || deviceType === 'p8') {
                    const macRegex = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;
                    if (!macRegex.test(detail.mac_address)) {
                        return `Invalid MAC address format for ${deviceType}: ${detail.mac_address}`;
                    }
                    if (seenMacs.has(detail.mac_address)) {
                        return `Duplicate MAC address found for ${deviceType}: ${detail.mac_address}`;
                    }
                    seenMacs.add(detail.mac_address);
                } else {
                    const numberRegex = /^\d{4}$/;
                    if (!numberRegex.test(detail.box)) {
                        return `Invalid number format for ${deviceType}: ${detail.box}`;
                    }
                    if (seenNumbers.has(detail.box)) {
                        return `Duplicate number found for ${deviceType}: ${detail.box}`;
                    }
                    seenNumbers.add(detail.box);
                }
            }
        }
        return null;
    };

    const handleSubmit = async () => {
        const validationError = validateDetails();
        if (validationError) {
            setSnackbarMessage(validationError);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            details: Object.values(details).flat(),
            status: "skompletowane"
        };
        try {
            await publicInstance.patch(`/dacapi_shipment_of_devices?group_id=eq.${groupId}`, payload);
            navigate('/shipments', {
                state: {
                    snackbarOpen: true,
                    snackbarMessage: 'Details added successfully',
                    snackbarSeverity: 'success',
                },
            });
        } catch (error) {
            console.error('Error adding details:', error);
            navigate('/shipments', {
                state: {
                    snackbarOpen: true,
                    snackbarMessage: 'Error adding details',
                    snackbarSeverity: 'error',
                },
            });
        }
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const disabledButtonStyles = theme.name === 'dark' ? {
        backgroundColor: 'white',
        color: 'white',
    } : {};

    return (
        <Box sx={{ padding: 2 }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                sx={{ marginBottom: 2 }}
            >
                Back
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Typography variant="h5" gutterBottom>Add Details for Group ID: {groupId}</Typography>
                    {shipmentData.map((item: any) => {
                        const currentDetails = details[item.device_type] || [];
                        const hasAllDetails = currentDetails.length >= item.quantity;

                        return (
                            <Box key={item.device_type} sx={{ marginBottom: 2 }}>
                                <Typography variant="subtitle1">{item.device_type} (Quantity: {item.quantity})</Typography>
                                <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
                                    {!hasAllDetails && (
                                        <TextField
                                            label={`Enter numbers for ${item.device_type} (separated by spaces)`}
                                            size="small"
                                            multiline
                                            minRows={3}
                                            value={currentInputs[item.device_type] || ''}
                                            sx={{ maxWidth: 250 }}
                                            onChange={(e) => handleDetailChange(
                                                item.device_type,
                                                e.target.value
                                            )}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleApply(item.device_type)}
                                        disabled={hasAllDetails}
                                        sx={{
                                            ...(hasAllDetails ? disabledButtonStyles : {}),
                                            '&.Mui-disabled': {
                                                backgroundColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '',
                                                color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '',
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        Apply
                                    </Button>
                                    <IconButton
                                        aria-label="undo"
                                        onClick={() => handleUndo(item.device_type)}
                                        disabled={currentDetails.length === 0}
                                        sx={currentDetails.length === 0 ? disabledButtonStyles : {}}
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        );
                    })}
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSubmit}
                        disabled={isSubmitDisabled}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '',
                                color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '',
                                opacity: 1,
                            },
                        }}
                    >
                        Submit Details
                    </Button>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
                <Box sx={{ width: '75%', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 25, mt: 10 }}>
                    <Box>
                        {shipmentData.map((item: any, idx: number) => {
                            let color, isP5 = false, isP8 = false;
                            const deviceNumbers = (details[item.device_type] || []).map((d: any) => d.box || d.mac_address || '');

                            switch (item.device_type) {
                                case 'sensorbox(Orange)':
                                    color = 'orange';
                                    break;
                                case 'sensorbox(Play)':
                                    color = 'purple';
                                    break;
                                case 'sensorbox(Plus)':
                                    color = 'green';
                                    break;
                                case 'p5':
                                    color = "black";
                                    isP5 = true;
                                    break;
                                case 'p8':
                                    color = "black";
                                    isP8 = true;
                                    break;
                                default:
                                    color = 'grey';
                            }
                            return (
                                <Box key={idx}>
                                    {renderBlocks(item.quantity, color, isP5, isP8, true, deviceNumbers, (index, newValue) => handleEdit(item.device_type, index, newValue))}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddDetails;
