import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { UserProvider } from './Components/UserContext'; // Import UserProvider
import { APIProvider } from '@vis.gl/react-google-maps';

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <APIProvider apiKey={'AIzaSyB7bpmDZf_muf03KPDS28jW6bQdpOXE7Ng'} onLoad={() => console.log('Maps API has loaded.')}>
      <UserProvider>
        <App />
      </UserProvider>
    </APIProvider>
  </React.StrictMode>
);
