import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, Card } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import FeedbackList from './FeedbackList';
import DeletedFeedbackList from './DeletedFeedbackList';

import { publicInstance } from '../axiosInstance';
import { CssBaseline, useMediaQuery } from "@mui/material";

interface FeedbackPageProps {}

const FeedbackPage: React.FC<FeedbackPageProps> = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [currentFeedbackCount, setCurrentFeedbackCount] = useState(0);
  const [deletedFeedbackCount, setDeletedFeedbackCount] = useState(0);
  const { updateFeedbackCount, refreshFeedbackList, refreshFeedback } = useOutletContext<{ updateFeedbackCount: (count: number) => void, refreshFeedbackList: () => void, refreshFeedback: boolean }>();
  const isMobile = useMediaQuery('(max-width:600px)');

  const fetchCurrentFeedbackCount = useCallback(() => {
    publicInstance.get('/dacapi_feedback')
      .then(response => {
        setCurrentFeedbackCount(response.data.length);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const fetchDeletedFeedbackCount = useCallback(() => {
    publicInstance.get('/dacapi_feedback_historical')
      .then(response => {
        setDeletedFeedbackCount(response.data.length);
      })
      .catch(error => console.error('Error fetching deleted feedback data:', error));
  }, []);

  useEffect(() => {
    fetchCurrentFeedbackCount();
    fetchDeletedFeedbackCount();
  }, [fetchCurrentFeedbackCount, fetchDeletedFeedbackCount]);

  useEffect(() => {
    if (refreshFeedback) {
      fetchCurrentFeedbackCount();
      fetchDeletedFeedbackCount();
      refreshFeedbackList();
    }
  }, [refreshFeedback, fetchCurrentFeedbackCount, fetchDeletedFeedbackCount, refreshFeedbackList]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      fetchCurrentFeedbackCount();
    } else if (newValue === 1) {
      fetchDeletedFeedbackCount();
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', mt: isMobile ? 3 : 0 }}>
      <Box sx={{ width: '100%', position: 'sticky', top: 0, backgroundColor: 'background.default', zIndex: 10 }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={`Current Feedback (${currentFeedbackCount})`} />
          <Tab label={`Deleted Feedback (${deletedFeedbackCount})`} />
        </Tabs>
      </Box>
      <Box sx={{ width: '85%', margin: 'auto', mt: 4 }}>
        {tabIndex === 0 && <FeedbackList  refreshFeedback={refreshFeedback} />}
        {tabIndex === 1 && <DeletedFeedbackList updateDeletedFeedbackCount={setDeletedFeedbackCount} />}
      </Box>
    </Box>
  );
};

export default FeedbackPage;
