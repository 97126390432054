import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const ReportRepairDialog = ({ open, onClose, onSubmit, newComment, setNewComment }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Report Repair</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="New Comment"
          type="text"
          fullWidth
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportRepairDialog;
