import React, { useState } from 'react';
import { useAdminUsers } from './AdminUserContext';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, List, ListItem, ListItemText, IconButton, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance, {publicInstance} from '../axiosInstance';

interface AdminRolesProps {
  expanded: string | false;
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const AdminRoles: React.FC<AdminRolesProps> = ({ expanded, handleAccordionChange }) => {
  const { roles, fetchRoles } = useAdminUsers();
  const [newRole, setNewRole] = useState<string>('');

  const handleAddRole = async () => {
    try {
      await publicInstance.post('/roles', {
        role_name: newRole,
      });
      fetchRoles();
      setNewRole('');
    } catch (error) {
      console.error('Error adding role:', error);
    }
  };
  

  const handleDeleteRole = async (roleName: string) => {
    try {
      await axiosInstance.delete('/auth/roles', {
        params: {
          role_name: roleName,
        },
      });
      fetchRoles();
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  return (
    <Accordion expanded={expanded === 'adminRoles'} onChange={handleAccordionChange('adminRoles')} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Manage Roles</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Add New Role</Typography>
            <TextField
              label="Role Name"
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleAddRole} sx={{ mt: 2 }}>
              Add Role
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Existing Roles</Typography>
            <List>
              {roles.map((role) => (
                <ListItem key={role}>
                  <ListItemText primary={role} />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteRole(role)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminRoles;
