import React from "react";
import { MoreVert } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../Components/UserContext";
import {
  Box,
  Typography,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
  Skeleton,
  IconButton,
  useMediaQuery
} from "@mui/material";

import { styled, Theme, CSSObject } from '@mui/material/styles';
import dacapi from "../Components/Assets/macio.png";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';

import useSignOut from "react-auth-kit/hooks/useSignOut";
import Menu from '@mui/icons-material/Menu';

import { useSpring, animated } from '@react-spring/web';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});



const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...openedMixin(theme),
  '& .MuiDrawer-paper': openedMixin(theme),
}));

const Sidebar: React.FC<{ children: React.ReactNode, handleOpenFeedback: () => void, updateTheme: () => void, theme: any, darkTheme: any }> = ({ children, handleOpenFeedback, updateTheme, theme, darkTheme }) => {
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleSignOut = () => {
    signOut();
    localStorage.removeItem("role");
    navigate("/login");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerSpring = useSpring({
    width: mobileOpen ? drawerWidth : 0,
    opacity: mobileOpen ? 1 : 0,
  });

  const [feedbackHovered, setFeedbackHovered] = React.useState(false);
  const feedbackHoverStyle = useSpring({
    transform: feedbackHovered ? 'scale(1.05)' : 'scale(1)',
  });

  const [themeHovered, setThemeHovered] = React.useState(false);
  const themeHoverStyle = useSpring({
    transform: themeHovered ? 'scale(1.05)' : 'scale(1)',
  });

  const commonDrawerContent = (
    <>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Link to="/">
          <img src={dacapi} style={{ width: '100%' }} alt="DACAPI" />
        </Link>
      </Box>
      <List sx={{ flexGrow: 1, px: 2 }}>
        {children}
      </List>
      <Box sx={{ mb: 2, pr: 2, textAlign: 'right', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <animated.div style={feedbackHoverStyle}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', mb: 1, cursor: 'pointer' }}
            onMouseEnter={() => setFeedbackHovered(true)}
            onMouseLeave={() => setFeedbackHovered(false)}
            onClick={handleOpenFeedback}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              Send feedback
            </Typography>
            <IconButton
              color="primary"
              sx={{ fontSize: '100%' }}
            >
              <FeedbackIcon sx={{ fontSize: '150%' }} />
            </IconButton>
          </Box>
        </animated.div>
        <animated.div style={themeHoverStyle}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onMouseEnter={() => setThemeHovered(true)}
            onMouseLeave={() => setThemeHovered(false)}
            onClick={updateTheme}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              {theme === darkTheme ? 'Light Mode' : 'Dark Mode'}
            </Typography>
            <IconButton
              color="primary"
              sx={{ fontSize: '100%' }}
            >
              {theme === darkTheme ? <Brightness7Icon sx={{ fontSize: '150%' }} /> : <Brightness4Icon sx={{ fontSize: '150%' }} />}
            </IconButton>
          </Box>
        </animated.div>
      </Box>

      {user && (
        <Box sx={{ borderTop: 1, borderColor: 'divider', p: 2, display: 'flex', alignItems: 'center' }}>
          <Link to="/profile">
            <Avatar src={user.profile_image_path || "https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"} alt="User Profile" />
          </Link>
          <Box sx={{ ml: 2, flexGrow: 1 }}>
            <Typography variant="body1" fontWeight="bold">{user.username}</Typography>
            <Typography variant="body2" color="text.primary">{user.role}</Typography>
          </Box>
          <Link to="/profile">
            <MoreVert />
          </Link>
        </Box>
      )}
    </>
  );

  if (loading && !isMobile) {
    return (
      <DrawerStyled variant="permanent">
        <Divider />
        <Box sx={{ width: drawerWidth, display: 'flex', flexDirection: 'column', height: '100%', bgcolor: 'background.paper', boxShadow: 1 }}>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Skeleton variant="rectangular" width={drawerWidth - 32} height={40} />
          </Box>
          <List sx={{ flexGrow: 1, px: 2 }}>
            {Array.from(new Array(5)).map((_, index) => (
              <ListItemButton key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton variant="circular" width={40} height={40} sx={{ marginRight: 2 }} />
                <Skeleton variant="rectangular" width="100%" height={40} />
              </ListItemButton>
            ))}
          </List>
          <Box sx={{ borderTop: 1, borderColor: 'divider', p: 2, display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Box sx={{ ml: 2, flexGrow: 1 }}>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="80%" />
            </Box>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        </Box>
      </DrawerStyled>
    );
  }

  if (!user && !isMobile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Box textAlign="center">
          <Typography variant="h6">User not found</Typography>
          <Button variant="contained" color="primary" onClick={handleSignOut} style={{ marginTop: '1rem' }}>
            Log out
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {isMobile ? (
        <>
          <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1200, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: 'background.paper', p: 1, boxShadow: 1 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              DACAPI
            </Typography>
          </Box>
          
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {commonDrawerContent}
            </Drawer>
          
        </>
      ) : (
        <DrawerStyled variant="permanent">
          {commonDrawerContent}
        </DrawerStyled>
      )}
    </Box>
  );
};

export default Sidebar;

export interface SidebarItemProps {
  icon: React.ReactNode;
  text: string;
  active: boolean;
  alert?: boolean;
  onClick?: () => void;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({ icon, text, active, alert, onClick }) => {
  const [hovered, setHovered] = React.useState(false);
  const hoverStyle = useSpring({
    backgroundColor: hovered ? 'rgba(0, 0, 0, 0.1)' : active ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
    transform: hovered ? 'scale(1.05)' : 'scale(1)',
  });

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <animated.div style={hoverStyle}>
      <ListItemButton
        selected={active}
        sx={{ borderRadius: 1, position: 'relative' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {alert && <Box sx={{ position: 'absolute', right: 16, width: 20, height: 8, bgcolor: 'secondary.main', borderRadius: '50%' }} />}
      </ListItemButton>
    </animated.div>
  );
};
