import React from 'react';
import { Box } from '@mui/material';
import PDFViewer from './PDFViewer'; 

const DocsPanel = ( ) => (
    <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'background.default' }}>
        <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <PDFViewer  />
        </Box>
    </Box>
);

export default DocsPanel;
