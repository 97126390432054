import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  DataGrid, GridColDef, GridRenderCellParams, GridToolbar,
} from '@mui/x-data-grid';
import {
  Snackbar, Alert, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, TextField, Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import axiosInstance, {publicInstance} from '../axiosInstance';
import { useRefresh } from '../../Components/RefreshContext';
import { useTheme, styled, ThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Link as MuiLink, useMediaQuery } from '@mui/material';
import GrafanaIcon from '../../Components/Assets/Grafana_icon.svg';
import { useAtom } from 'jotai';
import { serviceCommentsCountAtom } from '../../helpers/atoms';

interface Device {
  box: number;
  status: string;
  device_type: string;
  location: string;
  number_of_dz: string;
}

interface DeviceTableProps {
  devices: Device[];
  loading: boolean;
  onDelete: (deviceNumber: number) => void;
}

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledBox = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 150px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const DeviceTableComponent: React.FC<DeviceTableProps> = ({ devices: initialDevices, loading, onDelete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [devices, setDevices] = useState<Device[]>(initialDevices);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [issueDescription, setIssueDescription] = useState('');
  const { refresh } = useRefresh();
  const [, setServiceCommentsCount] = useAtom(serviceCommentsCountAtom);

  useEffect(() => {
    setDevices(initialDevices);
  }, [initialDevices]);

  const handleDelete = useCallback(async (deviceNumber: number) => {
    if (window.confirm("Are you sure you want to delete this device?")) {
      try {
        const response = await publicInstance.delete(`/dacapi_sensorbox_introduction_form?box=eq.${deviceNumber}`);
        if (response.status === 204) {
          setDevices((prevDevices) => prevDevices.filter((device) => device.box !== deviceNumber));
          if (onDelete) onDelete(deviceNumber);
          setSnackbarMessage('Device deleted successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          refresh();
        } else {
          const errorData = response.data;
          throw new Error(errorData.message || "Failed to delete the device");
        }
      } catch (error: any) {
        console.error(`Error: ${error.message}`);
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  }, [onDelete, refresh]);

  const handleReportIssue = useCallback((device: Device) => {
    setSelectedDevice(device);
    setOpenReportDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenReportDialog(false);
    setIssueDescription('');
  }, []);

  const handleIssueSubmit = useCallback(async () => {
    if (!selectedDevice) return;

    try {
      const response = await axiosInstance.post('/form/report_issue/', {
        box: selectedDevice.box,
        comment: issueDescription,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSnackbarMessage('Issue reported successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setServiceCommentsCount(prevCount => prevCount + 1); 
        refresh();
      } else {
        throw new Error(response.data.message || "Failed to report the issue");
      }
    } catch (error: any) {
      console.error(`Error: ${error.message}`);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      handleDialogClose();
    }
  }, [issueDescription, selectedDevice, refresh, handleDialogClose, setServiceCommentsCount]);

  const handleSnackbarClose = useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  }, []);

  const renderActions = useCallback((params: GridRenderCellParams<any>) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <Link to={`/edit-device/${params.row.box}`} style={{ textDecoration: 'none' }}>
        <MuiLink component="button" variant="body2" underline="hover" sx={{ mb: 0 }}><strong>Edit</strong></MuiLink>
      </Link>
      <MuiLink component="button" variant="body2" underline="hover" onClick={() => handleDelete(params.row.box)}>
        <strong>Delete</strong>
      </MuiLink>
      <MuiLink component="button" variant="body2" underline="hover" onClick={() => handleReportIssue(params.row)}>
        <strong>Report</strong>
      </MuiLink>
      <MuiLink
        component="a"
        variant="body2"
        underline="hover"
        href={`https://logdb.test.sensorbox.pl/d/MiMb_wkVk/przeglad-boxa?orgId=1&refresh=5m&var-box=box${params.row.box}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={GrafanaIcon} alt="Grafana" style={{ width: '24px', height: '24px' }} />
      </MuiLink>
    </div>
  ), [handleDelete, handleReportIssue]);

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'box',
      headerName: 'Device Number',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', textAlign: 'center', fontSize: '1.2rem' }}>
          {params.value}
        </MuiLink>
      ),
      cellClassName: 'deviceNumberCell',
    },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'device_type', headerName: 'Device Type', flex: 1 },
    { field: 'location', headerName: 'Location', flex: 1 },
    { field: 'number_of_dz', headerName: 'Dz Number', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      sortable: false,
      renderCell: renderActions,
    },
  ], [renderActions]);

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer>
        
        <StyledBox sx={{ overflowX: isMobile ? 'auto' : 'hidden' }}>
          <DataGrid
            rows={devices}
            columns={columns}
            loading={loading}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={devices.length}
            initialState={{
              sorting: {
                sortModel: [{ field: 'box', sort: 'desc' }],
              },
              density: 'comfortable',
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            getRowId={(row: Device) => row.box}
            
            sx={{
              width: isMobile ? '47vh' : '100%', 
              '& .MuiDataGrid-row': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-toolbarContainer': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
              '& .deviceNumberCell': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.2rem',
                paddingLeft: '16px',
              },
              '@media (max-width: 600px)': {
                '& .MuiDataGrid-columnHeader': {
                  fontSize: '0.8rem',
                },
                '& .MuiDataGrid-cell': {
                  fontSize: '0.8rem',
                },
              },
            }}
          />
        </StyledBox>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={openReportDialog}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Report Issue</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Describe the issue with the device.
            </DialogContentText>
            <TextField
              id="issue-description"
              autoFocus
              margin="dense"
              label="Issue Description"
              type="text"
              fullWidth
              variant="outlined"
              value={issueDescription}
              onChange={(e) => setIssueDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleIssueSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </StyledContainer>
    </ThemeProvider>
  );
};

const DeviceTable = React.memo(DeviceTableComponent);

export default DeviceTable;
