import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, List, ListItem, ListItemText, Grid, Box, MenuItem, Select, FormControl, InputLabel, Chip, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import axiosInstance from '../axiosInstance';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { red } from '@mui/material/colors';

interface LogEntry {
    timestamp: string;
    username: string;
    user_id: number;
    endpoint: string;
    device_number: number;
    data: any;
    validation: string;
}

interface LogsAccordionProps {
    expanded: string | false;
    handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const LogsAccordion: React.FC<LogsAccordionProps> = ({ expanded, handleAccordionChange }) => {
    const [logs, setLogs] = useState<LogEntry[]>([]);
    const [filterUser, setFilterUser] = useState<string>('');
    const [filterDevice, setFilterDevice] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [filteredLogs, setFilteredLogs] = useState<LogEntry[]>([]);
    const [usernames, setUsernames] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const logsPerPage = 20;
    const theme = useTheme();

    const fetchLogs = async () => {
        try {
            const response = await axiosInstance.get('/serwis/logs');
            const sortedLogs: LogEntry[] = response.data.logs.sort((a: LogEntry, b: LogEntry) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
            setLogs(sortedLogs);
            setFilteredLogs(sortedLogs);

            const uniqueUsernames: string[] = [...new Set(sortedLogs.map((log: LogEntry) => log.username))];
            setUsernames(uniqueUsernames);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    const applyDateFilter = (logs: LogEntry[]) => {
        let filtered = logs;

        if (startDate) {
            filtered = filtered.filter(log => new Date(log.timestamp) >= startDate);
        }

        if (endDate) {
            filtered = filtered.filter(log => new Date(log.timestamp) <= endDate);
        }

        return filtered;
    };

    const handleFilter = async () => {
        let filteredResults: LogEntry[] = [];

        if (filterUser) {
            try {
                const response = await axiosInstance.get(`/serwis/logs/user/${filterUser}`);
                filteredResults = response.data.logs;
            } catch (error) {
                console.error('Error fetching logs by user:', error);
                return;
            }
        } else if (filterDevice) {
            try {
                const response = await axiosInstance.get(`/serwis/logs/device/${filterDevice}`);
                filteredResults = response.data.logs;
            } catch (error) {
                console.error('Error fetching logs by device:', error);
                return;
            }
        } else {
            filteredResults = logs;
        }

        // Apply date filter
        filteredResults = applyDateFilter(filteredResults);

        // Sort the results
        filteredResults.sort((a: LogEntry, b: LogEntry) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

        setFilteredLogs(filteredResults);
        setCurrentPage(1);
    };

    const handleResetFilters = () => {
        setFilterUser('');
        setFilterDevice('');
        setStartDate(null);
        setEndDate(null);
        setFilteredLogs(logs);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);
    const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

    const renderLogData = (log: LogEntry) => {
        if (log.endpoint === '/run-macio-read-box' && typeof log.data === 'object') {
            const shortEntries: [string, any][] = [];
            const longEntries: [string, any][] = [];
            const ranges = {
                "pm25": [0, 200],
                "pm10": [0, 200],
                "dust temp": [10, 50],
                "dust humid": [10, 80],
                "ambient temp": [-20, 40],
                "ambient press": [900, 1200],
                "ambient humid": [20, 90],
                "dust heater ntc": [10, 70],
                "dust rpm": [4450, 4550],
            };

            const validateValue = (key: string, value: any): boolean => {
                if (key in ranges) {
                    const [min, max] = ranges[key];
                    return min <= parseFloat(value) && parseFloat(value) <= max;
                } else if (key === "hardware") {
                    return value.includes("OK") && value.includes("MAC");
                } else if (key in ["network", "OSE_ESA"]) {
                    return value.includes("OK");
                }
                return true;
            };

            Object.entries(log.data).forEach(([key, value]) => {
                const isValid = validateValue(key, value);
                const entry = (
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '0.85rem',
                            color: isValid ? 'inherit' : red[500]
                        }}
                    >
                        <strong>{key}:</strong> {JSON.stringify(value)}
                    </Typography>
                );

                if (key.length + JSON.stringify(value).length < 30) {
                    shortEntries.push([key, entry]);
                } else {
                    longEntries.push([key, entry]);
                }
            });

            return (
                <Paper elevation={3} sx={{ p: 1, bgcolor: theme.palette.background.paper }}>
                    <Typography variant="subtitle2">Macio Read Box Output:</Typography>
                    <Box sx={{ mt: 0.5 }}>
                        <Grid container spacing={1}>
                            {shortEntries.map(([key, entry], index) => (
                                <Grid item xs={6} key={index}>
                                    {entry}
                                </Grid>
                            ))}
                        </Grid>
                        {longEntries.map(([key, entry], index) => (
                            <Box key={index} sx={{ mt: 0.5 }}>
                                {entry}
                            </Box>
                        ))}
                    </Box>
                </Paper>
            );
        }
        return <Typography variant="body2">Data: {JSON.stringify(log.data)}</Typography>;
    };

    return (
        <Accordion expanded={expanded === 'logs'} onChange={handleAccordionChange('logs')} sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>System Logs</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Filter Logs by User, Device Number, and Date Range</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Username</InputLabel>
                                        <Select
                                            value={filterUser}
                                            onChange={(e) => setFilterUser(e.target.value as string)}
                                            label="Username"
                                        >
                                            <MenuItem value=""><em>All Users</em></MenuItem>
                                            {usernames.map((username, index) => (
                                                <MenuItem key={index} value={username}>{username}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <TextField
                                        label="Device Number"
                                        value={filterDevice}
                                        onChange={(e) => setFilterDevice(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" sx={{ mt: 2 }}>Start Date:</Typography>
                        <DatePicker
                            selected={startDate}
                            onChange={(date: Date | null) => setStartDate(date)}
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            className="date-picker"
                            customInput={<TextField fullWidth margin="normal" />}
                        />
                        <Typography variant="body1" sx={{ mt: 2 }}>End Date:</Typography>
                        <DatePicker
                            selected={endDate}
                            onChange={(date: Date | null) => setEndDate(date)}
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            className="date-picker"
                            customInput={<TextField fullWidth margin="normal" />}
                        />
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={handleFilter}>
                                    Apply Filters
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" color="secondary" onClick={handleResetFilters}>
                                    Reset Filters
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Logs</Typography>
                        <Box
                            sx={{
                                maxHeight: '400px',
                                overflowY: 'auto',
                                border: `2px solid ${theme.palette.divider}`,
                                borderRadius: '4px',
                                
                            }}
                        >
                            <List>
                                {currentLogs.map((log, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            padding: '8px',
                                            bgcolor: index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper,
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <Typography component="span">
                                                        [{format(new Date(log.timestamp), 'PPpp')}] {log.username} (ID: {log.user_id}) - {log.endpoint} - Device: {log.device_number} -
                                                    </Typography>
                                                    <Chip
                                                        label={`Validation: ${log.validation}`}
                                                        color={log.validation === 'ERR' ? 'error' : 'success'}
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                    />
                                                </React.Fragment>
                                            }
                                        />
                                        {renderLogData(log)}
                                    </ListItem>
                                ))}
                            </List>
                        </Box>


                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button
                                variant="outlined"
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </Button>
                            <Typography sx={{ margin: '0 15px' }}>
                                Page {currentPage} of {totalPages}
                            </Typography>
                            <Button
                                variant="outlined"
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default LogsAccordion;
