import React from "react";
import { Grid, IconButton, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomSelect, CustomTextField } from "./FormElements";

const SensorsSection = ({
  sensors,
  numberOfSensors,
  numberOfChannels,
  options,
  handleSensorChange,
  handleNumberOfSensorChange,
  handleNumberOfChannelsChange,
  handleAddSensor,
  handleRemoveSensor,
  spacing,
}) => (
  <>
    <Typography variant="subtitle1" sx={{ mb: 1, }}>Sensors</Typography>
    {sensors.map((sensor, index) => (
      <Grid container spacing={spacing} key={index} sx={{ mb: 1,}}>
        <Grid item xs={4}>
          <CustomSelect
            label={`Sensor ${index + 1}`}
            value={sensor}
            onChange={(e) => handleSensorChange(index, e.target.value)}
            options={options.Sensors || []}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            label={`Serial Number ${index + 1}`}
            value={numberOfSensors[index]}
            onChange={(e) => handleNumberOfSensorChange(index, e.target.value)}
            sx={numberOfSensors[index] === ""
              ? {
                boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
              }
              : {}} minRows={undefined}          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            label={`Channels ${index + 1}`}
            value={numberOfChannels[index]}
            onChange={(e) => handleNumberOfChannelsChange(index, e.target.value)}
            sx={numberOfChannels[index] === ""
              ? {
                boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
              }
              : {}} minRows={undefined}          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            color="error"
            onClick={() => handleRemoveSensor(index)}
            sx={{ fontSize: 30 }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
    <Grid container>
      <Grid item xs={10} sm={7}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddSensor}
          sx={{
            width: "100%",
            height: "56px", // This should match the height of the input fields
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid rgba(0, 123, 255, 0.5)", // Adjust border style if needed
          }}
        >
          <AddCircleOutlineIcon fontSize="large" />
        </Button>
      </Grid>
    </Grid>
  </>
);

export default SensorsSection;
