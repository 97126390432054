import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Link as MuiLink, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, TextField, Button, CircularProgress, Paper, Dialog, DialogContent, DialogTitle, useTheme, Fade } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import { Data, MeasurementData, getStyledValue } from './utils';
import { Link } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { green } from '@mui/material/colors';
import { format, parseISO } from 'date-fns';


interface DetailsPanelProps {
  isMobile: boolean;
  selectedDevice?: Data;
  selectedBts?: any[];  // Zaktualizowany typ na tablicę BTS-ów
  measurementData?: MeasurementData[];
  loadingMeasurements?: boolean;
  timeRange?: string;
  customTimeRange?: string;
  customTimeUnit?: string;
  handleTimeRangeChange?: (event: SelectChangeEvent<string>) => void;
  handleCustomTimeRangeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCustomTimeUnitChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCustomTimeSubmit?: () => void;
  isLoadingNewData?: boolean; // Add this new prop
}

const networkColor = (network: string) => {
  switch (network) {
    case 'Orange':
      return 'orange';
    case 'Plus':
      return '#66bb6a'; // jaśniejszy zielony dla Plusa
    case 'Play':
      return '#ba68c8'; // jaśniejszy fioletowy dla Play
    default:
      return 'black';
  }
};


const DetailsPanel: React.FC<DetailsPanelProps> = ({
  isMobile,
  selectedDevice,
  selectedBts,
  measurementData = [],
  loadingMeasurements = false,
  timeRange,
  customTimeRange,
  customTimeUnit,
  handleTimeRangeChange,
  handleCustomTimeRangeChange,
  handleCustomTimeUnitChange,
  handleCustomTimeSubmit,
  isLoadingNewData = false // Add this new prop with a default value
}) => {
  const [openChartPopup, setOpenChartPopup] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.name === 'dark';
  const [chartHeight, setChartHeight] = useState(300);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateChartHeight = () => {
      if (chartContainerRef.current) {
        const containerHeight = chartContainerRef.current.clientHeight;
        setChartHeight(containerHeight * 0.8); // Set chart height to 80% of container height
      }
    };

    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);

    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, "dd/MM/yyyy HH:mm:ss");
    } catch (error) {
      console.error("Error parsing date:", dateString, error);
      return "Invalid Date";
    }
  };

  const formatTime = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, "HH:mm");
    } catch (error) {
      console.error("Error parsing time:", dateString, error);
      return "Invalid Time";
    }
  };

  const renderPreviewChart = () => (
    <Box sx={{ width: '100%', height: isMobile ? '30vh' : '40vh', display: 'flex', justifyContent: 'flex-start', mt: 4 }}>
      <ResponsiveContainer width="95%" height="100%">
        <LineChart data={measurementData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time_stamp"
            tickFormatter={(time) => formatTime(time)}
            stroke={isDarkMode ? '#fff' : '#666'}
          />
          <YAxis stroke={isDarkMode ? '#fff' : '#666'} />
          <RechartsTooltip
            labelFormatter={(label) => formatDate(label)}
            formatter={(value) => [parseFloat(value as string).toFixed(2), "PM10"]}
            contentStyle={{
              backgroundColor: isDarkMode ? '#333' : '#fff',
              border: `1px solid ${isDarkMode ? '#666' : '#ccc'}`,
              color: isDarkMode ? '#fff' : '#333',
            }}
          />
          <Legend 
            wrapperStyle={{
              color: isDarkMode ? '#fff' : '#333',
            }}
          />
          <Line type="monotone" dataKey="pm10" stroke="#8884d8" dot={false} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );

  const renderFullChart = (dataKey: string, color: string, title: string) => (
    <Box sx={{ width: '100%', height: '40vh', mb: 4, position: 'relative' }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Fade in={isLoadingNewData} unmountOnExit>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 1,
        }}>
          <CircularProgress />
        </Box>
      </Fade>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={measurementData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time_stamp"
            tickFormatter={(time) => formatTime(time)}
            stroke={isDarkMode ? '#fff' : '#666'}
          />
          <YAxis stroke={isDarkMode ? '#fff' : '#666'} />
          <RechartsTooltip
            labelFormatter={(label) => formatDate(label)}
            formatter={(value) => [parseFloat(value as string).toFixed(2), title]}
            contentStyle={{
              backgroundColor: isDarkMode ? '#333' : '#fff',
              border: `1px solid ${isDarkMode ? '#666' : '#ccc'}`,
              color: isDarkMode ? '#fff' : '#333',
            }}
          />
          <Legend 
            wrapperStyle={{
              color: isDarkMode ? '#fff' : '#333',
            }}
          />
          <Line type="monotone" dataKey={dataKey} stroke={color} dot={false} strokeWidth={3} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );

  const renderTimeRangeControls = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <InputLabel id="time-range-select-label">Time Range</InputLabel>
        <Select
          labelId="time-range-select-label"
          value={timeRange}
          onChange={handleTimeRangeChange}
          label="Time Range"
        >
          <MenuItem value="custom">Custom</MenuItem>
          <MenuItem value="1h">1 Hour</MenuItem>
          <MenuItem value="12h">12 Hours</MenuItem>
          <MenuItem value="1d">1 Day</MenuItem>
          <MenuItem value="7d">7 Days</MenuItem>
          <MenuItem value="30d">30 Days</MenuItem>
        </Select>
        {timeRange === 'custom' && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={customTimeUnit === 'days'}
                  onChange={handleCustomTimeUnitChange}
                  name="customTimeUnitSwitch"
                  color="primary"
                />
              }
              label={customTimeUnit === 'days' ? 'Days' : 'Hours'}
              sx={{ ml: 2 }}
            />
            <TextField
              variant="outlined"
              size="small"
              label={`Custom Time Range (${customTimeUnit})`}
              placeholder={`e.g. 5 ${customTimeUnit}`}
              value={customTimeRange}
              onChange={handleCustomTimeRangeChange}
              sx={{ ml: 2 }}
            />
            <Button variant="contained" size="small" onClick={handleCustomTimeSubmit} sx={{ ml: 2 }}>
              Apply
            </Button>
          </>
        )}
      </FormControl>
      {isLoadingNewData && <CircularProgress size={24} />}
    </Box>
  );

  return (
    <Paper sx={{ p: 2, height: '100%', flex: '1 1 auto' }}>
      {selectedDevice ? (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Box {selectedDevice.box}</Typography>
            <MuiLink component={Link} to={`/device/${selectedDevice.box}`} style={{ marginLeft: 'auto' }}>
              <Tooltip title="Show device details">
                <IconButton color="primary" aria-label="Go to device panel">
                  <AssessmentIcon />
                </IconButton>
              </Tooltip>
            </MuiLink>
          </Box>
          <Typography variant="body1">Modem: {selectedDevice.modem}</Typography>
          <Typography variant="body1">GPS Date: {formatDate(selectedDevice.gps_time_stamp)}</Typography>
          <Typography variant="body1">
            PM10: {getStyledValue(parseFloat(selectedDevice.pm10).toFixed(2), 'pm10')}
          </Typography>
          <Typography variant="body1">
            PM25: {getStyledValue(parseFloat(selectedDevice.pm25).toFixed(2), 'pm25')}
          </Typography>
          <Typography variant="body1">Measurements Date: {formatDate(selectedDevice.measurement_time)}</Typography>

          {renderTimeRangeControls()}

          {loadingMeasurements ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : measurementData.length > 0 ? (
            <>
              {renderPreviewChart()}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                <Button
                  variant="outlined"
                  startIcon={<FullscreenIcon />}
                  onClick={() => setOpenChartPopup(true)}
                >
                  View All Measurements
                </Button>
              </Box>
            </>
          ) : (
            <Typography variant="body1" sx={{ mt: 4 }}>No measurement data available for this device.</Typography>
          )}

          <Dialog
            open={openChartPopup}
            onClose={() => setOpenChartPopup(false)}
            maxWidth={false}
            fullWidth
            
            PaperProps={{
              sx: {
                width: '95vw',
                maxWidth: '95vw',
                height: '90vh',
                
              },
            }}
          >
            <DialogTitle sx={{ 
              m: 0, 
              p: 2, 
              bgcolor: isDarkMode ? '#0f0f0f' : '#1976d2', 
              color: '#FFFFFF'
            }}>
              <IconButton
                aria-label="close"
                onClick={() => setOpenChartPopup(false)}
                sx={{
                  position: 'absolute',
                  left: 8,
                  top: 12,
                  color: '#FFFFFF',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ pl: 6 }}>
                Detailed Charts for Box {selectedDevice.box}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ 
              p: 4, 
              bgcolor: 'background.paper',
              color: isDarkMode ? '#E0E0E0' : 'inherit',
              position: 'relative',
            }}>
              {renderTimeRangeControls()}
              <Box sx={{ mt: 4 }}>
                {renderFullChart("pm10", "#8884d8", "PM10 ")}
                {renderFullChart("pm25", "#82ca9d", "PM2.5 ")}
              </Box>
              <Fade in={isLoadingNewData} unmountOnExit>
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 1000,
                }}>
                  <CircularProgress size={60} />
                </Box>
              </Fade>
            </DialogContent>
          </Dialog>
        </Box>
      ) : selectedBts && Array.isArray(selectedBts) && selectedBts.length > 0 ? (
        <Box>
          <Typography variant="h6">BTS Details</Typography>
          {selectedBts.map((bts, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="body1">Station ID: {bts.station_id}</Typography>
              <Typography variant="body1" sx={{ color: networkColor(bts.siec_id) }}>
                Network: {bts.siec_id}
              </Typography>
              <Typography variant="body1">Location: {bts.lokalizacja}</Typography>
              <Typography variant="body1">BTS Location: {bts.bts_location}</Typography>
              
              <Typography variant="body1">Standard: {bts.standard}</Typography>
              <Typography variant="body1">
                Bands: {Array.from(new Set(bts.pasma)).join(', ')}
              </Typography>
              
              {bts.uwagi && (
                <Typography variant="body1">Uwagi: {bts.uwagi}</Typography>
              )}
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', mt: 1 }}>
                Distance: {bts.distance.toFixed(2)} meters
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body1">Click on a marker to see details</Typography>
      )}
    </Paper>
  );
};

export default DetailsPanel;
