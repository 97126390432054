import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed
import { Link as MuiLink } from "@mui/material";

const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', paddingLeft: '16px', fontSize: '1.2rem' }}>
      {params.value}
    </MuiLink>
  );
};

const renderConfChannels = (params: GridRenderCellParams) => {
  const channels = params.row.conf_channel_all || {};
  const channelDetails = Object.entries(channels)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');
  return channelDetails;
};

const renderNumberSensors = (params: GridRenderCellParams) => {
  const sensors = params.row.number_sensor_all || {};
  const sensorDetails = Object.entries(sensors)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');
  return sensorDetails;
};

const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', width: 100, renderCell: renderBoxLink },
  { field: 'version_dz', headerName: 'Version DZ', width: 100 },
  { field: 'version_stm', headerName: 'Version STM', width: 100 },
  { field: 'mac', headerName: 'MAC', width: 150 },
  { field: 'imei', headerName: 'IMEI', width: 150 },
  { field: 'imsi', headerName: 'IMSI', width: 150 },
  { field: 'iccid', headerName: 'ICCID', width: 200 },
  { field: 'rssi', headerName: 'RSSI', width: 100 },
  { field: 'modem_sensorbox', headerName: 'Modem Sensorbox', width: 150 },
  { field: 'graphana', headerName: 'Graphana', width: 100 },
  { field: 'rsync', headerName: 'Rsync', width: 100 },
  { field: 'trm_patch', headerName: 'TRM Patch', width: 100 },
  { field: 'root', headerName: 'Root', width: 100 },
  { field: 'vpns', headerName: 'VPNs', width: 200 },
  { field: 'vpn_check', headerName: 'VPN Check', width: 100 },
  { field: 'svlog', headerName: 'SVLog', width: 100 },
  { field: 'cron', headerName: 'Cron', width: 100 },
  { field: 'network', headerName: 'Network', width: 100 },
  { field: 'hwclock', headerName: 'HWClock', width: 100 },
  { field: 'inittab', headerName: 'Inittab', width: 100 },
  { field: 'conf_channel_all', headerName: 'Conf Channels', width: 300, renderCell: renderConfChannels },
  { field: 'number_sensor_all', headerName: 'Number Sensors', width: 300, renderCell: renderNumberSensors },
  { field: 'ose_esa', headerName: 'OSE/ESA', width: 100 },
  { field: 'routes', headerName: 'Routes', width: 100 },
  { field: 'auto_jas', headerName: 'Auto JAS', width: 100 },
  { field: 'version', headerName: 'Version', width: 300 },
  { field: 'customroutes', headerName: 'Custom Routes', width: 300 },
  { field: 'open_vpn', headerName: 'Open VPN', width: 100 },
  { field: 'open_wrt', headerName: 'Open WRT', width: 100 },
  { field: 'scripts_comments_sensorbox', headerName: 'Scripts Comments (Sensorbox)', width: 200 },
  { field: 'time_stamp_sensorbox', headerName: 'Time Stamp (Sensorbox)', width: 220 },
  { field: 'modem_gps', headerName: 'Modem GPS', width: 150 },
  { field: 'gps', headerName: 'GPS', width: 200 },
  { field: 'scripts_comments_gps', headerName: 'Scripts Comments (GPS)', width: 200 },
  { field: 'time_stamp_gps', headerName: 'Time Stamp (GPS)', width: 180 },
  { field: 'lac', headerName: 'LAC', width: 100 },
  { field: 'cid', headerName: 'CID', width: 100 },
  { field: 'scripts_comments_bts', headerName: 'Scripts Comments (BTS)', width: 200 },
  { field: 'time_stamp_bts', headerName: 'Time Stamp (BTS)', width: 180 },
  { field: 'contracts', headerName: 'Contracts', width: 200 },
  { field: 'sum_vpna_last_week', headerName: 'Sum VPNA Last Week', width: 180 },
  { field: 'sum_vpns_last_week', headerName: 'Sum VPNS Last Week', width: 180 },
  { field: 'sum_vpnb_last_week', headerName: 'Sum VPNB Last Week', width: 180 },
  { field: 'sum_ping_last_week', headerName: 'Sum Ping Last Week', width: 180 },
  { field: 'mac_table', headerName: 'MAC Table', width: 150 },
  { field: 'bin', headerName: 'BIN', width: 100 },
  { field: 'data', headerName: 'Data', width: 100 },
  { field: 'table_type', headerName: 'Table Type', width: 100 },
  { field: 'brightness_type', headerName: 'Brightness Type', width: 150 },
  { field: 'scripts_comments_board', headerName: 'Scripts Comments (Board)', width: 200 },
  { field: 'time_stamp_board', headerName: 'Time Stamp (Board)', width: 180 },
];

interface Table2Props {
  search: string;
}

const Table2: React.FC<Table2Props> = ({ search }) => {
  return <ReusableTable fetchUrl="/dacapi_sensorbox_current" columns={columns} search={search} />;
};

export default Table2;
