import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Dialog, DialogTitle, Button, DialogContent, Grid, Typography, Box, Divider, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import SensorsIcon from '@mui/icons-material/Sensors';
import CommentIcon from '@mui/icons-material/Comment';

interface DeviceInformationProps {
  deviceNumber: string;
  deviceData: any;
}

const DeviceInformation: React.FC<DeviceInformationProps> = ({ deviceNumber, deviceData }) => {
  const [openSensors, setOpenSensors] = useState(false);
  const [openComments, setOpenComments] = useState(false);

  const {
    status = 'N/A',
    device_type = 'N/A',
    location: deviceLocation = 'N/A',
    version_of_dz = 'N/A',
    number_of_dz = 'N/A',
    pull_up = 'N/A',
    power_supply_type = 'N/A',
    measurement_track_type = 'N/A',
    rtc_battery = 'N/A',
    voltage_filter = 'N/A',
    sensors = {},
    number_of_sensors = {},
    number_of_channels = {},
    antennas = {},
    comments = 'N/A',
  } = deviceData || {};

  const handleClickOpenSensors = () => {
    setOpenSensors(true);
  };

  const handleCloseSensors = () => {
    setOpenSensors(false);
  };

  const handleClickOpenComments = () => {
    setOpenComments(true);
  };

  const handleCloseComments = () => {
    setOpenComments(false);
  };

  const formatBoolean = (value: string): string => (value === 'true' ? 'Yes' : 'No');

  const renderInfoRow = (label: string, value: string, fullWidth: boolean = false) => (
    <Grid item xs={fullWidth ? 12 : 6}>
      <Box display="flex" flexWrap="wrap" alignItems="baseline" mb={0.5}>
        <Typography variant="body2" fontWeight="bold" sx={{ mr: 1, minWidth: fullWidth ? 'auto' : '40%' }}>{label}:</Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>{value}</Typography>
      </Box>
    </Grid>
  );

  const renderSection = (title: string, rows: { label: string, value: string, fullWidth?: boolean }[]) => (
    <Box mt={1} mb={1}>
      <Typography variant="h6" color="primary" gutterBottom>{title}</Typography>
      <Grid container spacing={1}>
        {rows.map(({ label, value, fullWidth }, index) => renderInfoRow(label, value, fullWidth))}
      </Grid>
    </Box>
  );

  return (
    <Card sx={{  margin: 'auto' }}>
      <CardHeader
        title="Production information"
        titleTypographyProps={{ variant: 'h5' }}
        action={
          <Button variant="contained" size="small" component={Link} to={`/edit-device/${deviceNumber}`}>
            UPDATE
          </Button>
        }
      />
      <CardContent>
        {renderSection("Basic Information", [
          { label: "Status", value: status, fullWidth: true },
          { label: "Device Type", value: device_type, fullWidth: true },
          { label: "Location", value: deviceLocation, fullWidth: true },
          { label: "Number of DZ", value: number_of_dz },
          { label: "Version of DZ", value: version_of_dz },
          { label: "Measurement Track Type", value: measurement_track_type, fullWidth: true },
          { label: "Power Supply Type", value: power_supply_type, fullWidth: true }
        ])}

        <Divider sx={{ my: 1 }} />

        {renderSection("Additional Information", [
          { label: "Pull Up", value: formatBoolean(pull_up) },
          { label: "RTC Battery", value: formatBoolean(rtc_battery) },
          { label: "Voltage Filter", value: formatBoolean(voltage_filter) }
        ])}

        {Object.keys(antennas).length > 0 && (
          <>
            <Divider sx={{ my: 1 }} />
            {renderSection("Antennas", [
              { label: "Antennas", value: Object.values(antennas).join(', '), fullWidth: true }
            ])}
          </>
        )}

        {Object.keys(sensors).length > 0 && (
          <Box mt={3}>
            <Box display="flex" justifyContent="flex-start">
              <Tooltip title="View Sensors">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpenSensors}
                  startIcon={<SensorsIcon />}
                  sx={{ mr: 1 }}
                >
                  Sensors
                </Button>
              </Tooltip>
              <Tooltip title="View Comments">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpenComments}
                  startIcon={<CommentIcon />}
                >
                  Comments
                </Button>
              </Tooltip>
            </Box>
          </Box>
        )}

        <Dialog open={openSensors} onClose={handleCloseSensors} maxWidth="sm" fullWidth>
          <DialogTitle>Sensors</DialogTitle>
          <DialogContent>
            {Object.keys(sensors).map((key, index) => (
              <Box key={key} mb={0.5}>
                <Typography variant="body2">
                  Sensor {index + 1}: {sensors[key] || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  Serial Number: {number_of_sensors[`number_of_sensor_${index + 1}`] || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  Channel: {number_of_channels && number_of_channels[`number_of_channel_${index + 1}`] ? number_of_channels[`number_of_channel_${index + 1}`] : 'N/A'}
                </Typography>
              </Box>
            ))}
          </DialogContent>
        </Dialog>

        <Dialog open={openComments} onClose={handleCloseComments} maxWidth="sm" fullWidth>
          <DialogTitle>Comments</DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              {comments}
            </Typography>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default DeviceInformation;
