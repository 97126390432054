import React, { useState, useCallback } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import { Map, Marker, MapCameraChangedEvent, MapCameraProps } from '@vis.gl/react-google-maps';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';



interface GPSInformationProps {
  gpsData?: any;
  latitude?: number;
  longitude?: number;
}

const GPSInformation: React.FC<GPSInformationProps> = ({ gpsData, latitude, longitude }) => {
  const defaultPosition = { lat: 52.237049, lng: 21.017532 }; // Center of Poland
  const defaultZoomLevel = 5;
  const markerZoomLevel = 10; 

  const [cameraProps, setCameraProps] = useState<MapCameraProps>({
    center: defaultPosition,
    zoom: defaultZoomLevel,
  });

  


  const modem = gpsData?.modem_gps || "Unknown";
  const gps = gpsData?.gps || "Unknown";
  const timeStamp = gpsData?.time_stamp_gps ? new Date(gpsData.time_stamp_gps).toLocaleString() : "Unknown";

  const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
    setCameraProps(ev.detail);
  }, []);

  const resetMap = () => {
    setCameraProps({
      center: defaultPosition,
      zoom: defaultZoomLevel,
    });
  };

  const zoomToMarker = () => {
    if (latitude !== undefined && longitude !== undefined) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: markerZoomLevel,
      });
    }
  };
  
  

  return (
    <Card>
      <CardHeader title="GPS Information" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Modem</Typography>
            <Typography variant="body2">{modem}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>GPS</Typography>
            <Typography variant="body2">{gps}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Last Updated</Typography>
            <Typography variant="body2">{timeStamp}</Typography>
          </Grid>
          
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <IconButton color="primary" onClick={resetMap}>
            <ZoomOut />
          </IconButton>
          <IconButton color="primary" onClick={zoomToMarker}>
            <ZoomInIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: '300px', width: '100%', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}>
          <Map
            center={cameraProps.center}
            zoom={cameraProps.zoom}
            onCameraChanged={handleCameraChange}
          >
            {latitude !== undefined && longitude !== undefined && (
              <Marker
                position={{ lat: latitude, lng: longitude }}
              />
            )}
          </Map>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GPSInformation;
