import React, { useState, useEffect } from 'react';
import { Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Box, Snackbar, Alert, TextField } from '@mui/material';
import Table1 from './Table1';
import Table2 from './Table2';
import Table3 from './Table3';
import Table4 from './Table4';
import Table5 from './Table5';
import ContractBoxesTable from './ContractBoxesTable';
import NewTable from './NaskIdOurTable';  // Importuj nową tabelę
import { useLocation } from 'react-router-dom';
import NaskIdOurTable from './NaskIdOurTable';

const DataTables: React.FC = () => {
  const [selectedTable, setSelectedTable] = useState<string>('Table1');
  const [search, setSearch] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.successMessage) {
      setSnackbarMessage(location.state.successMessage);
      setSnackbarOpen(true);
    }
  }, [location.state]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTable(event.target.value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', color: "text.primary", minHeight: '100vh', py: 4 }}>
      <div style={{ width: '86vw', margin: '0 auto' }}>
        <Container maxWidth={false} sx={{ maxWidth: '100%', height: 'calc(100vh - 120px)' }}>
          <Box sx={{ mb: 2, ml: 3, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <FormControl variant="outlined" sx={{ backgroundColor: 'white', width: '300px', mr: 2 }}>
              <InputLabel id="table-select-label">Select Table</InputLabel>
              <Select
                labelId="table-select-label"
                value={selectedTable}
                label="Select Table"
                onChange={handleChange}
              >
                <MenuItem value="Table1">Production table</MenuItem>
                <MenuItem value="Table2">SensorBOX table</MenuItem>
                <MenuItem value="Table3">BTS table</MenuItem>
                <MenuItem value="Table4">GPS table</MenuItem>
                <MenuItem value="Table5">Board table</MenuItem>
                <MenuItem value="ContractBoxesTable">Contract Boxes Table</MenuItem>
                <MenuItem value="NaskIdOurTable">Nask Id Table</MenuItem> {/* Nowa tabela */}
              </Select>
            </FormControl>
            <TextField
              label="Search by Box Number"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="medium"
              sx={{ width: '200px', borderRadius: '8px', backgroundColor: 'white' }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            {selectedTable === 'Table1' && <Table1 search={search} />}
            {selectedTable === 'Table2' && <Table2 search={search} />}
            {selectedTable === 'Table3' && <Table3 search={search} />}
            {selectedTable === 'Table4' && <Table4 search={search} />}
            {selectedTable === 'Table5' && <Table5 search={search} />}
            {selectedTable === 'ContractBoxesTable' && <ContractBoxesTable search={search} />}
            {selectedTable === 'NaskIdOurTable' && <NaskIdOurTable search={search} />} {/* Nowa tabela */}
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      </div>
    </Box>
  );
};

export default DataTables;
