export interface Data {
    box: number;
    pm10: string;
    pm25: string;
    time_stamp: string;
    modem: string;
    gps: string;
    scripts_comments: string;
    gps_time_stamp: string;
    contracts: string[] | null;
    measurement_time: string;
  }
  
  export interface MeasurementData {
    box: number;
    pm10: string | null;
    pm25: string | null;
    time_stamp: string;
  }
  
  export const markerUrls = {
    green: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    yellow: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
    red: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
    gray: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    caution: 'https://maps.gstatic.com/mapfiles/ms2/micons/caution.png',
    shadow: 'https://maps.gstatic.com/mapfiles/ms2/micons/msmarker.shadow.png'
  };
  
  export const getCategory = (value: number, type: 'pm10' | 'pm25') => {
    const colorCategories = [
      { label: "Zielony", color: "green", pm10: [0, 50], pm25: [0, 35], zIndex: 1 },
      { label: "Żółty", color: "yellow", pm10: [50, 110], pm25: [35, 75], zIndex: 2 },
      { label: "Czerwony", color: "red", pm10: [110, Infinity], pm25: [75, Infinity], zIndex: 3 }
    ];
  
    for (let category of colorCategories) {
      if (type === 'pm10') {
        if (value >= category.pm10[0] && value < category.pm10[1]) return category;
      } else {
        if (value >= category.pm25[0] && value < category.pm25[1]) return category;
      }
    }
    return null;
  };
  
  export const determineBoxColor = (pm10: string, pm25: string) => {
    if (pm10 === 'UNKNOWN' || pm25 === 'UNKNOWN') {
      return 'unknown';
    } else {
      const pm10Value = parseFloat(pm10);
      const pm25Value = parseFloat(pm25);
  
      const pm10Category = getCategory(pm10Value, 'pm10');
      const pm25Category = getCategory(pm25Value, 'pm25');
  
      if (!pm10Category || !pm25Category) return 'gray';
  
      return pm10Category.zIndex > pm25Category.zIndex ? pm10Category.color : pm25Category.color;
    }
  };
  
  export const parseGps = (gps: string) => {
    const [lat, lng] = gps.split(' ');
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);
    return { lat: latitude, lng: longitude };
  };
  
  export const parseGpsInput = (input: string): { lat: number; lng: number } | null => {
    // Regular expression to match various GPS input formats
    const gpsRegex = /^(?:N?:?\s?)?(-?\d+(?:\.\d+)?)[,\s]+(?:E?:?\s?)?(-?\d+(?:\.\d+)?)$/i;
    const match = input.match(gpsRegex);

    if (match) {
      const lat = parseFloat(match[1]);
      const lng = parseFloat(match[2]);

      // Check if the parsed values are within valid ranges
      if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
        return { lat, lng };
      }
    }

    return null;
  };

  export const getMarkerIcon = (pm10: string, pm25: string, lat: number, lng: number) => {
    let markerUrl = markerUrls.gray;
    let scaledSize = new google.maps.Size(32, 32); 
    let zIndex = Math.floor((lat + lng) * 100000); 
    let shadow = markerUrls.shadow; 
  
    const boxColor = determineBoxColor(pm10, pm25);
  
    switch (boxColor) {
      case 'green':
        markerUrl = markerUrls.green;
        break;
      case 'yellow':
        markerUrl = markerUrls.yellow;
        break;
      case 'red':
        markerUrl = markerUrls.red;
        break;
      case 'unknown':
        markerUrl = markerUrls.caution;
        scaledSize = new google.maps.Size(20, 20);
        shadow = ''; 
        break;
      default:
        break;
    }
  
    return {
      url: markerUrl,
      scaledSize: scaledSize,
      zIndex: zIndex,
      shadow: shadow
    };
  };
  
  export const getStyledValue = (value: string, type: 'pm10' | 'pm25') => {
    if (value === 'UNKNOWN') return <span style={{ color: 'gray' }}>{value}</span>;
    const numericValue = parseFloat(value);
    const color = getColorForValue(numericValue, type);
    return <span style={{ color }}>{value}</span>;
  };
  
  const getColorForValue = (value: number, type: 'pm10' | 'pm25') => {
    if (type === 'pm10') {
      if (value < 50) return 'green';
      if (value < 110) return '#FFD700'; // Darker yellow
      return 'red';
    } else {
      if (value < 35) return 'green';
      if (value < 75) return '#FFD700'; // Darker yellow
      return 'red';
    }
  };
