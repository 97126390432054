import React from 'react';
import { Box, Typography, Avatar, Popover } from '@mui/material';
import { markerUrls } from './utils'; 

interface LegendPopoverProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  handleLegendClose: () => void;
}

const LegendPopover: React.FC<LegendPopoverProps> = ({ anchorEl, open, handleLegendClose }) => {
  const id = open ? 'legend-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleLegendClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body2"><b>Legenda:</b></Typography>
        <Typography variant="body2">
          <Avatar src={markerUrls.green} sx={{ width: 16, height: 16, mr: 1, display: 'inline-block' }} /> Zielony: PM10 &lt; 50 & PM25 &lt; 35
        </Typography>
        <Typography variant="body2">
          <Avatar src={markerUrls.yellow} sx={{ width: 16, height: 16, mr: 1, display: 'inline-block' }} /> Żółty: 50 &le; PM10 &lt; 110 | 35 &le; PM25 &lt; 75
        </Typography>
        <Typography variant="body2">
          <Avatar src={markerUrls.red} sx={{ width: 16, height: 16, mr: 1, display: 'inline-block' }} /> Czerwony: PM10 &ge; 110 | PM25 &ge; 75
        </Typography>
        <Typography variant="body2">
          <Avatar src={markerUrls.caution} sx={{ width: 16, height: 16, mr: 1, display: 'inline-block' }} /> Nieznany: Nieznany stan
        </Typography>
      </Box>
    </Popover>
  );
};

export default LegendPopover;
