import React, { useState } from 'react';
import { Dialog, DialogContent, Tabs, Tab, Box, IconButton, TextField, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface AdminPopupProps {
  open: boolean;
  onClose: () => void;
}

const AdminPopup: React.FC<AdminPopupProps> = ({ open, onClose }) => {
  const [value, setValue] = useState(0);
  const [input1, setInput1] = useState('');
  const [output1, setOutput1] = useState('');
  const [output1Pipes, setOutput1Pipes] = useState('');
  const [input2, setInput2] = useState('');
  const [output2, setOutput2] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const processTab1 = () => {
    const lines = input1.split('\n').map(line => line.trim()).filter(line => line !== '');
    const resultSpaces = lines.join(' ');
    const resultPipes = lines.join('|');
    setOutput1(resultSpaces);
    setOutput1Pipes(resultPipes);
  };

  const processTab2 = () => {
    const result = input2.split(' ').join('\n');
    setOutput2(result);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: { minHeight: '90vh' },
      }}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 16,
            top: 16,
            '& .MuiSvgIcon-root': {
              fontSize: '2rem',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 6 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Spacje i Pajpy" />
            <Tab label="Nowe linie" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Ta zakładka zamienia znaki nowej linii na spacje oraz łączy linie znakiem |.
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={input1}
            onChange={(e) => setInput1(e.target.value)}
            placeholder="Wprowadź tekst (każda linia w nowym wierszu)..."
          />
          <Button variant="contained" onClick={processTab1} sx={{ mt: 2, mb: 2 }}>
            Przetwórz
          </Button>
          <Typography variant="h6">Wynik (spacje):</Typography>
          <TextField
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            value={output1}
            InputProps={{
              readOnly: true,
            }}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>Wynik (pajpy):</Typography>
          <TextField
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            value={output1Pipes}
            InputProps={{
              readOnly: true,
            }}
          />
          <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
            Aby skopiować wynik, zaznacz tekst powyżej i użyj Ctrl+C (lub Cmd+C na Mac).
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Ta zakładka zamienia spacje na znaki nowej linii.
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={input2}
            onChange={(e) => setInput2(e.target.value)}
            placeholder="Wprowadź tekst..."
          />
          <Button variant="contained" onClick={processTab2} sx={{ mt: 2, mb: 2 }}>
            Przetwórz
          </Button>
          <Typography variant="h6">Wynik:</Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={output2}
            InputProps={{
              readOnly: true,
            }}
          />
          <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
            Aby skopiować wynik, zaznacz tekst powyżej i użyj Ctrl+C (lub Cmd+C na Mac).
          </Typography>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default AdminPopup;