import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from "@mui/material";


const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink 
      component={Link} 
      to={`/device/${params.value}`} 
      style={{ 
        display: 'block', 
        width: '100%', 
        paddingLeft: '16px', 
        fontSize: '1.2rem' 
      }}
    >
      {params.value}
    </MuiLink>
  );
};


const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', flex: 1, renderCell: renderBoxLink },
  { field: 'id_instalacji', headerName: 'ID Instalacji', flex: 1 },
  { field: 'id_esa', headerName: 'ID ESA', flex: 1 },
  { field: 'id_awarie', headerName: 'ID Awarie', flex: 1 },
];


interface NaskIdOurTableProps {
  search: string;
}

const NaskIdOurTable: React.FC<NaskIdOurTableProps> = ({ search }) => {
  return <ReusableTable fetchUrl="/dacapi_nask_id_our" columns={columns} search={search} idColumn="id_instalacji" />;
};

export default NaskIdOurTable;
