// FeedbackList.tsx
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Card, CardContent, CardActions } from '@mui/material';
import axiosInstance, {publicInstance} from '../axiosInstance';
import { useAtom } from 'jotai';
import { feedbackCountAtom } from '../../helpers/atoms';

interface FeedbackListProps {
  refreshFeedback: boolean;
}

const FeedbackList: React.FC<FeedbackListProps> = ({ refreshFeedback }) => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [users, setUsers] = useState([]);
  const [, setFeedbackCount] = useAtom(feedbackCountAtom);

  useEffect(() => {
    const fetchFeedbacks = () => {
      publicInstance.get('/dacapi_feedback')
        .then(response => {
          const sortedData = response.data.sort((a, b) => b.id - a.id);
          setFeedbackList(sortedData);
          setFeedbackCount(sortedData.length);
        })
        .catch(error => console.error('Error fetching data:', error));
    };

    const fetchUsers = () => {
      axiosInstance.get('/auth/users')
        .then(response => {
          setUsers(response.data);
        })
        .catch(error => console.error('Error fetching users:', error));
    };

    fetchFeedbacks();
    fetchUsers();
  }, [setFeedbackCount, refreshFeedback]);

  const handleDelete = (id: number) => {
    publicInstance.delete(`/dacapi_feedback?id=eq.${id}`)
      .then(response => {
        if (response.status === 204) {
          const updatedList = feedbackList.filter(item => item.id !== id);
          setFeedbackList(updatedList);
          setFeedbackCount(updatedList.length);
        } else {
          alert('Failed to delete the item.');
        }
      })
      .catch(error => console.error('Error deleting item:', error));
  };

  return (
    <Box sx={{ width: '85%', margin: 'auto', mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Feedback List ({feedbackList.length})
      </Typography>
      {feedbackList.map((feedback) => {
        const user = users.find(u => u.username === feedback.username);
        return (
          <Card key={feedback.id} sx={{ mb: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Avatar src={user?.profile_image_path} alt={feedback.username} sx={{ mr: 2 }} />
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                <Typography variant="body2">
                  <strong>Username:</strong> {feedback.username}
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                  <strong>Comment:</strong> {feedback.comment}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Created At:</strong> {new Date(feedback.time_stamp).toLocaleString()}
                </Typography>
              </Box>
              <CardActions>
                <Button variant="outlined" color="error" onClick={() => handleDelete(feedback.id)}>
                  Delete
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default FeedbackList;
