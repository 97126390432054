import React from 'react';
import { Grid, IconButton, Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomSelect } from './FormElements';

const AntennasSection = ({ antennas, options, handleAntennaChange, handleAddAntenna, handleRemoveAntenna, spacing }) => (
  <>
    <Typography variant="subtitle1" sx={{ mb: 1, mt: 1 }}>Antennas</Typography>
    {antennas.map((antenna, index) => (
      <Grid container spacing={spacing} key={index} sx={{ mb: 1, }}>
        <Grid item xs={10} sm={10}>
          <CustomSelect
            label={`Antenna ${index + 1}`}
            value={antenna}
            onChange={(e) => handleAntennaChange(index, e.target.value)}
            options={options.Antennas || []}
            required
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton color="error" onClick={() => handleRemoveAntenna(index)} sx={{ fontSize: 30 }}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
    <Grid container >
      <Grid item xs={10} sm={10}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddAntenna}
          sx={{
            width: '100%',
            height: '56px',  // This should match the height of the input fields
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(0, 123, 255, 0.5)', // Adjust border style if needed
          }}
        >
          <AddCircleOutlineIcon fontSize="large" />
        </Button>
      </Grid>
    </Grid>
  </>
);

export default AntennasSection;
