import React from 'react';
import ReusableTable from './ReusableTable';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link as MuiLink } from "@mui/material";
import { Link } from 'react-router-dom';

const renderBoxLink = (params: GridRenderCellParams) => {
  return (
    <MuiLink component={Link} to={`/device/${params.value}`} style={{ display: 'block', width: '100%', paddingLeft: '16px', fontSize: '1.2rem' }}>
      {params.value}
    </MuiLink>
  );
};

const columns: GridColDef[] = [
  { field: 'box', headerName: 'Box', flex:1, renderCell: renderBoxLink },
  { field: 'lac', headerName: 'LAC', flex:1 },
  { field: 'cid', headerName: 'CID', flex:1 },
  { field: 'scripts_comments', headerName: 'Scripts Comments', flex:1 },
  { field: 'time_stamp', headerName: 'Time Stamp', flex:1 },
];

interface Table3Props {
  search: string;
}

const Table3: React.FC<Table3Props> = ({ search }) => {
  return <ReusableTable fetchUrl="/dacapi_bts_current" columns={columns} search={search} />;
};

export default Table3;
