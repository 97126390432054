// src/hooks/useFormState.tsx
import { useState } from 'react';

export const useFormState = (initialState) => {
  const [state, setState] = useState(initialState);

  const handleChange = (key) => (event) => {
    const value = event.target.value;
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleToggle = (key) => (event) => {
    const value = event.target.checked;
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return [state, handleChange, handleToggle, setState];
};
