import React, { SyntheticEvent, useState } from 'react';
import { Box, TextField, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../axiosInstance';

interface UserProfileImageProps {
  expanded: string | false;
  handleAccordionChange: (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

const UserProfileImage: React.FC<UserProfileImageProps> = ({ expanded, handleAccordionChange }) => {
  const [userId, setUserId] = useState<string>('');
  const [newProfileImagePath, setNewProfileImagePath] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string>('');

  const handleProfileImageUpdate = async () => {
    const formData = new URLSearchParams();
    formData.append('profile_image_path', newProfileImagePath);

    try {
      await axiosInstance.put(`/auth/users/${userId}/profile-image`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      setUpdateSuccess(true);
      setUpdateError('');
    } catch (error) {
      setUpdateError('Error updating profile image. Please try again.');
      setUpdateSuccess(false);
      console.error('Error updating profile image:', error);
    }
  };

  return (
    <Accordion expanded={expanded === 'profileImages'} onChange={handleAccordionChange('profileImages')} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Manage User Profile Images</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="New Profile Image Path"
              value={newProfileImagePath}
              onChange={(e) => setNewProfileImagePath(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProfileImageUpdate}
              sx={{ mt: 2 }}
              fullWidth
            >
              Update Profile Image
            </Button>
          </Grid>
          {updateSuccess && (
            <Grid item xs={12}>
              <Alert severity="success">Profile image updated successfully!</Alert>
            </Grid>
          )}
          {updateError && (
            <Grid item xs={12}>
              <Alert severity="error">{updateError}</Alert>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UserProfileImage;
