import { createTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import { TypeBackground } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    secondary: string;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    name: string;
  }
  interface ThemeOptions {
    name?: string;
  }
}


declare module '@mui/material/styles/createPalette' {
  interface Palette {
    status: {
      awaria: string;
      naprawione: string;
    };
  }

  interface PaletteOptions {
    status?: {
      awaria?: string;
      naprawione?: string;
    };
  }
}

export const lightTheme = createTheme({
  name: 'light',
  palette: {
    
    background: {
      default: "#e8f4f8",
      paper: "#FCFCFC",
      secondary: "#f5f5f5"
    },
    status: {
      awaria: "#ffcccc", // Lighter red for 'awaria'
      naprawione: "#c8e6c9" // Lighter green for 'naprawione'
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#1976D2" // Blue accent color for list item icons
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            
            backgroundColor: '#FCFCFC' // Updated background color
          },
          
        }
      }
    },
    



    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#1D1D1D' // Kolor strzałki w polu select
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "1px solid #e0e0e0",
          boxShadow: "none",
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
          marginBottom: -1,
          minHeight: 56,
          '&$expanded': {
            minHeight: 56,
          }
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          }
        },
        expandIconWrapper: {
          '&$expanded': {
            transform: 'rotate(90deg)',
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        }
      }
    }
  }
});

export const darkTheme = createTheme({
  name: 'dark',
  palette: {
    
    background: {
      default: "#121212", // Very dark gray background
      paper: "#2C2C2C", // Slightly lighter dark gray for paper
      secondary: "#1E1E1E"
    },
    text: {
      primary: "#E0E0E0", // Light gray text
      secondary: "#B0B0B0" // Slightly darker gray text for secondary elements
    },
    status: {
      awaria: "#b71c1c", // Dark red for 'awaria'
      naprawione: "#1b5e20" // Dark green for 'naprawione'
    }
  },
  components: {
    
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#64B5F6", // Blue accent color for links
          textDecoration: "none"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#64B5F6', // Light gray for icons
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          color: "#E0E0E0" // Light gray for headers
        },
        h4: {
          color: "#E0E0E0"
        },
        body1: {
          color: "#FFFFFF" // Slightly darker gray for body text
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#64B5F6" // Blue accent color for list item icons
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#333333" // Dark gray for the app bar
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px", 
          color: "#E0E0E0",
          backgroundColor: "#333333",
          '&:hover': {
            backgroundColor: "#444444"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: "0px", 
            '& fieldset': {
              borderColor: '#64B5F6'
            },
            '&:hover fieldset': {
              borderColor: '#90CAF9'
            }
          },
          '& .MuiInputBase-root': {
            color: '#E0E0E0',
            backgroundColor: '#2C2C2C' 
          },
          '& .MuiInputLabel-root': {
            color: '#B0B0B0'
          },
          '& .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: '#E0E0E0' 
          }
        }
      }
    },
    
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: "0px", // Border radius set to 0
            '& fieldset': {
              borderColor: '#64B5F6'
            },
            '&:hover fieldset': {
              borderColor: '#90CAF9'
            }
          },
          '& .MuiInputBase-root': {
            color: '#E0E0E0',
            backgroundColor: '#1E1E1E'
          },
          '& .MuiInputLabel-root': {
            color: '#B0B0B0'
          },
          '& .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: '#E0E0E0' // Kolor ikony w form controls w trybie ciemnym
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#E0E0E0' // Kolor strzałki w polu select w trybie ciemnym
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "1px solid #424242",
          boxShadow: "none",
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#1E1E1E',
          borderBottom: '1px solid rgba(255, 255, 255, .125)',
          marginBottom: -1,
          minHeight: 56,
          '&$expanded': {
            minHeight: 56,
          }
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          }
        },
        expandIconWrapper: {
          '&$expanded': {
            transform: 'rotate(90deg)',
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#64B5F6',
        }
      }
    }
  }
});
