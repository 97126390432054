import React, { useEffect, useState } from "react";
import { Box, Snackbar, Alert, GlobalStyles, useTheme, useMediaQuery, Button, Typography, Dialog, DialogContent } from '@mui/material';
import axiosInstance, { publicInstance } from '../axiosInstance';
import CurrentShipments from './CurrentShipments';
import ShipmentHistory from './ShipmentHistory';
import CompletedShipments from './CompletedShipments'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { shipmentsCountAtom, completedShipmentsCountAtom } from '../../helpers/atoms';
import CircularProgress from '@mui/material/CircularProgress';

const ShipmentPanel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentShipments, setCurrentShipments] = useState([]);
  const [completedShipments, setCompletedShipments] = useState([]); 
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [loading, setLoading] = useState(true); // Stan ładowania początkowego
  const [updatingShipments, setUpdatingShipments] = useState(false); // Stan aktualizacji przesyłek
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('success');
  const navigate = useNavigate();
  const location = useLocation();  
  const [, setShipmentsCount] = useAtom(shipmentsCountAtom);
  const [completedShipmentsCount, setCompletedShipmentsCount] = useAtom(completedShipmentsCountAtom); // Nowy atom
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);

  useEffect(() => {
    if (location.state?.snackbarOpen) {
      setSnackbarOpen(location.state.snackbarOpen);
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarSeverity(location.state.snackbarSeverity);
    }

    const fetchCurrentShipments = () => {
      return publicInstance.get('/dacapi_shipment_of_devices')
        .then(response => {
          const filteredShipments = response.data.filter(shipment => shipment.status === 'utworzone');
          setCurrentShipments(filteredShipments);

          const uniqueGroupIds = new Set(filteredShipments.map(item => item.group_id));
          setShipmentsCount(uniqueGroupIds.size); 
        })
        .catch(error => console.error('Error fetching current shipments:', error));
    };

    const fetchCompletedShipments = () => {
      return publicInstance.get('/dacapi_shipment_of_devices')
        .then(response => {
          const filteredCompletedShipments = response.data.filter(shipment => shipment.status === 'skompletowane');
          setCompletedShipments(filteredCompletedShipments); 

          const uniqueCompletedGroupIds = new Set(filteredCompletedShipments.map(item => item.group_id));
          setCompletedShipmentsCount(uniqueCompletedGroupIds.size); 
        })
        .catch(error => console.error('Error fetching completed shipments:', error));
    };

    // Removed fetchShipmentHistory() call from here

    Promise.all([fetchCurrentShipments(), fetchCompletedShipments()])
      .finally(() => setLoading(false));
  }, [setShipmentsCount, setCompletedShipmentsCount, location.state]);

  const updateCurrentShipments = (groupId: number) => {
    setCurrentShipments(prevShipments => prevShipments.filter(shipment => shipment.group_id !== groupId));
    setShipmentsCount(prevCount => prevCount - 1);
  };

  const updateCompletedShipments = (groupId: number) => {
    setCompletedShipments(prevShipments => prevShipments.filter(shipment => shipment.group_id !== groupId));
    setCompletedShipmentsCount(prevCount => prevCount - 1);
  };

  const moveToCurrentShipments = (shipments: any[]) => {
    setCurrentShipments(prevShipments => [...prevShipments, ...shipments]);
    setCompletedShipments(prevShipments => prevShipments.filter(shipment => shipment.group_id !== shipments[0].group_id));
    setShipmentsCount(prevCount => prevCount + 1);
    setCompletedShipmentsCount(prevCount => prevCount - 1);
  };

  const showSnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleViewHistory = () => {
    setHistoryOpen(true);
    setHistoryLoading(true);
    publicInstance.get('/dacapi_shipment_of_devices_historical')
      .then(response => {
        setShipmentHistory(response.data);
      })
      .catch(error => {
        console.error('Error fetching shipment history:', error);
        showSnackbar('Failed to load shipment history', 'error');
      })
      .finally(() => setHistoryLoading(false));
  };

  const isEmpty = currentShipments.length === 0 && completedShipments.length === 0 && !loading && !updatingShipments;

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
      
      <Box sx={{ width: '85%', margin: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/shipments/add')}
          >
            Add Shipment
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleViewHistory}
          >
            View History
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 4 }}>
          <CurrentShipments 
            shipments={currentShipments} 
            onUpdateCurrentShipments={updateCurrentShipments}
            showSnackbar={showSnackbar}
          />
          <CompletedShipments 
            shipments={completedShipments} 
            onUpdateCompletedShipments={updateCompletedShipments} 
            onMoveToCurrentShipments={moveToCurrentShipments}
            showSnackbar={showSnackbar}
          />
        </Box>
        {isEmpty && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
            <img 
              src="https://media1.tenor.com/m/CC3o_ch_DGwAAAAd/john-travolta-hoarding.gif" 
              alt="No shipments available" 
              style={{ maxWidth: '100%', height: 'auto' }} 
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              No added shipments yet
            </Typography>
          </Box>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={historyOpen}
          onClose={() => setHistoryOpen(false)}
          maxWidth="xl"
          fullWidth
          PaperProps={{
            sx: {
              height: '90vh',
              maxHeight: '90vh',
              m: 2,
              borderRadius: 2,
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            {historyLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <ShipmentHistory
                shipments={shipmentHistory}
                onClose={() => setHistoryOpen(false)}
              />
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ShipmentPanel;
