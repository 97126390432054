import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useFormState } from '../../hooks/useFormState';
import axiosInstance from '../axiosInstance';
import SensorsSection from '../../Components/SensorsSection';
import AntennasSection from '../../Components/AntennasSection';
import CustomGridItem from '../../Components/CustomGridItem';

const initialState = {
  box: '',
  status: 'NIEPEŁNY',
  deviceType: 'SensorBOX-3 SE',
  location: 'Warsztat',
  versionOfDz: 'v1.6',
  numberOfDz: '',
  pullUp: true,
  powerSupplyType: '220v+12v',
  measurementTrackType: '{3-chem}+{2-pył}',
  rtcBattery: true,
  voltageFilter: true,
  sensors: ['PMSA003'],
  numberOfSensors: [''],
  numberOfChannels: [''],
  antennas: ['1575R-AR', 'Airgain N2420'],
  comments: '',
  customLocation: '',
  isOtherLocation: false,
};

const AddDevice = () => {
  const [formState, handleChange, handleToggle, setFormState] = useFormState(initialState);
  const [options, setOptions] = useState({
    Location: [],
    'Measurement Track Type': [],
    'Version of DZ': [],
    'Power Supply Type': [],
    Antennas: [],
    Sensors: [],
    'Device Type': [],
    Status: [],
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axiosInstance.get('/form/forms/sensorbox');
      setOptions(response.data);
    } catch (error) {
      setErrorMessage(`Error fetching options: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dzPattern = /^[A-Z]{1}[0-9]{1,3}$/;

    if (!dzPattern.test(formState.numberOfDz)) {
      setErrorMessage(
        "Invalid 'Number of DZ'. It should be one uppercase letter followed by up to three digits (e.g., G234, F23, A023, B1)."
      );
      return;
    }

    if (formState.numberOfSensors.some(sensor => sensor === '')) {
      setOpenConfirmDialog(true);
      return;
    }

    await submitForm();
  };

  const submitForm = async () => {
    const formData = new URLSearchParams();
    formData.append('box', formState.box);
    formData.append('status', formState.status);
    formData.append('device_type', formState.deviceType);

    const location = formState.isOtherLocation ? formState.customLocation : formState.location;
    formData.append('location', location);

    formData.append('version_of_dz', formState.versionOfDz);
    formData.append('number_of_dz', formState.numberOfDz);
    formData.append('pull_up', formState.pullUp.toString());
    formData.append('power_supply_type', formState.powerSupplyType);
    formData.append('measurement_track_type', formState.measurementTrackType);
    formData.append('rtc_battery', formState.rtcBattery.toString());
    formData.append('voltage_filter', formState.voltageFilter.toString());
    formData.append('sensors', JSON.stringify(formState.sensors));
    formData.append('number_of_sensors', JSON.stringify(formState.numberOfSensors));
    formData.append('number_of_channels', JSON.stringify(formState.numberOfChannels));
    formData.append('antennas', JSON.stringify(formState.antennas));
    formData.append('comments', formState.comments);

    try {
      const response = await axiosInstance.post('/form/form', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      setSuccessMessage(response.data.message);
      setFormState(initialState);
    } catch (error) {
      const apiErrorMessage = error.response?.data?.message || 'Network or server error. Please try again.';
      setErrorMessage(apiErrorMessage);
    }
  };

  const handleConfirm = async () => {
    setOpenConfirmDialog(false);
    await submitForm();
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Add Sensor Box
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/"
          sx={{ mb: 3 }}
        >
          Back
        </Button>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Register Sensor Box
                </Typography>
                <Grid container spacing={2}>
                  <CustomGridItem
                    type="text"
                    label="Box"
                    value={formState.box}
                    onChange={handleChange('box')}
                    required
                    xs={12}
                    sx={formState.box === '' ? {
                      boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
                    } : {}} options={undefined} checked={undefined} minRows={undefined} />
                  <CustomGridItem
                    type="select"
                    label="Status"
                    value={formState.status}
                    onChange={handleChange('status')}
                    options={options.Status}
                    required checked={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="select"
                    label="Device Type"
                    value={formState.deviceType}
                    onChange={handleChange('deviceType')}
                    options={options['Device Type']}
                    required checked={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="select"
                    label="Location"
                    value={formState.isOtherLocation ? 'Other (Specify)' : formState.location}
                    onChange={(event) => {
                      const newLocation = event.target.value;
                      handleChange('location')(event);
                      setFormState((prev) => ({
                        ...prev,
                        isOtherLocation: newLocation === 'Other (Specify)',
                        customLocation: newLocation === 'Other (Specify)' ? '' : prev.customLocation,
                      }));
                    }}
                    options={options.Location}
                    required

                    xs={12} checked={undefined} minRows={undefined} sx={undefined} />
                  {formState.isOtherLocation && (
                    <CustomGridItem
                      type="text"
                      label="Specify Location"
                      value={formState.customLocation}
                      onChange={handleChange('customLocation')}
                      required
                      xs={12} options={undefined} checked={undefined} minRows={undefined} sx={undefined} />
                  )}
                  <CustomGridItem
                    type="select"
                    label="Version of DZ"
                    value={formState.versionOfDz}
                    onChange={handleChange('versionOfDz')}
                    options={options['Version of DZ']}
                    required checked={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="text"
                    label="Number of DZ"
                    value={formState.numberOfDz}
                    onChange={handleChange('numberOfDz')}
                    required

                    sx={formState.numberOfDz === '' ? {
                      boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
                    } : {}} options={undefined} checked={undefined} minRows={undefined} />
                  <CustomGridItem
                    type="select"
                    label="Power Supply Type"
                    value={formState.powerSupplyType}
                    onChange={handleChange('powerSupplyType')}
                    options={options['Power Supply Type']}
                    required checked={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="select"
                    label="Measurement Track Type"
                    value={formState.measurementTrackType}
                    onChange={handleChange('measurementTrackType')}
                    options={options['Measurement Track Type']}
                    required checked={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="switch"
                    label="RTC Battery"
                    checked={formState.rtcBattery}
                    onChange={handleToggle('rtcBattery')} value={undefined} options={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="switch"
                    label="Voltage Filter"
                    checked={formState.voltageFilter}
                    onChange={handleToggle('voltageFilter')} value={undefined} options={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="switch"
                    label="Pull Up"
                    checked={formState.pullUp}
                    onChange={handleToggle('pullUp')} value={undefined} options={undefined} minRows={undefined} sx={undefined} />
                  <CustomGridItem
                    type="text"
                    label="Comments"
                    value={formState.comments}
                    onChange={handleChange('comments')}
                    multiline
                    minRows={3}
                    xs={12} options={undefined} checked={undefined} sx={undefined} />
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      ADD BOX
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <SensorsSection
                  sensors={formState.sensors}
                  numberOfSensors={formState.numberOfSensors}
                  numberOfChannels={formState.numberOfChannels}
                  options={options}
                  handleSensorChange={(index, value) => {
                    const updatedSensors = [...formState.sensors];
                    updatedSensors[index] = value;
                    setFormState((prev) => ({
                      ...prev,
                      sensors: updatedSensors,
                    }));
                  }}
                  handleNumberOfSensorChange={(index, value) => {
                    const updatedNumbers = [...formState.numberOfSensors];
                    updatedNumbers[index] = value;
                    setFormState((prev) => ({
                      ...prev,
                      numberOfSensors: updatedNumbers,
                    }));
                  }}
                  handleNumberOfChannelsChange={(index, value) => {
                    const updatedChannels = [...formState.numberOfChannels];
                    updatedChannels[index] = value;
                    setFormState((prev) => ({
                      ...prev,
                      numberOfChannels: updatedChannels,
                    }));
                  }}
                  handleAddSensor={() => {
                    setFormState((prev) => ({
                      ...prev,
                      sensors: [...prev.sensors, ''],
                      numberOfSensors: [...prev.numberOfSensors, ''],
                      numberOfChannels: [...prev.numberOfChannels, ''],
                    }));
                  }}
                  handleRemoveSensor={(index) => {
                    const filteredSensors = formState.sensors.filter((_, i) => i !== index);
                    const filteredNumbers = formState.numberOfSensors.filter((_, i) => i !== index);
                    const filteredChannels = formState.numberOfChannels.filter((_, i) => i !== index);
                    setFormState((prev) => ({
                      ...prev,
                      sensors: filteredSensors,
                      numberOfSensors: filteredNumbers,
                      numberOfChannels: filteredChannels,
                    }));
                  }}
                  spacing={1}
                />
                <AntennasSection
                  antennas={formState.antennas}
                  options={options}
                  handleAntennaChange={(index, value) => {
                    const updatedAntennas = [...formState.antennas];
                    updatedAntennas[index] = value;
                    setFormState((prev) => ({
                      ...prev,
                      antennas: updatedAntennas,
                    }));
                  }}
                  handleAddAntenna={() => {
                    setFormState((prev) => ({
                      ...prev,
                      antennas: [...prev.antennas, ''],
                    }));
                  }}
                  handleRemoveAntenna={(index) => {
                    const filteredAntennas = formState.antennas.filter((_, i) => i !== index);
                    setFormState((prev) => ({
                      ...prev,
                      antennas: filteredAntennas,
                    }));
                  }}
                  spacing={1}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>

        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
        >
          <DialogTitle>Confirm Submission</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Some sensor serial numbers are empty. Do you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AddDevice;
